import { useCreateUserMutation } from "../users-api";
import { UserForm } from "../user-form/user-form";
import { appPaths } from "@/app";
import { useAuthUserNavigate } from "@/features/auth";

export function NewUser() {
    const { navigateTo } = useAuthUserNavigate();
    const [createUser, { isLoading, isSuccess }] = useCreateUserMutation();

    return (
        <UserForm
            title="Add New User"
            onSubmit={createUser}
            isSubmitting={isLoading}
            isSubmitted={isSuccess}
            onClose={() => navigateTo(appPaths.users)}
        />
    );
}
