const envsConfig = {
    development: {
        apiUrl: "https://api.paperless.flexisafapps-dev.com",
    },
    staging: {
        apiUrl: "https://api.paperless.flexisafapps-stage.com",
    },
    production: {
        apiUrl: "https://api.paperless.safrecords.com",
    },
};

const environment = process.env.REACT_APP_ENV ?? "development";
export const config = envsConfig[environment];
