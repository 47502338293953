import { Box, Spacer, Modal } from "@flexisaf/flexibull2";
import { Button } from "@flexisaf/flexibull2/build/button";
import { SearchBar, StyledModalBody } from "@/features/ui";
import UserDropdown from "./Attendee";

interface Props {
    variables?: any;
    actions?: any;
}

function AttendanceModal({ variables, actions }: Props) {
    const { queryState, usersData, usersLoading, open, adding } = variables;

    return (
        <Modal outerclick onClose={() => actions.setOpen(false)} open={open}>
            <StyledModalBody width="600px">
                <Box pad="20px">
                    <Box>
                        <SearchBar
                            value={queryState["search-word"] ?? ""}
                            onChange={actions.searchUser}
                            isSearching={usersLoading}
                        />
                        <Spacer space={32} />
                        {usersData?.entities?.length === 0 ? "User not found" : ""}
                        <UserDropdown
                            users={usersData?.entities}
                            setAttendees={actions.setAttendees}
                        />
                    </Box>
                    <Spacer space={16} />
                    <div
                        style={{
                            textAlign: "right",
                        }}
                    >
                        <Button spaceRight pale onClick={() => actions.setOpen(false)}>
                            Cancel
                        </Button>
                        <Button
                            progress={adding}
                            onClick={actions.save}
                            iconRight={<i className="flexibull-angle-right" />}
                        >
                            Add
                        </Button>
                        <Spacer space={20} />
                    </div>
                </Box>
            </StyledModalBody>
        </Modal>
    );
}

export default AttendanceModal;
