import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { REHYDRATE } from "redux-persist";
import { createStaggeredBaseQuery } from "@/lib/base-query";
import { config } from "@/utils/config";
import { hoursToMilliseconds } from "date-fns";

export interface InstitutionResponse {
    universityName: string;
    shortName: string;
    logoUrl: string;
    motto?: string;
}

export const institutionApi = createApi({
    baseQuery: createStaggeredBaseQuery(config.apiUrl as string),
    tagTypes: ["INSTITUTION"],
    reducerPath: "institutionApi",
    extractRehydrationInfo: (action, { reducerPath }) => {
        if (action.type === REHYDRATE && action.payload) return action.payload[reducerPath];
    },
    endpoints: (builder) => ({
        getInstitution: builder.query<InstitutionResponse, string>({
            queryFn: () => ({
                data: {
                    universityName: "Flexisaf Institution",
                    shortName: "flexisaf institution",
                    logoUrl: "logoUrl",
                    motto: "A fictional institution owned by flexisaf ",
                },
            }),
            keepUnusedDataFor: hoursToMilliseconds(1),
        }),
    }),
});

export const { useGetInstitutionQuery } = institutionApi;
