import { useAuth } from "@/features/auth";
import { useGetSRMSDepartmentsQuery, useGetSRMSFacultiesQuery } from "./prayer-api";
import { PrayerUiOptions, prayerStatusOptions } from "../prayers-ui-options";
import { PrayerStatuses, Unit } from "@/utils/enums";
import { useGetUpcomingSenateMeeting } from "@/features/meetings";
import { ApiError } from "@/types/common";
import { UserDetails } from "./prayer-utils";

export function usePrayerController() {
    const { user } = useAuth();
    const userDetails: UserDetails = {
        unit: user.currentRole.unit as Unit,
        faculty: {
            name: user?.department?.faculty?.name ?? user?.faculty?.name,
            id: user?.department?.faculty?.id ?? user?.faculty?.id,
        },
        department: {
            name: user?.department?.name,
            id: user?.department?.id,
        },
        userId: user.id,
    };

    const { data: faculties, ...getFacultiesMeta } = useGetSRMSFacultiesQuery();
    const { data: departments, ...getDepartmentsMeta } = useGetSRMSDepartmentsQuery(
        { "faculty-code": userDetails.faculty.id },
        { skip: !userDetails.faculty.id }
    );

    const filteredPrayerStatusOptions = filterPrayerStatusOptions(prayerStatusOptions, user?.unit);

    const facultyOptions = mapFacultiesToOptions(faculties);
    const departmentOptions = mapFacultiesToOptions(departments);

    const { meeting } = useGetUpcomingSenateMeeting();

    const loading = {
        isLoadingDepartments: getDepartmentsMeta.isLoading,
        isLoadingFaculties: getFacultiesMeta.isLoading,
    };

    const errors = {
        departmentError: <ApiError>getDepartmentsMeta.error,
        facultyError: <ApiError>getFacultiesMeta.error,
    };

    return {
        userDetails,
        facultyOptions,
        departmentOptions,
        filteredPrayerStatusOptions,
        meetingId: meeting?.id,
        loading,
        errors,
    };
}

interface Faculty {
    abbreviation: string;
    dean: string;
    facultyCategory: string;
    facultyId: string;
    facultyName: string;
}

interface Department {
    departmentId: string;
    departmentName: string;
    faculty: Faculty;
    shortName: string;
}

function mapFacultiesToOptions(data: Array<Faculty | Department>): PrayerUiOptions[] {
    return data?.map((item) => ({
        label: isDepartment(item) ? item.departmentName : item.facultyName,
        value: isDepartment(item) ? item.departmentId : item.facultyId,
    }));
}

function isDepartment(obj: any): obj is Department {
    return "departmentName" in obj;
}

function filterPrayerStatusOptions(prayerStatusOptions, userUnit: Unit) {
    let updatedPrayerStatusOptions = [...prayerStatusOptions];

    if (userUnit === Unit.Faculty) {
        // Remove 'Initiated' from the array
        updatedPrayerStatusOptions = updatedPrayerStatusOptions.filter(
            (option) => option.value !== PrayerStatuses.Initiated
        );
    } else if (userUnit === Unit.Sbc) {
        // Remove 'Initiated' and 'Raised' from the array
        updatedPrayerStatusOptions = updatedPrayerStatusOptions.filter(
            (option) =>
                option.value !== PrayerStatuses.Initiated && option.value !== PrayerStatuses.Raised
        );
    } else if (userUnit === Unit.Secretariat || userUnit === Unit.Senate) {
        // Remove 'Initiated', 'Submitted', and 'Raised' from the array
        updatedPrayerStatusOptions = updatedPrayerStatusOptions.filter(
            (option) =>
                option.value !== PrayerStatuses.Initiated &&
                option.value !== PrayerStatuses.Submitted &&
                option.value !== PrayerStatuses.Raised
        );
    }

    return updatedPrayerStatusOptions;
}
