import { Box, Text, Button, Loader } from "@flexisaf/flexibull2";
import { theme } from "@/style";
import { useAccountVerification } from "./use-account-verification";

const styles = {
    display: "grid",
    placeItems: "center",
    width: "100vw",
    height: "100vh",
};

const innerStyles = {
    display: "flex",
    flexDirection: "column",
    gap: 24,
    maxWidth: "302px",
    textAlign: "center",
    alignItems: "center",
};

const imageStyles = {
    backgroundImage: "transparent",
    maxWidth: "120px",
    maxHeight: "80px",
    width: "auto",
    height: "auto",
    display: "block",
};

export function AccountVerification() {
    const { errors, loading, token } = useAccountVerification();

    const renderContent = () => {
        if (!token) {
            return <Text>No Token</Text>;
        }

        if (errors.verificationError) {
            const { message } = errors.verificationError;
            return (
                <>
                    <Text size="2em" bold block>
                        Account Verification Failed
                    </Text>
                    <Text color={theme.PrimaryRed}>{message}</Text>
                </>
            );
        }

        if (loading.isVerifying) {
            return (
                <>
                    <Loader />
                    <Text block>Verifying account...</Text>
                </>
            );
        }

        return (
            <>
                <Text size="2em" bold block>
                    Account Verification Successful
                </Text>
                <Text>
                    Your account has been successfully confirmed. Proceed to create your password.
                </Text>
                <Button>Reset Password</Button>
            </>
        );
    };

    return (
        <Box style={styles}>
            <Box style={innerStyles}>
                <img
                    src={"https://safrecords.com/wp-content/uploads/2023/01/logo.svg"}
                    style={imageStyles}
                    onError={(evt) => (evt.currentTarget.style.display = "none")}
                />
                {renderContent()}
            </Box>
        </Box>
    );
}
