import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useUpdatePrayerMutation, useGetPrayerByIdQuery } from "./prayer-api";
import { useAuthUserNavigate } from "@/features/auth";
import { appPaths } from "@/app";
import { PrayerForm } from "./prayer-form";
import { BackButton, PageLayout, Section, WithLoader } from "@/features/ui";
import { ApiError } from "@/types/common";

type prayerEditProps = {
    onPrayerCreated?: (data) => void;
};

export const PrayerEdit = (props: prayerEditProps): React.ReactElement => {
    const { id } = useParams();
    const { navigateTo } = useAuthUserNavigate();
    const { onPrayerCreated = () => navigateTo(appPaths.prayers) } = props;
    const [editPrayerMutation, { isLoading: isPrayerUpdating, isSuccess: prayerUpdated }] =
        useUpdatePrayerMutation();
    const { data, refetch, error, isLoading: isEditLoading } = useGetPrayerByIdQuery(id as string);

    const meetingId = data?.meeting?.id;

    useEffect(() => {
        if (prayerUpdated) {
            onPrayerCreated(data);
        }
    }, [prayerUpdated, onPrayerCreated, data]);

    return (
        <PageLayout pageLabel="Edit Prayer">
            <WithLoader
                isLoading={isEditLoading}
                error={error as ApiError}
                height={"100vh"}
                retry={refetch}
            >
                <Section>
                    <BackButton />
                    <PrayerForm
                        data={data}
                        submit={(prayerBody) => editPrayerMutation({ id, ...prayerBody })}
                        isSubmitting={isPrayerUpdating}
                        isEdit={true}
                        meetingId={meetingId}
                    />
                </Section>
            </WithLoader>
        </PageLayout>
    );
};
