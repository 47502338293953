import styled, { css } from "styled-components";
import { Select } from "@flexisaf/flexibull2/build/select";
import { lighten } from "polished";

export const StyledSelect = styled(Select)`
    ${(p) =>
        p.pale &&
        css`
            display: inline;
            min-width: 180px;
            .flexi__control {
                background: none;
                border: none !important;
                padding: 0px;
            }
        `}
    ${(p) =>
        p.rounded &&
        css`
            .flexi__control {
                border-radius: 3.2em !important;
            }
        `}
        ${(p) =>
        p.color &&
        css`
            .flexi__control {
                background-color: ${p.color};
                .flexi__single-value {
                    color: ${lighten(0.9, p.color)};
                }
            }
        `}

    /* Overriding flexisaf default select button width */
        width: ${(p) => p.width || "100%"} !important;
`;
