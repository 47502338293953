import { useEffect, useState } from "react";
import { Box, Grid, Select, Text, Spacer, Button } from "@flexisaf/flexibull2";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { theme } from "@/style";
import { useResponsiveValue } from "@/lib/use-responsive-value";
import { titleCase } from "@/utils/string-utils";
import { Modal, HookForm as Form } from "@/features/ui";
import { useRoles } from "./use-roles";

type RolesModalProps = {
    showModal: boolean;
    isEdit: boolean;
    setShowModal: (value: boolean) => void;
    setIsEdit: (value: boolean) => void;
    selectedRole: any;
};

const RolesModal = ({
    showModal,
    isEdit,
    setShowModal,
    setIsEdit,
    selectedRole,
}: RolesModalProps) => {
    const isMobile = useResponsiveValue({ sm: true, md: false }) as boolean;
    const [isFocused, setIsFocused] = useState(false);
    const { privilegesOptions, actions, loading, isRoleCreated, isRoleUpdated } = useRoles();

    const validation = yup.object({
        role: yup.string().label("Role").required(),
        privileges: yup.array().label("Privileges").required(),
        roleDescription: yup.string().label("Role description"),
    });

    const form = useForm({
        resolver: yupResolver(validation),
    });

    const onSubmit = (values: any) => {
        const { role, privileges, roleDescription } = values;
        const data = {
            name: role,
            privileges: privileges.map((p) => p.value),
            description: roleDescription,
        };

        if (isEdit && selectedRole) {
            const id = selectedRole?.id;
            actions.editRole({ id, ...data });
            return;
        }
        actions.createRole(data);
    };

    useEffect(() => {
        if (isEdit && selectedRole) {
            form.reset({
                role: titleCase(selectedRole?.name),
                roleDescription: selectedRole?.description,
                privileges: selectedRole?.privileges.map((p) => ({
                    label: p.name,
                    value: p.id,
                })),
            });
        }
    }, [selectedRole, isEdit, form]);

    const closeModal = () => {
        setShowModal(false);
        if (isEdit) {
            setIsEdit(false);
        }
        form.reset({
            role: "",
            roleDescription: "",
            privileges: [],
        });
    };

    useEffect(() => {
        if (isRoleCreated || isRoleUpdated) {
            closeModal();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRoleCreated, isRoleUpdated]);

    const handleFocus = () => setIsFocused(true);
    const handleBlur = () => setIsFocused(false);
    const privegesSelectStyle = {
        width: "100%",
        maxHeight: "50vh",
        minHeight:
            isFocused && privilegesOptions?.length !== undefined && privilegesOptions?.length > 1
                ? "35vh"
                : "2vh",
    };

    return (
        <Modal
            isOpen={showModal}
            position={isMobile ? "bottom" : "center"}
            onClose={() => actions.clearSelectedRole()}
        >
            <Box>
                <Box style={{ display: "flex", flexDirection: "column" }}>
                    <Text size={theme.FontSizes.xl} bold>
                        {isEdit ? "Edit" : "New"} Role
                    </Text>

                    <Text size={theme.FontSizes.sm}>
                        Select Privileges to {isEdit ? "edit" : "create new"} role
                    </Text>
                </Box>

                <Spacer space={24} />

                <Form form={form}>
                    <Spacer space={24} />
                    <Form.Input
                        name="role"
                        label="Role"
                        placeholder="Role Title"
                        style={{ width: "100%" }}
                    />
                    <Spacer space={32} />
                    <Controller
                        name="privileges"
                        control={form.control}
                        render={({ field, fieldState }) => {
                            return (
                                <>
                                    <Box style={{ overflowY: "auto", maxHeight: "300px" }}>
                                        <Box style={privegesSelectStyle}>
                                            <Select
                                                {...field}
                                                label="Privileges"
                                                options={privilegesOptions}
                                                style={{ width: "100%" }}
                                                isMulti
                                                onFocus={handleFocus}
                                                onBlur={handleBlur}
                                                closeMenuOnSelect={false}
                                            />
                                        </Box>
                                    </Box>
                                    {fieldState.error && (
                                        <Text size="1em" color={theme.PrimaryRed}>
                                            {fieldState.error?.message}
                                        </Text>
                                    )}
                                </>
                            );
                        }}
                    />
                    <Spacer space={32} />
                    <Form.Input
                        name="roleDescription"
                        label="Role description"
                        placeholder="Describe the role"
                        style={{ width: "100%" }}
                    />
                    <Spacer space={24} />
                    <Grid default="1fr">
                        <Button
                            onClick={form.handleSubmit(onSubmit)}
                            progress={loading.isCreatingRoles || loading.isEditingRoles}
                        >
                            {isEdit ? "Edit" : "Create"}
                        </Button>
                        <Button pale onClick={closeModal}>
                            Cancel
                        </Button>
                    </Grid>
                </Form>
            </Box>
        </Modal>
    );
};

export default RolesModal;
