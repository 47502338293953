import { createContext, useMemo, useContext } from "react";
import Helmet from "react-helmet";

import { InstitutionResponse, useGetInstitutionQuery } from "./institution.api";
import { WithLoader } from "@/features/ui";
import { ApiError } from "@/types/common";
import { useParams } from "react-router-dom";

import { APP_TITLE } from "@/utils/constants";

export type InstitutionContext = {
    institution: { universityName: string; shortName: string; logoUrl: string; motto?: string };
};

export const institutionContext = createContext<InstitutionContext | null>(null);

type InstitutionProviderProps = {
    children: React.ReactNode;
};

export function InstitutionProvider(props: InstitutionProviderProps) {
    const { institutionId } = useParams();
    const {
        data: institution,
        isLoading,
        error,
    } = useGetInstitutionQuery(institutionId as string, { skip: !institutionId });

    const contextValue = useMemo(
        () => ({ institution: institution as InstitutionResponse }),
        [institution]
    );
    const title = institution?.universityName ?? APP_TITLE;
    return (
        <WithLoader isLoading={isLoading} error={error as ApiError} height={"100vh"}>
            <institutionContext.Provider value={contextValue}>
                <Helmet>
                    <link rel="icon" href={institution?.logoUrl} />
                    <title> {title}</title>
                </Helmet>

                {props.children}
            </institutionContext.Provider>
        </WithLoader>
    );
}

export const useInstitution = () => {
    const ctx = useContext(institutionContext);
    if (!ctx) {
        throw new Error("institution context was not called inside  the appropriate Provider");
    }

    return ctx;
};
