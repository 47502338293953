import { Box, Grid, Text, Button, Spacer } from "@flexisaf/flexibull2";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { HookForm as Form, Modal } from "@/features/ui";
import { useForm } from "react-hook-form";
import { theme } from "@/style";
import { NewAgendaItem } from "./use-agenda-form";
import { DocumentUploader } from "@/features/documents";

export interface AgendaItem {
    position: number;
    content: string;
    fileUrl?: string;
}
type AddAgendaItemModalProps = {
    onSubmit: (agendaItem: NewAgendaItem) => void;
    onClose: () => void;
    isOpen: boolean;
    targetPosition: number;
};

const validation = yup.object({
    title: yup.string().label("Title").required(),
});

export function AddAgendaItemModal(props: AddAgendaItemModalProps) {
    const { onSubmit, onClose, targetPosition, isOpen } = props;
    const form = useForm<NewAgendaItem>({
        resolver: yupResolver(validation),
    });

    const onFileChange = (files: File[]) => form.setValue("file", files[0]);
    const onAdd = (val: NewAgendaItem) => {
        onSubmit(val);
        onClose();
    };

    return (
        <Modal title="Add Agenda Item" isOpen={isOpen} onClose={onClose}>
            <Box>
                <Form form={form}>
                    <Form.Input name="title" label="Title" spaceBottom width="100%" />
                    <Form.HiddenInput name="position" value={targetPosition} />
                </Form>
                <Spacer space={theme.PrimarySpace} />
                <DocumentUploader onChange={onFileChange}>
                    <div>
                        <Text color={theme.PrimaryBlue}> Attach File</Text>
                    </div>
                </DocumentUploader>
                <Spacer space={theme.PrimarySpace} />
                <Grid default="1fr 1fr" sm="1fr">
                    <Button pale onClick={onClose}>
                        Cancel
                    </Button>
                    <Button onClick={form.handleSubmit(onAdd)}> Add </Button>
                </Grid>
            </Box>
        </Modal>
    );
}
