import { Notify } from "@flexisaf/flexibull2";
import { baseApi } from "@/store/base-api";
import { List, QueryFulfilledError } from "@/types/common";
import { encodeInterpolatedUrl } from "@/utils/string-utils";
import { Methods } from "@/utils/enums";

export const rolesApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getAllRoles: builder.query<List<SingleRole>, RoleParams | any>({
            query: (params) => ({
                url: "/roles",
                params,
            }),
            providesTags: ["ROLES"],
        }),
        createNewRole: builder.mutation<SingleRole, NewRoleRequest>({
            query: (data) => ({
                url: "/roles",
                method: Methods.Post,
                data,
            }),
            invalidatesTags: ["ROLES"],
            async onQueryStarted(_, { queryFulfilled: qf }) {
                qf.then(() => Notify("Role created Successfully")).catch(
                    (err: QueryFulfilledError) => Notify(err.error?.message, { status: "error" })
                );
            },
        }),
        getRole: builder.query({
            query: ({ id }) => ({
                url: encodeInterpolatedUrl`/roles/${id}`,
            }),
            providesTags: ["ROLES"],
        }),
        editRole: builder.mutation<unknown, UpdateRoleRequest>({
            query: ({ id, ...data }) => ({
                url: encodeInterpolatedUrl`/roles/${id}`,
                method: Methods.Put,
                data,
            }),
            invalidatesTags: ["ROLES"],
            async onQueryStarted(_, { queryFulfilled: qf }) {
                qf.then(() => Notify("Role edited Successfully")).catch(
                    (err: QueryFulfilledError) => Notify(err.error?.message, { status: "error" })
                );
            },
        }),
        deactivateRole: builder.mutation<unknown, string>({
            query: (id) => ({
                url: `/roles/${id}`,
                method: Methods.Delete,
            }),
            invalidatesTags: ["ROLES"],
            async onQueryStarted(_, { queryFulfilled: qf }) {
                qf.then(() => Notify("Role deleted Successfully")).catch(
                    (err: QueryFulfilledError) => Notify(err.error?.message, { status: "error" })
                );
            },
        }),
        getPrivileges: builder.query<Privileges[], string>({
            query: () => ({
                url: "/privileges",
            }),
        }),
    }),
});

export interface RoleParams {
    "search-word"?: string;
    limit?: number;
    offset?: number;
    unit?: string;
}

export interface RolePrivilege {
    id: string;
    name: string;
}

export interface SingleRole {
    date_updated: string;
    description: string;
    id: string;
    name: string;
    user_count: number;
    privileges: RolePrivilege[];
}

export interface Role {
    description?: string;
    id: number;
    roleId: string;
    unit?: string;
    name?: string;
}

interface NewRoleRequest {
    name: string;
    privileges: string[];
    description: string;
}

interface Privileges {
    description: string;
    id: number;
    name: string;
}

interface UpdateRoleRequest extends Partial<NewRoleRequest> {
    id: string;
}

export const {
    useGetAllRolesQuery,
    useGetPrivilegesQuery,
    useEditRoleMutation,
    useDeactivateRoleMutation,
    useGetRoleQuery,
    useCreateNewRoleMutation,
} = rolesApi;
