import { theme } from "@/style";
import { Draggable } from "react-beautiful-dnd";
import { DataItem, DataItemProps } from "../data-item/data-item";

type DragDataFieldProps = {
    draggableId: string;
    index: number;
    dataItemProps: (provided: any, snapshot: any) => DataItemProps | DataItemProps;
};

export function DragDataItem(props: DragDataFieldProps) {
    const { draggableId, index, dataItemProps } = props;

    return (
        <Draggable index={index} draggableId={draggableId}>
            {(provided, snapshot) => {
                const dataItemVals =
                    typeof dataItemProps === "function"
                        ? dataItemProps(provided, snapshot)
                        : dataItemProps;

                return (
                    <div
                        ref={provided.innerRef}
                        {...provided.dragHandleProps}
                        {...provided.draggableProps}
                        style={{
                            padding: `1em ${snapshot.isDragging ? "1em" : "0"}`,
                            background: "white",
                            boxShadow: snapshot.isDragging ? theme.Elevate.high : "none",
                            borderBottom: theme.Border.light,
                            ...provided.draggableProps.style,
                        }}
                    >
                        <DataItem {...dataItemVals} />
                    </div>
                );
            }}
        </Draggable>
    );
}
