import { useCallback, useState, useRef } from "react";

import { Box } from "@flexisaf/flexibull2";

import { usePinchZoom } from "@/lib/use-pinch-zoom";
import { theme } from "@/style";
import { ApiError, MaybeUndefined } from "@/types/common";

import { WithLoader } from "@/features/ui/base/with-loader/with-loader";

import { Zoomer } from "../components";
import { ZOOM_MIN, ZOOM_MAX, ZOOM_STEP } from "../util";
import { RendererBaseProps } from "../types";
import { RendererWrapper } from "../styles";

const ERROR_MESSAGE = "Unable to load Image";

export function ImageRenderer(props: RendererBaseProps) {
    const { url, onLoadError } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<MaybeUndefined<ApiError>>();
    const [zoomLevel, setZoomLevel] = useState(1);
    const containerRef = useRef<HTMLDivElement | null>(null);

    const { onZoomIn, onZoomOut } = usePinchZoom(containerRef);

    onZoomIn(() => zoomLevel < ZOOM_MAX && setZoomLevel(zoomLevel + ZOOM_STEP));
    onZoomOut(() => zoomLevel > ZOOM_MIN && setZoomLevel(zoomLevel - ZOOM_STEP));

    const onEncounteredError = useCallback(() => {
        setError({ message: ERROR_MESSAGE, status: 100 });
        onLoadError && onLoadError(ERROR_MESSAGE);
    }, [onLoadError]);

    const onLoaded = useCallback(() => {
        setIsLoading(false);
    }, []);

    return (
        <WithLoader isLoading={isLoading} error={error}>
            <RendererWrapper style={{ width: "100%" }}>
                <Box
                    style={{
                        left: 0,
                        background: "white",
                        top: 0,
                        width: "100%",
                        zIndex: theme.Layer.third,
                        boxShadow: theme.Elevate.mid,
                    }}
                >
                    <Zoomer onZoomChange={setZoomLevel} min={ZOOM_MIN} max={ZOOM_MAX} />
                </Box>
                <div style={{ position: "relative", overflow: "scroll" }} ref={containerRef}>
                    <img
                        src={url}
                        style={{
                            top: 0,
                            bottom: 0,
                            margin: "auto",
                            left: 0,
                            right: 0,
                            transform: `scale(${zoomLevel})`,
                            backgroundImage: "transparent",
                            maxHeight: "80vh",
                            width: "auto",
                            height: "auto",
                            display: "block",
                        }}
                        onError={onEncounteredError}
                        onLoad={onLoaded}
                    />
                </div>
            </RendererWrapper>
        </WithLoader>
    );
}
