import { Box, Loader, Spacer } from "@flexisaf/flexibull2";
import debounce from "lodash/debounce";

import styled from "styled-components";
import { theme } from "@/style";
import { Icon } from "../icon";
import { Duration } from "@/utils/enums";

const ICON_SIZE = 16;

type SearchBarProps = {
    value: string;
    placeholder?: string;
    onChange: (value: string) => void;
    debounceInterval?: number;
    isSearching?: boolean;
    onFocus?: () => void;
    onBlur?: () => void;
    label?: React.ReactElement;
    isDisabled?: boolean;
    style?: React.CSSProperties;
};

const StyledInput = styled.input`
    background: "none";
    border: "1px solid #ccc";
    border: none;
    width: 100%;
    outline: none;
    background: transparent;
    :disabled {
        background: ${theme.SecondaryGrey};
    }
`;

const flexibullLabelStyles: Partial<React.CSSProperties> = {
    position: "absolute",
    display: "block",
    top: "-20px",
};

export const SearchBar = (props: SearchBarProps) => {
    const {
        value,
        onChange,
        placeholder,
        label,
        debounceInterval = Duration.Short,
        isDisabled,
        onFocus,
        onBlur,
        isSearching = false,
        style = {},
    } = props;

    const debouncedOnChange = debounce(onChange, debounceInterval);

    return (
        <Box
            onFocus={onFocus}
            onBlur={onBlur}
            display="flex"
            style={{
                position: "relative",
                alignItems: "center",
                backgroundColor: isDisabled ? theme.SecondaryGrey : "white",
                justifyContent: "space-between",
                border: `1px solid ${theme.PrimaryBorderColor}`,
                borderRadius: "4px",
                padding: ".8em",
                ...style,
            }}
        >
            {label && (
                <>
                    <label style={flexibullLabelStyles}> {label}</label>
                    <Spacer space={8} />
                </>
            )}

            <StyledInput
                type="text"
                placeholder={placeholder}
                disabled={isDisabled}
                defaultValue={value}
                onChange={(evt) => debouncedOnChange(evt.target.value)}
            />
            <span>
                {isSearching ? (
                    <Loader size={20} color={theme.PrimaryGreyMid} />
                ) : (
                    <Icon size={ICON_SIZE} icon={"search-normal-1"} />
                )}
            </span>
        </Box>
    );
};
