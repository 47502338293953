import React, { useCallback, useEffect, useRef } from "react";
import useBasePinchZoom, { ZoomState } from "react-use/lib/usePinchZoom";

type ZoomCallbackFn = (zoomingState: ZoomState) => void;

export function usePinchZoom(elRef: React.MutableRefObject<HTMLElement | null>) {
    const { zoomingState } = useBasePinchZoom(elRef);
    const onZoomInCbRef = useRef<ZoomCallbackFn | null>();
    const onZoomOutCbRef = useRef<ZoomCallbackFn | null>();

    useEffect(() => {
        if (zoomingState === ZoomState.ZOOMING_IN) {
            const { current: zoomInCb } = onZoomInCbRef;
            zoomInCb && zoomInCb(zoomingState);
        } else if (zoomingState === ZoomState.ZOOMING_OUT) {
            const { current: zoomOutCb } = onZoomOutCbRef;
            zoomOutCb && zoomOutCb(zoomingState);
        }
    }, [zoomingState]);

    const onZoomIn = useCallback((cb) => {
        onZoomInCbRef.current = cb;
    }, []);

    const onZoomOut = useCallback((cb) => {
        onZoomOutCbRef.current = cb;
    }, []);

    return {
        zoomingState,
        onZoomIn,
        onZoomOut,
    };
}
