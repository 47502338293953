import { Box, Spacer, Button, Grid, Checkbox, Text } from "@flexisaf/flexibull2";
import { useForm } from "react-hook-form";
import { useState } from "react";

import { Modal, HookForm as Form } from "@/features/ui";

import { useUpdatePasswordMutation } from "../users-api";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { theme } from "@/style";
const validation = yup.object({
    old_password: yup.string().label("Old Password").required(),
    new_password: yup
        .string()
        .label("New Password")
        .required()
        .min(8, "Password needs to be at  least 8 characters")
        .matches(/(?=,*[#$%@&*^])/, "At least one special character is needed")
        .matches(/(?=.*[A-Z])/, "At least one uppercase letter is needed"),
});

type PasswordUpdateModalProps = {
    onClose: () => void;
    isOpen: boolean;
};
export function PasswordUpdateModal(props: PasswordUpdateModalProps) {
    const { onClose, isOpen } = props;
    const form = useForm({
        defaultValues: {
            old_password: "",
            new_password: "",
        },
        resolver: yupResolver(validation),
    });
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [updatePassword, { isLoading, isSuccess }] = useUpdatePasswordMutation();
    const inputType = isPasswordVisible ? "text" : "password";

    return (
        <Modal
            title={"Password Update"}
            topSpace={16}
            closeIf={isSuccess}
            isOpen={isOpen}
            onClose={onClose}
            autoclose={true}
        >
            <Box>
                <Text block>
                    Please provide your old password, and the new password you want to use.
                </Text>
                <Spacer space={36} />
                <Form form={form}>
                    <Box display="flex" style={{ flexDirection: "column" }}>
                        <Form.Input
                            type={inputType}
                            name="old_password"
                            label="Old Password"
                            spaceBottom
                        />
                        <Spacer space={8} />
                        <Form.Input type={inputType} name="new_password" label="New Password" />
                        <Spacer space={2} />
                        <Text size={theme.FontSizes.sm}>
                            (At least 8 characters long, 1 uppercase letter, 1 special character)
                        </Text>
                    </Box>
                </Form>
                <Spacer space={16} />
                <Box
                    onClick={() => setIsPasswordVisible((prev) => !prev)}
                    style={{ display: "inline-block" }}
                >
                    <Checkbox
                        label="Show Passwords"
                        checked={isPasswordVisible}
                        style={{ paddingLeft: 0 }}
                    />
                </Box>
                <Spacer space={16} />
                <Grid default="1fr 1fr" md="1fr 1fr" lr="1fr 1fr" sm="1fr">
                    <Button pale onClick={onClose}>
                        Close
                    </Button>
                    <Button
                        progress={isLoading}
                        style={{ width: "100%" }}
                        onClick={form.handleSubmit(updatePassword)}
                    >
                        {isLoading ? "Updating Password..." : "Update Password"}
                    </Button>
                </Grid>
            </Box>
        </Modal>
    );
}
