import { Box, Spacer, Text } from "@flexisaf/flexibull2";
import noop from "lodash/noop";
import { theme } from "@/style";
import { DataItemWrapper } from "./styles";

export type DataItemProps = {
    leftContent?: React.ReactNode;
    title?: React.ReactNode;
    subtitle?: React.ReactNode;
    caption?: React.ReactNode;
    rightContent?: React.ReactNode;
    onClick?: Function;
    style?: React.CSSProperties;
};

export function DataItem(props: DataItemProps) {
    const { leftContent, title, subtitle, caption, rightContent, onClick, style = {} } = props;
    return (
        <DataItemWrapper onClick={onClick ?? noop} hasOnClick={!!onClick} style={style}>
            <Box display="flex" style={{ alignItems: "center" }}>
                {leftContent}
                <Spacer space={8} direction="horizontal" />
                <Box display="flex" style={{ flexDirection: "column", gap: "4px" }}>
                    {title && (
                        <Text size="14px" color={theme.PrimaryGrey}>
                            {title}
                        </Text>
                    )}

                    {subtitle && (
                        <Text size="14px" bold>
                            {subtitle}
                        </Text>
                    )}

                    {caption && (
                        <Text size="11px" color={theme.PrimaryGreyDark}>
                            {caption}
                        </Text>
                    )}
                </Box>
            </Box>
            <Box>{rightContent}</Box>
        </DataItemWrapper>
    );
}
