import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { buildInstitutionPath } from "@/utils/string-utils";
import { useAuth } from "./auth.context";

export function useAuthUserNavigate() {
    const { user } = useAuth();
    const navigate = useNavigate();

    const buildPath = useCallback(
        (path: string) => {
            if (!user) {
                // eslint-disable-next-line no-console
                console.error("Auth User not avaialable, cannot build user path");
                return;
            }
            return buildInstitutionPath(path, user.organisation);
        },
        [user]
    );

    const navigateTo = useCallback(
        (path: string) => {
            const realPath = buildPath(path);
            if (!realPath) return;
            navigate(realPath);
        },
        [buildPath, navigate]
    );

    const back = useCallback(() => navigate(-1), [navigate]);

    return {
        back,
        navigateTo,
        buildPath,
    };
}
