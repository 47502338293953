import { DragDropContext, Droppable, OnDragEndResponder } from "react-beautiful-dnd";
type DropContainerProps = {
    onDrop: OnDragEndResponder;
    droppableId?: string;
    children: React.ReactNode;
};
export function DropContainer(props: DropContainerProps) {
    const { children, onDrop, droppableId = "drop-container" } = props;
    return (
        <DragDropContext onDragEnd={onDrop}>
            <Droppable droppableId={droppableId}>
                {(provided) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "1.2em",
                        }}
                    >
                        {children}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
}
