import { useSearchParam } from "react-use";
import { useAccountVerificationQuery } from "@/features/auth/auth.api";

export function useAccountVerification() {
    const token = useSearchParam("token");
    const { isLoading: isVerifying, error: verificationError } = useAccountVerificationQuery(
        token as string,
        { skip: !token }
    );
    const loading = {
        isVerifying,
    };
    const errors = {
        verificationError,
    };

    return {
        token,
        errors,
        loading,
    };
}
