import { Box, Text, Spacer, Select, Avatar, Button, Grid } from "@flexisaf/flexibull2";
import { Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

import { HookForm as Form, Icon, PageLayout, Section, WithLoader } from "@/features/ui";

import { useUserProfile } from "./use-user-profile";
import { PasswordUpdateModal } from "./password-update-modal";
import { Unit } from "@/utils/enums";
import { useResponsiveValue } from "@/lib/use-responsive-value";
import { theme } from "@/style";
import { Privileges, usePrivileges } from "@/features/authorization";
import { useAuth } from "@/features/auth";

type UserProfileProps = {
    isSelf?: boolean;
};

export function UserProfile(props: UserProfileProps) {
    const { isSelf } = props;
    const auth = useAuth();
    const [isShowingPasswordModal, togglePasswordModal] = useState(false);
    const navigate = useNavigate();
    const { form, user, loading, actions, errors, roleOptions, unitRoleMapping } = useUserProfile();
    const { hasPrivilege } = usePrivileges();
    const fullName = user ? `${user.firstName} ${user.lastName}` : "";

    const showFacultyField = [Unit.Department, Unit.Faculty].includes(user?.unit as Unit);
    const showDepartmentField = user?.unit === Unit.Department;

    const departmentName = user?.department?.name;
    const facultyName = user?.faculty ? user.faculty?.name : user?.department?.faculty.name;
    const isSecretariateUser = auth.user.currentRole.unit === Unit.Secretariat;

    const canUpdateUser = hasPrivilege(Privileges.UpdateUsers);
    const showUpdateButton = user && (isSelf ?? canUpdateUser);

    const avatarDimension = useResponsiveValue({ sm: "120px", md: "200px" }) as string;

    return (
        <PageLayout minHeight={"98vh"} pageLabel="User Profile">
            <Box>
                <WithLoader isLoading={loading.isLoading} error={errors.userError} height="70vh">
                    <Grid sm="1fr" default="1fr 1.3fr" md="1fr 1.3fr" lr="1fr 1.3fr" gap="24px">
                        <Section style={{ height: "max-content" }}>
                            <Box style={{ textAlign: "center" }}>
                                <Text bold size="1.5em" onClick={() => navigate(-1)}>
                                    User Profile
                                </Text>
                                <Spacer space={32} />
                                <Avatar
                                    src={""}
                                    name={fullName}
                                    round
                                    style={{ width: avatarDimension, height: avatarDimension }}
                                />
                                <Spacer space={32} />
                            </Box>

                            {isSelf && (
                                <>
                                    <PasswordUpdateModal
                                        isOpen={isShowingPasswordModal}
                                        onClose={() => togglePasswordModal(false)}
                                    />

                                    <Box display="flex" style={{ justifyContent: "center" }}>
                                        <Button plain onClick={togglePasswordModal}>
                                            I want to change my password
                                        </Button>
                                    </Box>
                                    <Spacer space={16} />
                                </>
                            )}
                        </Section>

                        <Section>
                            <Form form={form}>
                                <Box
                                    display="flex"
                                    style={{ flexDirection: "column", gap: "8px", paddingTop: 16 }}
                                >
                                    <Form.Input
                                        disabled
                                        name="name"
                                        label="Name"
                                        spaceBottom
                                        defaultValue={fullName}
                                    />
                                    <Form.Input
                                        disabled
                                        name="username"
                                        defaultValue={user?.username}
                                        label="Email (Username)"
                                        spaceBottom
                                    />

                                    {showDepartmentField && (
                                        <Form.Input
                                            disabled
                                            defaultValue={departmentName}
                                            name="department"
                                            label="Department"
                                            spaceBottom
                                        />
                                    )}

                                    {showFacultyField && (
                                        <Form.Input
                                            disabled
                                            name="faculty"
                                            label="Faculty"
                                            defaultValue={facultyName}
                                            spaceBottom
                                        />
                                    )}

                                    {isSecretariateUser ? (
                                        unitRoleMapping.map((mapping, index) => (
                                            <>
                                                <Box
                                                    key={index}
                                                    display="flex"
                                                    style={{
                                                        gap: "15px",
                                                        alignItems: "flex-start",
                                                        marginBottom: "16px",
                                                    }}
                                                >
                                                    <Select
                                                        name={`units[${index}]`}
                                                        label={`Unit ${index + 1}`}
                                                        options={actions.getAvailableUnitOptions(
                                                            index
                                                        )}
                                                        value={mapping.unit}
                                                        onChange={(unit) =>
                                                            actions.handleUnitChange(index, unit)
                                                        }
                                                    />
                                                    <Box>
                                                        <Controller
                                                            name={`roles[${index}]`}
                                                            control={form.control}
                                                            render={({ field }) => (
                                                                <Select
                                                                    {...field}
                                                                    label={`Role(s) for Unit ${
                                                                        index + 1
                                                                    }`}
                                                                    options={roleOptions}
                                                                    isMulti
                                                                    value={mapping.roles}
                                                                    onChange={(roles) =>
                                                                        actions.handleRoleChange(
                                                                            index,
                                                                            roles
                                                                        )
                                                                    }
                                                                />
                                                            )}
                                                        />
                                                    </Box>
                                                    <Button
                                                        plain
                                                        onClick={() =>
                                                            actions.handleDeleteUnit(index)
                                                        }
                                                        style={{ marginLeft: "auto" }}
                                                    >
                                                        <Icon
                                                            icon="trash"
                                                            color={theme.PrimaryRed}
                                                        />
                                                    </Button>
                                                </Box>
                                            </>
                                        ))
                                    ) : (
                                        <Box>
                                            {unitRoleMapping.map((mapping) => (
                                                <Box
                                                    key={mapping.unit}
                                                    style={{ marginBottom: "8px" }}
                                                >
                                                    <Box>
                                                        <Text bold block>{`Unit: ${
                                                            mapping.unit.label || "--"
                                                        }`}</Text>
                                                    </Box>
                                                    <Spacer space={4} />
                                                    <Text>{`Roles: ${mapping.roles
                                                        .map((role) => role.label)
                                                        .join(", ")}`}</Text>
                                                </Box>
                                            ))}
                                        </Box>
                                    )}

                                    {isSecretariateUser && (
                                        <Button onClick={actions.addMoreUnits}>Add Units</Button>
                                    )}
                                </Box>
                                <Spacer space={16} />
                            </Form>
                            <Spacer space={16} />
                            <Box display="flex" style={{ justifyContent: "space-between" }}>
                                <Button
                                    pale
                                    onClick={() => navigate(-1)}
                                    spaceRight
                                    width={theme.ButtonDesktopWidth}
                                >
                                    Back
                                </Button>

                                {showUpdateButton && (
                                    <Button
                                        progress={loading.isUpdatingUser}
                                        onClick={form.handleSubmit(actions.onSubmit)}
                                        width={theme.ButtonDesktopWidth}
                                    >
                                        {loading.isUpdatingUser ? "Updating..." : "Update User"}{" "}
                                    </Button>
                                )}
                            </Box>
                        </Section>
                    </Grid>
                </WithLoader>
            </Box>
        </PageLayout>
    );
}
