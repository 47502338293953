import { Box, Text, Spacer, Button } from "@flexisaf/flexibull2";
import { Modal } from "@/features/ui";
type ModalProps = {
    isModalOpen: boolean;
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
export default function DeleteNotificationModal({ isModalOpen, setIsModalOpen }: ModalProps) {
    return (
        <Modal
            title="Delete Record?"
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            centeredTitle
        >
            <Box>
                <Text block color={"#64748B"} style={{ textAlign: "center", marginTop: "-15px" }}>
                    You are about to delete a system record and this action cannot be reversed.
                    Please confirm before you proceed{" "}
                </Text>
                <Spacer space={"25px"} />
                <Box display="flex" style={{ justifyContent: "space-between" }}>
                    <Button onClick={() => setIsModalOpen(false)} pale>
                        No Cancel
                    </Button>
                    <Button onClick={() => setIsModalOpen(false)}>Yes Delete</Button>
                </Box>
            </Box>
        </Modal>
    );
}
