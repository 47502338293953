import { Box, Text, Spacer, Table } from "@flexisaf/flexibull2";
import { BsFillPlusSquareFill } from "react-icons/bs";
import { MeetingWrapper, WithLoader } from "@/features/ui";
import { memo, useEffect } from "react";
import { attachSuffix } from "./useSuffix";
import AttendanceModal from "./attendanceModal";
import { TextCover, StyledSpacer, SpaceBox } from "@/features/ui/meeting.style";
import { Title } from "../ui/menu";
import { UseAttendanceProps, useAttendance } from "./attendance.controller";

export const Attendance = memo(({ meetId }: UseAttendanceProps) => {
    const { variables, actions } = useAttendance({ meetId });
    const { added, attendeeList, date, usersLoading, meetingDetailData: data } = variables;
    useEffect(() => {
        if (added) {
            actions.setOpen(false);
        }
    }, [actions, added]);

    return (
        <>
            <Spacer space={30} />
            <Title>Meeting Attendance</Title>
            <StyledSpacer space={30} />
            <SpaceBox />
            <StyledSpacer space={10} />
            <Text size="12px" block>
                Automated Entries
            </Text>

            <TextCover style={{ width: "100%" }}>
                <div>
                    <Text size="12px" block>
                        Total No. of attendees
                    </Text>

                    <Text block size="16px" bold>
                        {attendeeList?.length}
                    </Text>
                </div>
                <div>
                    <Text size="12px" block>
                        Check-ins
                    </Text>
                    <Text block size="16px" bold>
                        0
                    </Text>
                </div>
            </TextCover>
            <SpaceBox />
            <WithLoader isLoading={usersLoading} height={"100vh"}>
                <MeetingWrapper>
                    <Text size="20px">Attendance</Text>
                    <Spacer space={10} />
                    <div style={{ display: "flex", flex: "1", justifyContent: "space-between" }}>
                        <div>
                            <Text size="15px">
                                {attachSuffix(data?.number)} {"  "} {data?.title}
                            </Text>
                        </div>
                        <div>
                            <Text size="15px"> {date}</Text>
                        </div>
                    </div>

                    <Table stripped>
                        <table>
                            <thead>
                                <tr>
                                    <th>S/N.</th>
                                    <th>Name</th>
                                    <th>Unit</th>
                                </tr>
                            </thead>
                            <tbody>
                                {attendeeList?.map((data, index) => (
                                    <tr key={data?.id}>
                                        <td>{index + 1}</td>
                                        <td>
                                            {data?.staff?.firstName}-{data?.staff?.lastName}
                                        </td>
                                        <td>{data?.staff?.rank}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Table>
                    <div
                        onClick={() => actions.setOpen(true)}
                        style={{
                            float: "right",
                            color: "#1890FF",
                        }}
                    >
                        <Box display="flex" style={{ alignItems: "center" }}>
                            <Text
                                style={{
                                    marginTop: "-5px",
                                }}
                                size="14px"
                            >
                                Add Attendee(s) {"  "}{" "}
                            </Text>
                            <Spacer direction="horizontal" space={4} />
                            <BsFillPlusSquareFill color="#1890FF" size={15} />
                        </Box>
                    </div>
                </MeetingWrapper>
            </WithLoader>
            <AttendanceModal variables={variables} actions={actions} />
        </>
    );
});
