import { useAuthUserNavigate } from "@/features/auth";
import { Link } from "react-router-dom";

type CrumbProps = {
    to: string;
    title: string;
};
export function Crumb(props: CrumbProps) {
    const { to, title } = props;
    const { buildPath } = useAuthUserNavigate();
    return <Link to={buildPath(to) as string}> {title}</Link>;
}
