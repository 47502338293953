import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Spacer, Text, Box, Button } from "@flexisaf/flexibull2/";

import { appPaths } from "@/app/app-paths";
import { typography } from "@/style/typography";
import { HookForm as Form } from "@/features/ui";
import { ForgotPasswordParams } from "@/features/auth/auth.api";
import { AuthLayout } from "@/features/auth/auth-layout";
import { statusColors } from "@/style/colors";
import theme from "@/style/theme";

import { useForgotPassword } from "./use-forgot-password";

const FORGOT_PASSWORD_MSG =
    "Please enter your email address to receive a secure link to reset your password";
const RESET_LINK_SENT_MSG =
    "A link for resetting your password has been sent to the email address linked to your student account";

export function ForgotPassword() {
    const { requestResetLink, validation, isRequestingLink, isRequestLinkSent, requestLinkError } =
        useForgotPassword();
    const form = useForm<ForgotPasswordParams>({
        resolver: yupResolver(validation),
    });

    return (
        <AuthLayout>
            <Box>
                <Box pad="24px 0">
                    <Text {...typography.h2} bold block>
                        Forgot Password
                    </Text>
                </Box>

                <Text
                    {...{
                        color: isRequestLinkSent ? statusColors.success.color : theme.PrimaryGrey,
                    }}
                    style={{ textAlign: "center" }}
                    block
                >
                    {isRequestLinkSent ? RESET_LINK_SENT_MSG : FORGOT_PASSWORD_MSG}
                </Text>
                <Spacer space={16} />

                {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
                <Form form={form} onSubmit={form.handleSubmit(requestResetLink)}>
                    <Form.Input name="username" spaceBottom block type="email" />

                    <>
                        <Text
                            className="login-error"
                            color={statusColors.error.color}
                            block
                            style={{ height: "48px" }}
                        >
                            {requestLinkError?.message}
                        </Text>
                        <Spacer space={8} />
                    </>

                    <Button
                        progress={isRequestingLink}
                        block
                        onClick={form.handleSubmit(requestResetLink)}
                    >
                        Request Reset Link
                    </Button>
                    <Spacer space={16} />
                    <Link to={appPaths.login}>
                        <Text>
                            <i className="icon-angle-left" />
                            Back
                        </Text>
                    </Link>
                </Form>
            </Box>
        </AuthLayout>
    );
}
