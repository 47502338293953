import sortBy from "lodash/sortBy";

import { Prayer } from "@/features/prayers";
import { AuthUser } from "@/features/auth";
import type { DataItemProps } from "@/features/ui/base/data-item/data-item";
import { PrayerStatuses, Unit } from "@/utils/enums";
import { theme } from "@/style";

import { PrayerItem } from "./agenda-api";
import { titleCase } from "@/utils/string-utils";

export type DepartmentMap = Record<string, { count: number; items: PrayerItem[] }>;

export type FacultyMap = {
    [facultyName: string]: Record<string, { name: string; count: number }>;
};

export const OTHERS_LABEL = "Others";

export const sortByPosition = (items) => sortBy(items, ["position"], ["asc"]);

export const groupedByDepartment = (prayerItems: PrayerItem[]): DepartmentMap => {
    const groups: DepartmentMap = {};

    for (const prayerItem of prayerItems) {
        const departmentName = prayerItem.prayer?.department?.name ?? OTHERS_LABEL;

        if (!groups[departmentName]) {
            groups[departmentName] = { count: 0, items: [] };
        }

        groups[departmentName].items.push(prayerItem);
        groups[departmentName].count += 1;
    }

    return groups;
};

export const groupDepartmentByFaculties = (prayerItems: PrayerItem[]): FacultyMap => {
    const result: FacultyMap = {};

    for (const item of prayerItems) {
        const facultyName = item.prayer.faculty?.name ?? OTHERS_LABEL;
        const departmentName = item.prayer.department?.name ?? OTHERS_LABEL;

        if (!result[facultyName]) {
            result[facultyName] = {};
        }

        if (!result[facultyName][departmentName]) {
            result[facultyName][departmentName] = { name: departmentName, count: 0 };
        }

        result[facultyName][departmentName].count++;
    }

    return result;
};

export const getDataItemTextProps = (item: Prayer, user: AuthUser) => {
    const fields: Partial<DataItemProps> = { subtitle: item.title };
    const itemCategory = titleCase(item.category);
    if (user.currentRole.unit === Unit.Department) {
        fields.caption = itemCategory;
        return fields;
    }
    if (user.currentRole.unit === Unit.Faculty) {
        fields.caption = `${itemCategory} ${item.department ? `| ${item.department.name}` : ""}`;
        return fields;
    }
    fields.caption = `${item.faculty ? `${item.faculty.name} |` : ""}  ${
        item.department ? item.department?.name : ""
    }`;

    return fields;
};

const buildStatusTagProps = (color: string, status: string) => ({ status, color });
export const getStatusTagProps = (status, userUnit) => {
    if (userUnit === Unit.Department) {
        return status === PrayerStatuses.Initiated
            ? buildStatusTagProps(theme.PrimaryGrey, "Initiated")
            : buildStatusTagProps(theme.PrimaryGreen, "Raised");
    }

    if (userUnit === Unit.Faculty) {
        return status === PrayerStatuses.Raised
            ? buildStatusTagProps(theme.PrimaryGrey, "Raised")
            : buildStatusTagProps(theme.PrimaryGreen, "Submitted");
    }

    if (userUnit === Unit.Sbc) {
        if (status === PrayerStatuses.Submitted) {
            return buildStatusTagProps(theme.PrimaryGrey, "Submitted");
        }
        if (status === PrayerStatuses.SteppedDown) {
            return buildStatusTagProps(theme.PrimaryRed, "Stepped Down");
        }
        if (status === PrayerStatuses.Recommended) {
            return buildStatusTagProps(theme.PrimaryGreen, "Recommended");
        }
    }

    if (status === PrayerStatuses.Recommended) {
        return buildStatusTagProps(theme.PrimaryGrey, "Recommended");
    }
    if ([PrayerStatuses.Approved, PrayerStatuses.ConditionalApproval].includes(status)) {
        return buildStatusTagProps(theme.PrimaryGreen, titleCase(status));
    }
    if (status === PrayerStatuses.Rejected) {
        return buildStatusTagProps(theme.PrimaryRed, "Rejected");
    }
};
