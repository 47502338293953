import { Outlet, Link } from "react-router-dom";
import { Text, Box, Button, Grid, Spacer, FlexiPagination, Tag } from "@flexisaf/flexibull2";

import { AuthUser, useAuth, useAuthSlice, useAuthUserNavigate } from "@/features/auth";
import { Field, Modal, PageLayout, SearchBar, Section, Table, WithLoader } from "@/features/ui";
import { theme } from "@/style";
import { Icon } from "@/features/ui/base/icon";
import { formatNumber } from "@/utils/number-utils";
import { appPaths } from "@/app";
import { IconsaxIcon } from "@/assets/iconsax";
import { PrivilegeGuard, Privileges } from "@/features/authorization";
import { pageOptions } from "@/utils/object-utils";
import { titleCase } from "@/utils/string-utils";
import { useResponsiveValue } from "@/lib/use-responsive-value";

import { useUsers } from "./use-users";
import {
    Staff,
    useDownloadStaffTemplateMutation,
    useUploadStaffTemplateMutation,
} from "../users-api";
import { useEffect, useState } from "react";

type GetColumnsArgs = { onActionClick: (user: Staff) => void };

const getColumns = ({ onActionClick }: GetColumnsArgs) => [
    {
        title: "Name",
        render: ({ firstName, lastName }) => (
            <Text style={{ width: "240px" }}>
                {titleCase(`${firstName ?? ""} ${lastName ?? ""}`)}
            </Text>
        ),
        fixed: true,
    },
    {
        title: "Username",
        dataIndex: "username",
    },
    {
        title: "Status",
        render: ({ active }) =>
            (
                <Tag
                    color={active ? theme.PrimaryGreen : theme.PrimaryRed}
                    style={{ border: "none" }}
                >
                    {active ? "Active" : "Inactive"}
                </Tag>
            ) ?? "N/A",
    },
    {
        title: "",
        width: "48px",
        render: (user: Staff) => (
            <Button
                plain
                onClick={() => onActionClick(user)}
                style={{ transform: "rotateZ(90deg)", width: "min-content" }}
            >
                <Icon icon="more" />{" "}
            </Button>
        ),
    },
];

const getWelcomeMessage = (user: AuthUser) => {
    return "This is where all users are being managed";
};

export default function Users() {
    const { selectedUser, queryState, page, usersData, isStatsEnabled, actions, loading, errors } =
        useUsers();
    const { navigateTo, buildPath } = useAuthUserNavigate();
    const { user } = useAuth();

    const isMobile = useResponsiveValue({ sm: true, md: false }) as boolean;
    const [excelFile, setExcelFile] = useState<any>();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const toogleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const createUploadFunction = (
        fieldName: string,
        mutationFn: (formData: FormData) => Promise<any>
    ) => {
        return async (fileObj: any): Promise<void> => {
            const bodyFormData = new FormData();
            bodyFormData.append(fieldName, fileObj);
            return await mutationFn(bodyFormData);
        };
    };
    const [upload, { isLoading: isUploading, isSuccess }] = useUploadStaffTemplateMutation();
    const [download, { isLoading: isDownloading }] = useDownloadStaffTemplateMutation();
    const uploadStaffFile = createUploadFunction("file", upload);

    const { access_token: token } = useAuthSlice();

    useEffect(() => {
        if (isSuccess) {
            setExcelFile(null);
            toogleModal();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess]);
    const handleFileChange = (e) => {
        const files = e.target.files;
        if (files && files[0]) {
            setExcelFile(files[0]);
        }
    };

    return (
        <>
            <Outlet />
            <PageLayout pageLabel="Users" header="Users" caption={getWelcomeMessage(user)}>
                <Box>
                    <Spacer space={16} />
                    <Section
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                        }}
                    >
                        <Box>
                            <Text bold block size="1.5em" style={{ color: theme.PrimaryGreyDark }}>
                                Users
                            </Text>

                            <Spacer space={8} />
                            <Text> Manage User Accounts</Text>
                        </Box>
                        <Button pale onClick={() => setIsModalOpen(true)}>
                            Bulk Upload
                        </Button>
                        <Box>
                            <Link to={buildPath(appPaths.createUser) ?? "#"}>
                                <Button iconRight={<Icon icon="add" color={theme.PrimaryWhite} />}>
                                    New User
                                </Button>
                            </Link>
                        </Box>
                    </Section>
                    <Spacer space={16} />

                    {isStatsEnabled && (
                        <Grid
                            default="2fr 1fr"
                            sm="2fr 1fr"
                            md="2fr 1fr"
                            style={{ background: theme.SecondaryGrey }}
                        >
                            <Grid
                                default="1fr 1fr"
                                sm="1fr 1fr"
                                md="1fr 1fr"
                                style={{ borderRadius: 4, padding: 12 }}
                            >
                                <Field title="Faculty Members" value={formatNumber(200)} />

                                <Field title="Department" value={formatNumber(200)} />

                                <Field title="SBC Members" value={formatNumber(200)} />
                                <Field title="Secretariat" value={formatNumber(200)} />
                            </Grid>
                            <Box style={{ alignSelf: "center" }}>
                                <Field
                                    flexAlign="center"
                                    title={"Total Users"}
                                    value={formatNumber(usersData?.total ?? 0)}
                                    valueStyles={{ fontSize: "3em" }}
                                />
                            </Box>
                        </Grid>
                    )}

                    <Spacer space={16} />
                    <Section>
                        <SearchBar
                            value={queryState["search-word"] ?? ""}
                            onChange={actions.searchUser}
                            placeholder="Search for User..."
                            isSearching={loading.isUpdatingUsers}
                        />
                        <Spacer space={24} />
                        <WithLoader isLoading={loading.isLoadingusers} error={errors.usersError}>
                            <>
                                <Text block>Users List</Text>
                                <Table
                                    columns={getColumns({ onActionClick: actions.setUser })}
                                    data={usersData?.entities ?? []}
                                    isLoading={loading.isUpdatingUsers}
                                />
                                <Spacer space={16} />
                                <FlexiPagination
                                    pageCounts={pageOptions}
                                    total={usersData?.total}
                                    itemsDisplayed
                                    pageSize={queryState.limit}
                                    changePageSize={({ value }) => actions.setLimit(value)}
                                    onChange={(val: number) => actions.setPage(val)}
                                    current={page}
                                    style={{ maxWidth: "100%" }}
                                />
                            </>
                        </WithLoader>
                        <Modal
                            title="Actions"
                            position={isMobile ? "bottom" : "center"}
                            subtitle="What action do you want to take on this member?"
                            isOpen={!!selectedUser}
                            onClose={() => actions.setUser(null)}
                            topSpace={isMobile ? 24 : 0}
                        >
                            <Box>
                                <UserAction
                                    icon="user-edit"
                                    title="View User"
                                    callback={() =>
                                        navigateTo(appPaths.getUser(selectedUser?.umsId))
                                    }
                                />
                                {selectedUser?.active ? (
                                    <PrivilegeGuard privileges={Privileges.DeleteUsers}>
                                        <UserAction
                                            icon="user-remove"
                                            color={theme.PrimaryRed}
                                            title="Deactivate User"
                                            callback={() =>
                                                actions.deactivateStaff(selectedUser?.umsId)
                                            }
                                        />
                                    </PrivilegeGuard>
                                ) : (
                                    <UserAction
                                        icon="user-remove"
                                        color={theme.PrimaryGreen}
                                        title="Activate User"
                                        callback={() => {
                                            actions.activateStaff(selectedUser?.umsId as string);
                                            actions.clearSelectedUser();
                                        }}
                                    />
                                )}
                                <Spacer space={24} />
                            </Box>
                        </Modal>
                    </Section>
                </Box>
            </PageLayout>
            <Modal
                isOpen={isModalOpen}
                withCloseButton
                title="Staff Upload in Bulk"
                onClose={() => {
                    setIsModalOpen(false);
                    setExcelFile(null);
                }}
                titleSize="small"
            >
                <>
                    <Text>NB: Please read the information below before proceeding</Text>
                    <Box background={theme.BackgroundColor} pad="10px">
                        <Text>Preparing your file</Text>
                        <Spacer space={10} />
                        <Text>
                            We have provided you with excel template for uploading your students,
                            click the button below to download the template.
                        </Text>

                        <Text
                            color={theme.PrimaryColor}
                            style={{ display: "block", cursor: "pointer", margin: "10px 0px" }}
                            onClick={() => download(token)}
                        >
                            {isDownloading ? "Downloading..." : "Dowload Template"}
                        </Text>
                    </Box>
                    <Box>
                        <div
                            style={{
                                border: "2px dashed #ccc",
                                padding: "20px",
                                textAlign: "center",
                            }}
                        >
                            <p>Click to select file.</p>
                            {excelFile ? (
                                excelFile.name
                            ) : (
                                <label htmlFor="file">
                                    <Button pale>Select File</Button>
                                </label>
                            )}
                            <input
                                type="file"
                                id="file"
                                name="filename"
                                onChange={handleFileChange}
                                style={{ display: "none" }}
                            />
                        </div>
                    </Box>
                    <Box
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: "10px",
                        }}
                    >
                        <Button
                            pale
                            onClick={() => {
                                setExcelFile(null);
                                setIsModalOpen(false);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            disabled={!excelFile}
                            size="15px"
                            onClick={() => {
                                uploadStaffFile(excelFile);
                            }}
                        >
                            {isUploading ? "Uploading..." : "Upload"}
                        </Button>
                    </Box>
                </>
            </Modal>
        </>
    );
}

interface UserActionProps {
    icon: IconsaxIcon;
    title: string;
    callback: () => void;
    color?: string;
}

export function UserAction(props: UserActionProps) {
    return (
        <Box
            display="flex"
            style={{
                borderBottom: "1px solid lightgray",
                alignItems: "center",
                padding: "1em",
                gap: 16,
                cursor: "pointer",
            }}
            onClick={props.callback}
        >
            <Icon icon={props.icon} color={props.color} />
            <span style={{ color: props.color }}>{props.title}</span>
        </Box>
    );
}
