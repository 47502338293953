import { useMemo } from "react";
import { MeetingStatus, Unit } from "@/utils/enums";
import { useAuth } from "@/features/auth";
import { MeetingFilterParams, useGetFilteredMeetingsQuery } from "./meeting.api";

export default function useGetUpcomingSenateMeeting() {
    const { user } = useAuth();

    const queryParams = useMemo<MeetingFilterParams>(
        () => ({
            status: MeetingStatus.Inactive,
            unit: Unit.Senate,
        }),
        []
    );

    const { data: meetingData, ...filteredMeetingMeta } = useGetFilteredMeetingsQuery(queryParams, {
        skip: !user,
    });

    return {
        meeting: meetingData?.entities[0],
        ...filteredMeetingMeta,
    };
}
