import { Box, Spacer, Text } from "@flexisaf/flexibull2";
import { theme } from "@/style";

type StatusIndicatorProps = { status?: string; color?: string; size?: string };
export function StatusIndicator(props: StatusIndicatorProps) {
    const { status, color = theme.PrimaryGrey, size = "10px" } = props;
    return (
        <Box
            style={{
                borderRadius: "12px",
                border: `1px solid ${color}`,
                padding: "4px 6px",
                display: "inline-flex",
                alignItems: "center",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
            }}
        >
            <Box
                style={{
                    width: size,
                    height: size,
                    borderRadius: "100%",
                    background: color,
                }}
            ></Box>
            <Spacer space={4} direction="horizontal" />
            <Text size={size} color={color}>
                {status}
            </Text>
        </Box>
    );
}
