import { useCallback, useMemo, useState, useEffect } from "react";
import { Table as FlexibullTable } from "@flexisaf/flexibull2/build/table";

import { SortDirection } from "@/utils/enums";

import { TitleCellWithSort } from "./title-cell-with-sort";
import { Actions } from "./actions";
import { TableAction } from "./action-item";

import { StyledTable } from "./table.styles";

type TableProps = {
    columns: Array<Record<string, any>>;
    data: any[];
    isUpdating?: boolean;
    isLoading?: boolean;
    onSort?: Function;
    actions?: TableAction[];
    [prop: string]: any;
};

export function Table(props: TableProps) {
    const { columns = [], data = [], isUpdating, onSort, actions, ...restProps } = props;

    const [sortField, setSortField] = useState("");
    const [sortDirection, setSortDirection] = useState<SortDirection>();

    const handleColumnHeaderClick = useCallback(
        (field) => {
            const newSortDirection =
                field !== sortField ? SortDirection.Asc : getNewSortDirection(sortDirection);
            setSortField(field);
            setSortDirection(newSortDirection);
            onSort?.({ field, sortDirection: newSortDirection });
        },
        [onSort, sortDirection, sortField]
    );

    const actualColumns = useMemo(() => {
        const enhancedColumns = columns.map((column) => {
            try {
                if (column.sort) {
                    if (!column.key) {
                        throw new Error("'key' not provided. Cannot sort without 'key'");
                    }
                    return {
                        ...column,
                        title: (
                            <TitleCellWithSort
                                title={column.title}
                                field={column.dataIndex || column.key}
                                currentSortField={sortField}
                                currentSortDirection={sortDirection}
                                onSort={handleColumnHeaderClick}
                            />
                        ),
                    };
                }
                return column;
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
                return column;
            }
        });

        if (actions) {
            if (!Array.isArray(actions)) {
                throw new Error("Expected actions to be an array of action items");
            }

            const actionColumn = {
                title: "Actions",
                render: (item) => <Actions actions={actions} item={item} />,
            };

            enhancedColumns.push(actionColumn);
        }

        return enhancedColumns;
    }, [columns, actions, sortField, sortDirection, handleColumnHeaderClick]);

    useEffect(() => {
        setSortField("");
        setSortDirection(undefined);
    }, [columns]);

    return (
        <FlexibullTable>
            <StyledTable
                columns={actualColumns}
                data={data}
                isUpdating={isUpdating}
                rowClassName="table-row"
                {...restProps}
            />
        </FlexibullTable>
    );
}

function getNewSortDirection(oldSortDirection) {
    return oldSortDirection === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc;
}
