import { useState, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useUpdateUserMutation, useGetUserQuery, useUpdateUserRoleMutation } from "../users-api";
import { ApiError } from "@/types/common";
import { Role, useGetRolesQuery } from "@/features/authorization";
import { useGetAllRolesQuery } from "@/features/roles";
import { titleCase } from "@/utils/string-utils";

type RoleIdAcc = Record<string, boolean>;

export function useUserProfile() {
    const { id } = useParams();

    const { data: user, ...userMeta } = useGetUserQuery(<string>id);
    const { data: rolesData, ...getRolesMeta } = useGetRolesQuery({
        limit: 100,
    });
    const [updateUserReq, updateeUserMeta] = useUpdateUserMutation();
    const { data: allRolesData } = useGetAllRolesQuery({ limit: 100 });
    const [updateUserRole, updateUserRoleMeta] = useUpdateUserRoleMutation();

    // State to manage units and roles
    const [unitRoleMapping, setUnitRoleMapping] = useState([
        {
            unit: { label: "", value: "" },
            roles: [
                {
                    label: "",
                    value: "",
                },
            ],
        },
    ]);

    const form = useForm();
    const formValues = form.watch();
    const { userRoleRequest, unit } = formValues;

    useEffect(() => {
        if (user) {
            const roles = user.roles.map(({ role, unit }) => ({
                ...role,
                label: titleCase(role.name),
                value: role.id,
                unit,
            }));
            form.setValue("userRoleRequest", roles);
            form.setValue("id", user.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const updateUser = (body) => updateUserReq(body);

    const roles = useMemo(() => {
        const userRoleMapById = userRoleRequest
            ? userRoleRequest.reduce((acc: RoleIdAcc, role: Role) => {
                  acc[role.id] = true;
                  return acc;
              }, {})
            : {};

        const roleFilter = (role: Role) => {
            if (userRoleMapById[role.id]) return false;

            if (unit) {
                return role.description === unit;
            }
            return role;
        };

        return rolesData?.entities.filter(roleFilter);
    }, [rolesData, unit, userRoleRequest]);

    const roleOptions = allRolesData?.entities.map((r) => ({
        label: r.name,
        value: r.id,
    }));
    const addRole = (role: Role) => {
        const newRoles = userRoleRequest ? [...userRoleRequest, role] : [role];
        form.setValue("userRoleRequest", newRoles);
    };

    const removeRole = (roleId: string) => {
        if (userRoleRequest) {
            form.setValue(
                "userRoleRequest",
                userRoleRequest.filter(({ id }) => id !== roleId)
            );
        }
    };

    const unitOptions = [
        { value: "SBC", label: "SBC" },
        { value: "SENATE", label: "SENATE" },
        { value: "FACULTY", label: "FACULTY" },
        { value: "DEPARTMENT", label: "DEPARTMENT" },
        { value: "SECRETARIAT", label: "SECRETARIAT" },
    ];

    const getAvailableUnitOptions = (index) => {
        const selectedUnits = unitRoleMapping.map((mapping) => mapping.unit.value);
        const availableUnits = unitOptions.filter(
            (option) => !selectedUnits.includes(option.value)
        );

        // Add the previously selected unit back to the options list if it's not already present
        if (
            unitRoleMapping[index].unit.value &&
            !selectedUnits.includes(unitRoleMapping[index].unit.value)
        ) {
            availableUnits.push(unitRoleMapping[index].unit);
        }

        return availableUnits;
    };

    // Function to handle unit and role changes
    const handleUnitChange = (index, unit) => {
        const newUnitRoleMapping = [...unitRoleMapping];
        newUnitRoleMapping[index].unit = unit;
        newUnitRoleMapping[index].roles = []; // Clear roles when unit changes
        setUnitRoleMapping(newUnitRoleMapping);
    };

    const handleRoleChange = (index, roles) => {
        const newUnitRoleMapping = [...unitRoleMapping];
        newUnitRoleMapping[index].roles = roles;
        setUnitRoleMapping(newUnitRoleMapping);
    };

    const addMoreUnits = () => {
        setUnitRoleMapping([...unitRoleMapping, { unit: { label: "", value: "" }, roles: [] }]);
    };

    const handleDeleteUnit = (index) => {
        const newUnitRoleMapping = unitRoleMapping.filter((_, i) => i !== index);
        setUnitRoleMapping(newUnitRoleMapping);
    };

    useEffect(() => {
        const { userRoleRequest } = formValues;

        // Check if userRoleRequest exists and is an array
        if (Array.isArray(userRoleRequest)) {
            const mappedRoles = userRoleRequest.reduce((acc, role) => {
                const { unit, id, name } = role;
                const existingUnit = acc.find((item) => item.unit.value === unit);

                if (existingUnit) {
                    // Combine roles for the same unit
                    existingUnit.roles = [...existingUnit.roles, { value: id, label: name }];
                } else {
                    acc.push({
                        unit: { label: unit, value: unit },
                        roles: [{ value: id, label: name }],
                    });
                }

                return acc;
            }, []);

            setUnitRoleMapping(mappedRoles);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formValues.userRoleRequest]);

    const onSubmit = (data) => {
        const roles = unitRoleMapping.flatMap(({ unit, roles }) =>
            roles.map((role) => ({
                id: role.value,
                admin_level: unit.value,
            }))
        );

        actions.updateUser({ ...data, roles });
    };

    const loading = {
        isLoading: userMeta.isLoading,
        isLoadingRoles: getRolesMeta.isLoading,
        isUpdatingUser: updateeUserMeta.isLoading,
        isUpdatingUserRole: updateUserRoleMeta.isLoading,
    };
    const errors = {
        userError: <ApiError>userMeta.error,
        updateUserError: <ApiError>updateeUserMeta.error,
        updateUserRoleError: <ApiError>updateUserRoleMeta.error,
    };

    const actions = {
        updateUser,
        addRole,
        removeRole,
        updateUserRole,
        getAvailableUnitOptions,
        handleUnitChange,
        handleRoleChange,
        addMoreUnits,
        handleDeleteUnit,
        onSubmit,
    };

    return {
        roles,
        form,
        user,
        formValues,
        loading,
        errors,
        actions,
        roleOptions,
        unitRoleMapping,
    };
}
