import { useState, useEffect } from "react";
import { useToggle } from "react-use";
import * as yup from "yup";

import { Box, Text, Button, Spacer } from "@flexisaf/flexibull2";

import { theme } from "@/style";

import { Modal, HookForm as Form, Icon } from "../../ui";
import { useGetSRMSResultsQuery } from "../prayers/prayer-api";
import { levelOptions, semesterOptions } from "../prayers-ui-options";
import { useResults } from "./use-results";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { DocumentPreviewerModal, DocumentUploader } from "@/features/documents";
import { titleCase, formatResultUrl } from "@/utils/string-utils";

type ModalProps = {
    title: string;
    isOpen: boolean;
    onClose: () => void;
    srmsResults: any;
    setSrmsResults: any;

    setLocalResults: any;
};

type Content = "srms" | "local";

export const ResultsModal = (props: ModalProps) => {
    const { title, isOpen, onClose, srmsResults, setSrmsResults, setLocalResults } = props;
    const [content, setContent] = useState<Content | null>(null);

    const closeModal = () => {
        onClose();
        setContent(null);
    };

    return (
        <Modal title={title} topSpace={20} isOpen={isOpen} onClose={closeModal}>
            {content === "local" ? (
                <LocalResults setLocalResults={setLocalResults} closeModal={closeModal} />
            ) : content === "srms" ? (
                <SrmsResultsForm
                    srmsResults={srmsResults}
                    setSrmsResults={setSrmsResults}
                    closeModal={closeModal}
                />
            ) : (
                <Box
                    display="flex"
                    style={{
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "20px",
                    }}
                >
                    <Text style={{ textAlign: "center" }}>
                        Select the location where you want to upload you result from
                    </Text>
                    <Box
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <Button
                            pale
                            pad="20px"
                            style={{ marginRight: "20px" }}
                            onClick={() => setContent("local")}
                        >
                            Local Storage
                        </Button>
                        <Button pad="20px" onClick={() => setContent("srms")}>
                            Get From SRMS
                        </Button>
                    </Box>
                </Box>
            )}
        </Modal>
    );
};

type Subprogram = {
    id: string;
    name: string;
};

type DataToSubmit = {
    subprogram: Subprogram;
    level: string;
    semester: string;
    session: string;
};

type SrmsResultsFromProps = {
    srmsResults: any;
    setSrmsResults: any;
    closeModal: () => void;
};

const SrmsResultsForm = (props: SrmsResultsFromProps) => {
    const { srmsResults, setSrmsResults, closeModal } = props;
    const { sessionOptions, subProgramOptions, isSessionsLoading, isSubprogramsLoading } =
        useResults();
    const [skip, setSkip] = useState(true);
    const [formValues, setFormValues] = useState<DataToSubmit>({
        subprogram: {
            id: "",
            name: "",
        },
        level: "",
        semester: "",
        session: "",
    });

    const {
        data: resultsData,
        isLoading,
        isFetching,
        refetch,
        isUninitialized,
    } = useGetSRMSResultsQuery(
        {
            sessionId: formValues.session,
            subProgramId: formValues.subprogram.id,
            "level-id": formValues.level,
            "semester-id": formValues.semester,
        },
        { skip }
    );

    useEffect(() => {
        if (
            !isLoading &&
            !isFetching &&
            Object.values(formValues).some((value) => {
                if (typeof value === "object" && value !== null) {
                    return Object.values(value).some((subValue) => subValue !== "");
                }
                return value !== "";
            })
        ) {
            // Check if the result already exists in the array
            const exists = srmsResults.some((result) => result.url === resultsData);
            if (!exists) {
                setSrmsResults([...srmsResults, { ...formValues, url: resultsData }]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, isFetching]);

    const resultsvalidation = yup.object().shape({
        subprogram: yup.object().shape({
            id: yup.string(),
            name: yup.string(),
        }),
        level: yup.string().required("Level is required"),
        semester: yup.string().required("Semester is required"),
        session: yup.string().required("Session is required"),
    });

    const form = useForm<DataToSubmit>({
        resolver: yupResolver(resultsvalidation),
    });

    function submitResults(values): void {
        setFormValues(values);
        setSkip(false);
        if (!isUninitialized) {
            refetch();
        }
    }

    function handleRemoveResults(url: string) {
        setSrmsResults(srmsResults.filter((result) => result.url !== url));
    }

    function removeResults(url: string) {
        return () => handleRemoveResults(url);
    }

    return (
        <Box>
            <Form form={form}>
                <Text>Fill in the details below to download result</Text>
                <Spacer space={36} />
                <Form.Select
                    name="subprogram"
                    label="Subprogram"
                    placeholder="Select Subprogram"
                    options={subProgramOptions}
                    isLoading={isSubprogramsLoading}
                    style={{ width: "100%" }}
                    onChange={(e) => {
                        form.setValue("subprogram.id", e.value);
                        form.setValue("subprogram.name", e.label);
                    }}
                />
                <Spacer space={24} />
                <Form.Select
                    name="level"
                    label="Level"
                    placeholder="Select Level"
                    options={levelOptions}
                    style={{ width: "100%" }}
                />
                <Spacer space={24} />
                <Form.Select
                    name="session"
                    label="Session"
                    placeholder="Select Session"
                    options={sessionOptions}
                    isLoading={isSessionsLoading}
                    style={{ width: "100%" }}
                />
                <Spacer space={24} />
                <Form.Select
                    name="semester"
                    label="Semester"
                    placeholder="Select Semester"
                    options={semesterOptions}
                    style={{ width: "100%" }}
                />

                <Spacer space={32} />
                <Button
                    style={{ width: "100%" }}
                    pale
                    progress={isFetching}
                    onClick={form.handleSubmit(submitResults)}
                >
                    {isFetching ? "Getting Result..." : "Get Result"}
                </Button>
                <Spacer space={10} />
                {srmsResults.length > 0 && (
                    <Box>
                        <Text> Selected Results</Text>

                        {srmsResults.map((formData, index) => {
                            return (
                                <>
                                    <Spacer space={7} />
                                    <SubProgramResults
                                        key={index}
                                        subprogram={formData.subprogram}
                                        level={formData.level}
                                        session={formData.session}
                                        semester={formData.semester}
                                        onDelete={removeResults(formData.url)}
                                        resultUrl={formData.url}
                                    />
                                </>
                            );
                        })}
                    </Box>
                )}
            </Form>
            <Spacer space={36} />
            <Button style={{ width: "100%" }} onClick={closeModal}>
                Attach Results
            </Button>
        </Box>
    );
};

type LocalResultsProps = {
    setLocalResults: any;
    closeModal: () => void;
};

const LocalResults = ({ setLocalResults, closeModal }: LocalResultsProps) => {
    return (
        <Box>
            <Text> Fill in the details below to upload result</Text>
            <Spacer space={36} />
            <Box style={{ maxHeight: "350px", overflowY: "auto" }}>
                <DocumentUploader onChange={(files: File[]) => setLocalResults(files)}>
                    <Box>
                        <Text color={theme.PrimaryBlue}>Attach Files</Text>
                    </Box>
                </DocumentUploader>
            </Box>
            <Spacer space={32} />
            <Button style={{ width: "100%" }} pale onClick={closeModal}>
                Attach Results
            </Button>
        </Box>
    );
};

type SubProgramProps = {
    subprogram: any;
    level: string;
    semester: string;
    session: string;
    onDelete?: any;
    onOpen?: any;
    resultUrl?: string;
};

export const SubProgramResults = (props: SubProgramProps) => {
    const { level, semester, session, onDelete, onOpen, resultUrl, subprogram } = props;

    const [isPreviewerOpen, setIsPreviewerOpen] = useToggle(false);

    const formattedResultUrl = formatResultUrl(resultUrl ?? "");

    return (
        <>
            <Box
                display="flex"
                pad="0 0 5px 0"
                style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    cursor: onOpen ? "pointer" : "default",
                    borderBottom: `1px solid ${theme.PrimaryGreyLight}`,
                }}
                onClick={onOpen}
            >
                <Box>
                    <Text block style={{ fontSize: "12px" }}>
                        {subprogram?.name}
                    </Text>

                    <Text>
                        <Text color={theme.PrimaryGreyMid}>Level {titleCase(level)} -</Text>
                        <Text color={theme.PrimaryGreyMid}> {session} -</Text>
                        <Text color={theme.PrimaryGreyMid}>
                            {semester === "FIRST" ? "1st" : "2nd"} Semester
                        </Text>
                    </Text>
                </Box>
                <Box>
                    {resultUrl && (
                        <Button
                            plain
                            onClick={() => setIsPreviewerOpen(true)}
                            style={{ marginRight: "-8px" }}
                        >
                            <Icon icon="eye" color={theme.PrimaryColor} />
                        </Button>
                    )}

                    {onDelete && (
                        <Button plain onClick={onDelete} color={theme.PrimaryRed}>
                            <Icon icon="minus-circle" color={theme.PrimaryRed} />
                        </Button>
                    )}
                </Box>
            </Box>

            <DocumentPreviewerModal
                isOpen={isPreviewerOpen}
                onClose={() => setIsPreviewerOpen(false)}
                url={formattedResultUrl}
            />
        </>
    );
};
