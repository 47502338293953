import { Notify } from "@flexisaf/flexibull2";

import { baseApi } from "@/store/base-api";
import { Methods } from "@/utils/enums";
import { QueryFulfilledError } from "@/types/common";

export const documentApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        postDocument: builder.mutation<string, PostDocumentRequest>({
            query: ({ formData }) => ({
                url: "/documents",
                data: formData,
                method: Methods.Post,
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }),
            async onQueryStarted(req, { queryFulfilled: qf }) {
                qf.then(() => {
                    if (req.showSuccessNotification) {
                        const successMsg = getFileUploadedMessage(
                            req.formData.get("fileName") as string
                        );
                        Notify(successMsg);
                    }
                }).catch((err: QueryFulfilledError) => {
                    if (req.showErrorNotification) {
                        const errorMsg = getFileUploadErrorMessage(
                            req.formData.get("fileName") as string,
                            err.error?.message
                        );
                        Notify(errorMsg, { status: "error" });
                    }
                });
            },
        }),
    }),
});
function getFileUploadedMessage(fileName: string) {
    return `${fileName} has been uploaded successfully`;
}
function getFileUploadErrorMessage(fileName: string, errorMessage: string | undefined) {
    return `An error occured while uploading file "${fileName}"${
        errorMessage ? `: ${errorMessage}` : ""
    }`;
}

export type PostDocumentRequest = {
    formData: FormData;
    showErrorNotification?: boolean;
    showSuccessNotification?: boolean;
    onUploadProgress?: (val: number) => void;
};

export const { usePostDocumentMutation } = documentApi;
