import { createRoot } from "react-dom/client";
import { App } from "./app";
import reportWebVitals from "./reportWebVitals";
import "@/assets/fonts/general-icons/css/icons.css";

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
    // @note: StrictMode is not used, as it conflicts with the drag and drop library
    <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
