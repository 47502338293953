import { useEffect } from "react";
import * as yup from "yup";

import { Title } from "../ui/menu";
import { Spacer, Text, Box, Button } from "@flexisaf/flexibull2";

import {
    BackButton,
    HookForm as Form,
    MeetingWrapper,
    PageLayout,
    WithLoader,
} from "@/features/ui";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMeeting } from "./meeting.controller";
import { useAuthUserNavigate } from "@/features/auth/use-auth-user-navigate";
import { appPaths } from "@/app";
import { useAuth } from "../auth";
import { Unit } from "@/utils/enums";
import { DocumentUploader, useUploadDocument } from "@/features/documents";
import { theme } from "@/style";

const validation = yup.object().shape({
    title: yup.string().required("Meeting Title is required"),
    description: yup.string().required("Description is required"),
    type: yup.string().oneOf(["Regular", "Special"]).required("Please select a type"),
    datetime: yup.string().required("Time is required"),
    venue: yup.string().required("Venue is required"),
    unit: yup.string().required("Participants field is required"),
});

export const NewMeet = () => {
    const { createMeeting, meetingCreated, loading } = useMeeting();
    const { navigateTo } = useAuthUserNavigate();
    const { user } = useAuth();
    const showUnit = user.currentRole.unit === Unit.Secretariat;
    const { upload, isUploading } = useUploadDocument();

    const Options = showUnit
        ? [
              { value: "SBC", label: "SBC" },
              { value: "SENATE", label: "SENATE" },
              { value: "FACULTY", label: "FACULTY" },
              { value: "DEPARTMENT", label: "DEPARTMENT" },
          ]
        : [{ value: user.currentRole.unit, label: user.currentRole.unit }];

    const form = useForm({
        resolver: yupResolver(validation),
    });

    useEffect(() => {
        if (meetingCreated) {
            navigateTo(appPaths.dashboard);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [meetingCreated]);

    const onSubmit = async (values) => {
        const { preAgenda, otherAttachment, ...restForm } = values;

        if (preAgenda) {
            const preAgendaUrl = await upload({ file: preAgenda, fileName: preAgenda.name });
            restForm.preAgenda = preAgendaUrl;
        }

        if (otherAttachment) {
            const attachmentUrl = await upload({
                file: otherAttachment,
                fileName: otherAttachment.name,
            });
            restForm.otherAttachment = attachmentUrl;
        }

        const data = {
            ...restForm,
            roleId: user?.currentRole?.role.id,
            departmentId: user?.department?.id,
            facultyId: user?.faculty ? user.faculty?.id : user?.department?.faculty.id,
        };
        createMeeting(data);
    };

    return (
        <PageLayout>
            <WithLoader isLoading={false} height={"100vh"}>
                <MeetingWrapper>
                    <Box>
                        <BackButton />
                    </Box>
                    <Spacer space={30} />
                    <Title>Meeting</Title>
                    <Spacer space={5} />
                    <Text
                        style={{
                            color: "#64748B",
                            fontSize: "13px",
                            fontWeight: 400,
                        }}
                    >
                        Create and schedule upcoming meeting instantly.{" "}
                    </Text>
                    <Spacer space={39} />
                    <Form
                        form={form}
                        onSubmit={form.handleSubmit(onSubmit)}
                        preventEnterKeyToSubmit={true}
                    >
                        <Box>
                            <Form.Input
                                name="title"
                                label="Meeting Title *"
                                style={{
                                    width: "100%",
                                }}
                            />
                        </Box>
                        <Spacer space={39} />

                        <Box>
                            <Text size="12px" bold>
                                Description
                            </Text>
                            <Spacer space={8} />
                            <Form.ControlledEditor control={form.control} name="description" />
                        </Box>
                        <Spacer space={39} />

                        <Text size="12px" bold>
                            Type of meeting *
                        </Text>
                        <Spacer space={5} />
                        <Box
                            style={{
                                display: "flex",
                                flex: "1",
                            }}
                        >
                            <Form.CheckboxInput
                                form={form}
                                label="Regular"
                                name="type"
                                round
                                block
                            />
                            <Form.CheckboxInput
                                form={form}
                                label="Special"
                                name="type"
                                round
                                block
                            />
                        </Box>
                        <Spacer space={39} />

                        <Text size="12px" bold>
                            Pre-agenda *
                        </Text>
                        <Box
                            style={{
                                display: "flex",
                                flex: "1",
                            }}
                        >
                            <DocumentUploader
                                onChange={(files: File[]) => form.setValue("preAgenda", files[0])}
                            >
                                <div>
                                    <Text color={theme.PrimaryBlue}>Attach File</Text>
                                </div>
                            </DocumentUploader>
                        </Box>
                        <Spacer space={10} />
                        <Box>
                            <label>Date</label>
                            <Form.TimeInput
                                label="Time *"
                                form={form}
                                name="datetime"
                                style={{
                                    width: "100%",
                                }}
                                placeholder="time"
                            />
                        </Box>

                        <Spacer space={39} />

                        <Box>
                            <Form.Input
                                name="venue"
                                form={form}
                                label="Venue"
                                style={{
                                    width: "100%",
                                }}
                                placeholder="Where will this meeting take place?"
                            />
                        </Box>
                        <Spacer space={39} />
                        <Box>
                            <Form.Select
                                name="unit"
                                label="Participants (Unit) *"
                                style={{
                                    width: "100%",
                                }}
                                options={Options}
                            />
                        </Box>
                        <Spacer space={39} />

                        <Text bold>Other attachment</Text>
                        <DocumentUploader
                            onChange={(files: File[]) => form.setValue("otherAttachment", files[0])}
                        >
                            <div>
                                <Text color={theme.PrimaryBlue}>Attach File</Text>
                            </div>
                        </DocumentUploader>
                        <Spacer space={10} />
                        <Button
                            style={{
                                width: "100%",
                            }}
                            onClick={form.handleSubmit(onSubmit)}
                            progress={isUploading || loading}
                        >
                            Create Meeting{" "}
                        </Button>
                        <Spacer space={39} />
                    </Form>
                    <Box
                        style={{
                            textAlign: "center",
                        }}
                    >
                        <Text>
                            All participant will get a meeting invite and a copy of the attachment
                        </Text>
                    </Box>
                </MeetingWrapper>
            </WithLoader>
        </PageLayout>
    );
};
