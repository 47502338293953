import { useResponsiveValue } from "@/lib/use-responsive-value";
import { theme } from "@/style";
import { Text, Spacer, Box } from "@flexisaf/flexibull2";

type PageLayoutProps = {
    children: React.ReactElement;
    pageLabel?: React.ReactNode;
    minHeight?: string | number;
    header?: React.ReactNode;
    caption?: React.ReactNode;
    pageBottomContent?: React.ReactNode;
    style?: React.CSSProperties;
};
export function PageLayout(props: PageLayoutProps) {
    const { children, pageLabel, minHeight = "auto", pageBottomContent, style = {} } = props;

    const showPageLabel = useResponsiveValue({ sm: true, md: false }) as boolean;
    const marginLeft = useResponsiveValue({
        sm: "auto",
        md: `${theme.SideBarDesktopWidth}px`,
    }) as string;

    return (
        <Box
            style={{
                marginLeft,
            }}
        >
            <Box
                display="flex"
                maxWidth="800px"
                style={{
                    margin: "0 auto",
                    position: "relative",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    minHeight,
                    ...style,
                }}
            >
                <Box>
                    {pageLabel && showPageLabel && (
                        <>
                            <Text
                                bold
                                block
                                style={{ textAlign: "center", color: theme.PrimaryGrey }}
                            >
                                {pageLabel}
                            </Text>
                            <Spacer space={40} />
                        </>
                    )}
                    <Box>{children}</Box>
                </Box>
                <Box className="page-bottom">{pageBottomContent}</Box>
            </Box>
        </Box>
    );
}
