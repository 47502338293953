import { Notify } from "@flexisaf/flexibull2";

import { baseApi } from "@/store/base-api";
import { Methods, Unit } from "@/utils/enums";
import { List, QueryFulfilledError } from "@/types/common";
import { Meeting } from "../meetings/meeting.api";
import { Prayer } from "../prayers";

const AGENDA_CREATED_MSG = "Agenda created successfully";
const AGENDA_UPDATED_MSG = "Agenda was updated successfully";

export const agendaApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getAgendas: builder.query<List<AgendaDetails>, AgendaParams>({
            query: (params) => ({
                url: "/agendas",
                params,
            }),
            providesTags: ["AGENDA"],
        }),
        getAgenda: builder.query<AgendaDetails, string>({
            query: (id) => ({
                url: `/agendas/${id}`,
            }),
            providesTags: ["AGENDA"],
        }),
        createAgenda: builder.mutation<unknown, NewAgendaRequest>({
            query: (data) => ({
                url: "/agendas",
                method: Methods.Post,
                data,
            }),
            invalidatesTags: ["AGENDA"],
            async onQueryStarted(_, { queryFulfilled: qf }) {
                qf.then(() => Notify(AGENDA_CREATED_MSG)).catch((err: QueryFulfilledError) =>
                    Notify(err.error?.message, { status: "error" })
                );
            },
        }),

        updateAgenda: builder.mutation<unknown, UpdateAgendaRequest>({
            query: ({ id, ...data }) => ({
                url: `/agendas/${id}`,
                method: Methods.Put,
                data,
            }),
            async onQueryStarted(_, { queryFulfilled: qf }) {
                qf.then(() => Notify(AGENDA_UPDATED_MSG)).catch((err: QueryFulfilledError) =>
                    Notify(err.error?.message, { status: "error" })
                );
            },
        }),

        deleteAgenda: builder.query<AgendaDetails, string>({
            query: (id) => ({
                url: `/agendas/${id}`,
                method: Methods.Delete,
            }),
        }),
    }),
});

export function updateCurrentMeetingAgendaPrayerItems(updatedPrayerItem: Prayer, dispatch) {
    dispatch(
        agendaApi.util.updateQueryData("getAgendas", { meetingId: 120 }, (draft) => {
            const targetAgenda = draft?.entities[0];
            if (!targetAgenda) return;
            const targetIndex = targetAgenda.prayerItems.findIndex(
                ({ prayer }) => prayer.id === updatedPrayerItem.id
            );
            if (targetIndex !== -1) {
                targetAgenda.agendaItems[targetIndex] = {
                    ...targetAgenda.agendaItems[targetIndex],
                    ...updatedPrayerItem,
                };
                draft.entities[0] = targetAgenda;
            }
        })
    );
}

export interface AgendaParams {
    meetingTitle?: string;
    meetingId?: number;
    meetingDate?: number;
    meetingUnit?: Unit;
    departmentId?: string;
    facultyId?: string;
    limit?: number;
    offset?: number;
}

export interface AgendaItem {
    position: number;
    documentUrl?: string;
    id: number;
    title: string;
}
export interface PrayerItem {
    position: number;
    prayer: Prayer;
}

export interface AgendaDetails {
    agendaItems: AgendaItem[];
    prayerItems: PrayerItem[];
    createdAt: string;
    id: number;
    meeting: Meeting;
    title: string;
    updatedAt: string;
}

export interface NewAgendaRequest {
    title: string;
    meetingId: string;
    agendaItem: Array<{ position: string; id: string }>;
}

export interface UpdateAgendaRequest extends Partial<NewAgendaRequest> {
    id: string;
}

export const {
    useGetAgendaQuery,
    useGetAgendasQuery,
    useCreateAgendaMutation,
    useUpdateAgendaMutation,
} = agendaApi;
