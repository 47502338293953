import { Button } from "@flexisaf/flexibull2";

import { Modal, Icon } from "@/features/ui";
import { DocumentPreviewer } from "@/features/documents";
import { theme } from "@/style";
import { FileTypes } from "@/utils/enums";
import { getFileDetailsFromUrl } from "@/utils/string-utils";

type DocumentPreviewerModalProps = {
    isOpen: boolean;
    onClose: () => void;
    url: string;
};

export const DocumentPreviewerModal = (props: DocumentPreviewerModalProps) => {
    const { isOpen, onClose, url } = props;

    const { ext } = getFileDetailsFromUrl(url);

    function handleExtType(ext: string): FileTypes {
        if (ext === "pdf") return FileTypes.Pdf;
        return FileTypes.Image;
    }

    const extType = handleExtType(ext);

    return (
        <Modal
            isOpen={isOpen}
            topSpace={4}
            style={{ width: "max-content" }}
            onClose={onClose}
            bodyStyle={{ padding: "8px" }}
            autoclose={false}
        >
            <DocumentPreviewer
                header={
                    <Button
                        color={theme.PrimaryColor}
                        plain
                        onClick={onClose}
                        style={{
                            marginRight: "auto",
                            display: "inline-flex",
                            alignItems: "center",
                            paddingLeft: 0,
                        }}
                    >
                        <Icon
                            icon="arrow-left-plain"
                            color={theme.PrimaryColor}
                            size={theme.FontSizes.sm}
                        />
                        Back
                    </Button>
                }
                ext={extType}
                url={url}
            />
        </Modal>
    );
};
