import { useController } from "react-hook-form";

type HiddenInputProps = {
    name: string;
    value: any;
};

export function HiddenInput(props: HiddenInputProps) {
    const { name, value } = props;
    const { field } = useController({ name, defaultValue: value });

    return <input name={field.name} type={"hidden"} value={field.value} />;
}
