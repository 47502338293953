import { useState, useEffect, useCallback } from "react";
import { intervalToDuration } from "date-fns";
import { useGetMeetingDetailsQuery, useGetMeetingDurationQuery } from "./meeting.api";
import { MeetingStatus } from "@/utils/enums";

type IntervalFunctions = {
    start: () => void;
    stop: () => void;
    elapsedTime: number;
    running: boolean;
    isPaused: boolean;
};

export const useOngoing = (meetId: string): IntervalFunctions => {
    const [running, setRunning] = useState(false);
    const [isPaused, setIsPaused] = useState(false);
    const [elapsedTime, setElapsedTime] = useState(0);

    const { data: meetingData } = useGetMeetingDetailsQuery(meetId);
    const { data: durationData } = useGetMeetingDurationQuery(meetId);

    const calculateElapsedTime = useCallback(() => {
        if (durationData !== undefined) {
            setElapsedTime(durationData);
        }
    }, [durationData]);

    useEffect(() => {
        if (meetingData) {
            if (meetingData.status === MeetingStatus.Active) {
                setRunning(true);
                setIsPaused(false);
            } else if (meetingData.status === MeetingStatus.Paused) {
                setRunning(false);
                setIsPaused(true);
            }
            calculateElapsedTime();
        }
    }, [meetingData, calculateElapsedTime]);

    useEffect(() => {
        let intervalId: NodeJS.Timeout | null = null;

        if (running && !isPaused) {
            intervalId = setInterval(() => {
                setElapsedTime((prevElapsedTime) => prevElapsedTime + 1);
            }, 1000);
        }

        return () => {
            if (intervalId !== null) {
                clearInterval(intervalId);
            }
        };
    }, [running, isPaused]);

    const start = () => {
        if (!running) {
            setRunning(true);
        }
    };

    const stop = () => {
        if (running) {
            setRunning(false);
        }
    };

    return {
        start,
        stop,
        elapsedTime,
        running,
        isPaused,
    };
};

type TimeInterval = string;

function generateTimeInterval(elapsedTime: number): TimeInterval {
    if (elapsedTime < 0) elapsedTime = 0;

    try {
        const duration = intervalToDuration({ start: 0, end: elapsedTime * 1000 });
        const hours = String(duration.hours ?? 0).padStart(2, "0");
        const minutes = String(duration.minutes ?? 0).padStart(2, "0");
        const seconds = String(duration.seconds ?? 0).padStart(2, "0");

        return `${hours}:${minutes}:${seconds}`;
    } catch (error) {
        // eslint-disable-next-line
        console.error("Error formatting time interval:", error);
        return "00:00:00";
    }
}

function useTimeInterval(elapsedTime: number, running: boolean, isPaused: boolean): TimeInterval {
    const [timeInterval, setTimeInterval] = useState<TimeInterval>(
        generateTimeInterval(elapsedTime)
    );

    useEffect(() => {
        if (running && !isPaused) {
            const interval = setInterval(() => {
                setTimeInterval((prevTimeInterval) => {
                    const newElapsedTime = elapsedTime + 1;
                    return generateTimeInterval(newElapsedTime);
                });
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [elapsedTime, running, isPaused]);

    useEffect(() => {
        setTimeInterval(generateTimeInterval(elapsedTime));
    }, [elapsedTime]);

    return timeInterval;
}

export default useTimeInterval;
