export function paginationAdapter<F extends { limit?: number; offset?: number }>(
    queryState: F,
    setQueryField: (name: keyof F, value: any) => void,
    updateQueryState: (queryState: F) => void
) {
    const { offset = 0 } = queryState;
    const page = offset + 1;

    const setPage = (p: number) => {
        setQueryField("offset", p - 1);
    };
    const setLimit = (lim: number) => setQueryField("limit", lim);

    return { page, setPage, setLimit };
}

export type PaginationAdapterReturn = ReturnType<typeof paginationAdapter>;
