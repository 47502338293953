import { useCallback } from "react";
import { useController } from "react-hook-form";

import { Option } from "@/types/common";

import { StyledSelect } from "./select.styles";
import { BaseFormElementProps } from "../types";

/** This is better if integrated with the select component from the base folder */

type SelectProps = BaseFormElementProps & {
    options: any[];
    pale?: boolean;
    rounded?: boolean;
    color?: string;
    value?: Option | string | number;
    isMulti?: boolean;
    valueAndLabel?: boolean;
    onChange?: Function;
    [key: string]: any;
};

const getSelectValue = (options, value: number | string | Record<any, any>) => {
    if (Array.isArray(value)) {
        return value.map((currentValue) => getSelectValue(options, currentValue));
    }

    if (typeof value === "number" && !value) return "";

    if (typeof value === "object") {
        return value;
    }

    if (typeof value === "string") {
        return options.find((opt) => opt.value === value);
    }
};

export function Select(props: SelectProps) {
    const {
        name,
        label,
        rules = {},
        options,
        valueAndLabel,
        defaultValue,
        error,
        isMulti,
        ...restProps
    } = props;
    const { field, fieldState } = useController({ name, defaultValue, rules });

    const handleChange = useCallback(
        (selected: Option | Option[]) => {
            if (!selected) return null;
            if (valueAndLabel) return field.onChange(selected);
            if (isMulti) {
                const selectedValues = selected.map(({ value }) => value);
                return field.onChange(selectedValues);
            }
            if (!Array.isArray(selected)) field.onChange(selected.value);
        },
        [isMulti, field, valueAndLabel]
    );

    return (
        <StyledSelect
            name={name}
            label={label}
            value={getSelectValue(options, field.value)}
            onChange={handleChange}
            error={error || fieldState.error?.message}
            options={options}
            {...restProps}
        />
    );
}
