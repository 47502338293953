import React from "react";
import styled, { css } from "styled-components";

type IconboxProps = {
    size?: number;
    hover?: boolean;
    radius?: boolean;
    round?: boolean;
    bg?: string;
    style?: React.CSSProperties;
};

export const Iconbox = styled.div<IconboxProps>`
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
    display: flex;
    align-items: center;
    justify-content: center;

    ${(props) =>
        props.hover &&
        css`
            ${(props) => props.theme.hover};
        `}
    ${(props) =>
        props.bg &&
        css`
            background: ${(props) => props.theme.bg};
        `}
    ${(props) =>
        props.radius &&
        css`
            border-radius: ${(props) => props.theme.PrimaryRadius};
        `}
    ${(props) =>
        props.round &&
        css`
            border-radius: 50%;
        `}
`;
