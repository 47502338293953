import { UserRole } from "@/features/authorization";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";

type RoleSwitcher = {
    targetRole?: UserRole;
    isSwitching?: boolean;
};

export type GlobalState = {
    intervalTime: number;
    running: boolean;
    lastTime: number;
    roleSwitcher: RoleSwitcher;
};

const initialState = <GlobalState>{
    intervalTime: 0,
    running: false,
    lastTime: 0,
    roleSwitcher: {},
};

export const globalSlice = createSlice({
    name: "global",
    initialState,
    reducers: {
        resetGlobalState(state) {
            return initialState;
        },
        updateIntervalTime(state, action: PayloadAction<number>) {
            if (state.running) {
                const currentTime = performance.now();
                const deltaTime = currentTime - state.lastTime;
                state.intervalTime += deltaTime;
                state.lastTime = currentTime;
            }
        },
        setRunningStatus(state, action: PayloadAction<boolean>) {
            state.running = action.payload;
            if (action.payload) {
                state.lastTime = performance.now();
            }
        },
        resetIntervalState(state) {
            state.intervalTime = 0;
            state.running = false;
            state.lastTime = 0;
        },
        startRoleSwitching(state, action: PayloadAction<UserRole>) {
            state.roleSwitcher = {
                isSwitching: true,
                targetRole: action.payload,
            };
        },
        endRoleSwitching(state) {
            state.roleSwitcher = {
                isSwitching: false,
                targetRole: undefined,
            };
        },
    },
});

export const { resetGlobalState, updateIntervalTime, setRunningStatus, resetIntervalState } =
    globalSlice.actions;

export const globalStateSelector = (state: { global: GlobalState }) => state.global;

export const useGlobalSlice = () => {
    const slice = useSelector(globalStateSelector);
    const dispatch = useDispatch();

    const resetGlobalState = () => dispatch(globalSlice.actions.resetGlobalState());

    const updateIntervalTime = (t: number) => dispatch(globalSlice.actions.updateIntervalTime(t));

    const setRunningStatus = (runningStatus: boolean) =>
        dispatch(globalSlice.actions.setRunningStatus(runningStatus));

    const resetIntervalState = () => dispatch(globalSlice.actions.resetIntervalState());

    const startRoleSwitching = (targetRole: UserRole) =>
        dispatch(globalSlice.actions.startRoleSwitching(targetRole));

    const endRoleSwitching = () => dispatch(globalSlice.actions.endRoleSwitching());

    return {
        ...slice,
        actions: {
            resetGlobalState,
            updateIntervalTime,
            setRunningStatus,
            resetIntervalState,
            startRoleSwitching,
            endRoleSwitching,
        },
    };
};
