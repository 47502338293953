import { useCallback, useRef } from "react";
import { Box, Spacer, RadioButton } from "@flexisaf/flexibull2";

import { Icon } from "@/features/ui";
import { useClickAway, useToggle } from "react-use";
import { useGlobalSlice } from "@/app/global-slice";
import { useAuthSlice } from "@/features/auth";
import { titleCase } from "@/utils/string-utils";

import { Role, UserRole } from "../authorization.api";
import { theme } from "@/style";

export function RoleSwitcher() {
    const [isOpen, toggleOpen] = useToggle(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const { user } = useAuthSlice();
    const {
        actions: { startRoleSwitching },
    } = useGlobalSlice();

    const handleRoleChange = useCallback((role: UserRole) => {
        startRoleSwitching(role);
        toggleOpen();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useClickAway(containerRef, () => isOpen && toggleOpen(false));
    return (
        <div
            ref={containerRef}
            style={{
                marginBottom: "16px",
                background: theme.PrimaryColor,
                borderRadius: "8px",
            }}
        >
            <Box
                onClick={toggleOpen}
                display="flex"
                style={{
                    padding: "8px 15px",
                    width: "100%",
                    alignItems: "center",

                    justifyContent: "space-between",
                }}
            >
                <Box display="flex">
                    <Icon icon="toggle-off-circle" color="#fff" />
                    <Spacer space={10} direction="horizontal" />
                    <span style={{ color: "#fff" }}> Change Role</span>
                </Box>
                <Icon icon={isOpen ? "arrow-up-2" : "arrow-down-1"} color="#fff" />
            </Box>

            <Box
                style={{
                    transition: "max-height .4s ease",
                    maxHeight: isOpen ? "400px" : "0px",
                    overflow: "hidden",
                }}
            >
                {user.roles.map((roleItem) => {
                    const isActive = user.currentRole.role.id === roleItem.role.id;
                    return (
                        <RoleItem
                            key={roleItem.role.id}
                            role={roleItem.role}
                            isActive={isActive}
                            onChange={() => handleRoleChange(roleItem)}
                        />
                    );
                })}
            </Box>
        </div>
    );
}

/* ----------------------------------------------- */
type RoleSwitcherItemProps = {
    role: any;
    isActive: boolean;
    onChange: (role: Role) => void;
};

function RoleItem(props: RoleSwitcherItemProps) {
    const { role, onChange, isActive } = props;
    return (
        <Box
            display="flex"
            style={{
                padding: "0px 0px 0px 41px ",
                alignItems: "center",
                overflow: "hidden",
                justifyContent: "space-between",
                color: "#fff",
            }}
            key={role.id}
            onClick={() => onChange(role)}
        >
            {titleCase(role.name)}
            <RadioButton size="16px" checked={isActive} color="#fff" />
        </Box>
    );
}

/* ----------------------------------------------- */
