import { Button } from "@flexisaf/flexibull2";
import { useNavigate } from "react-router";

import { theme } from "@/style";

import { Icon } from "./icon";

type BackButtonProps = {
    content?: React.ReactElement;
    onClick?: () => void;
    color?: string;
};

export function BackButton(props: BackButtonProps) {
    const navigate = useNavigate();

    const { content = "Back", onClick = () => navigate(-1), color = theme.PrimaryColor } = props;

    return (
        <Button
            color={color}
            plain
            onClick={onClick}
            style={{
                marginRight: "auto",
                display: "inline-flex",
                alignItems: "center",
                paddingLeft: 0,
            }}
        >
            <Icon icon="arrow-left-plain" color={color} />
            {content}
        </Button>
    );
}
