import { Box, Spacer, Button } from "@flexisaf/flexibull2";
import { Text } from "@flexisaf/flexibull2/build/typo";
import { useParams } from "react-router-dom";
import moment from "moment";
import { appPaths } from "@/app/app-paths";

import theme from "@/style/theme";

import { ArrowSquareRight, MonitorRecorder } from "iconsax-react";
import { TabButton, TabContainer, TabPanel } from "@/features/ui/tab.component";
import {
    MeetingWrapper,
    ModTitle,
    SpaceBox,
    StyledBox,
    StyledSpacer,
    SubTitle,
    TextCover,
} from "@/features/ui/meeting.style";
import { BackButton, PageLayout } from "@/features/ui";
import { Title } from "@/features/ui/menu";
import { replaceNewLineWithBr } from "@/utils/string-utils";
import { useQueryStateSync } from "@/lib/use-query-state-sync";

import { useGetMeetingDetailsQuery, useUploadMinuteMutation } from "./meeting.api";
import { AgendaTab } from "./meeting-tabs";
import { useAuth, useAuthUserNavigate } from "../auth";
import DocumentViewerButton from "./document-viewer-button";
import { useAttendance } from "./attendance.controller";
import { DocumentUploader, useUploadDocument } from "../documents";
import { useState } from "react";

export const PreviousMeeting = () => {
    const { id: meetId } = useParams();
    const { navigateTo } = useAuthUserNavigate();
    const meetNumber = Number(meetId);
    const { variables } = useAttendance({ meetId: meetNumber });
    const { attendeeList } = variables;
    const { data } = useGetMeetingDetailsQuery(meetId);

    const dateTime = moment(data?.datetime);
    const date = dateTime.format("YYYY-MM-DD");
    const time = dateTime.format("HH:mm:ss");
    const { queryState, setQueryField } = useQueryStateSync<{ activeTab: number }>({
        activeTab: 0,
    });

    const handleTabClick = (index: number) => {
        setQueryField("activeTab", index);
    };
    const currentlyActiveTab = queryState?.activeTab ? +queryState.activeTab : 0;

    const { upload, isUploading } = useUploadDocument();
    const [uploadMinuteMutation, { isLoading }] = useUploadMinuteMutation();
    const [minuteFile, setMinuteFile] = useState<File>();
    const { user } = useAuth();
    const handleMinuteUpload = async () => {
        if (minuteFile && meetId) {
            const minuteUrl = await upload({
                file: minuteFile,
                fileName: minuteFile?.name,
            });
            uploadMinuteMutation({
                meetingId: meetId,
                organization: user.organisation,
                attachmentUrl: minuteUrl,
            });
        }
    };

    return (
        <PageLayout>
            <MeetingWrapper>
                <BackButton onClick={() => navigateTo(appPaths.dashboard)} />
                <Spacer space={10} />
                <Box
                    display="flex"
                    style={{ justifyContent: "space-between", alignItems: "flex-end" }}
                >
                    <Box>
                        <Title
                            style={{
                                fontSize: "16px",
                                fontWeight: "bold",
                            }}
                        >
                            {data?.title}
                        </Title>
                        <Spacer space="5px" />
                        <Text color={theme.PrimaryGreyMid} size="12px" block bold>
                            Duration
                        </Text>
                        <Spacer space="5px" />
                        <Text size="16px" color={theme.PrimaryGreyMid} bold>
                            <MonitorRecorder size="15" />
                            {new Date(data?.endTime).toLocaleTimeString("en-US", {
                                hour12: false,
                            })}
                        </Text>
                    </Box>
                    <Box display="flex"></Box>
                </Box>
                <Spacer space={20} />
                <TabContainer>
                    <TabButton
                        style={{
                            background: currentlyActiveTab === 0 ? "#2D3142" : "#E9EAED",
                        }}
                        active={currentlyActiveTab === 0}
                        onClick={() => handleTabClick(0)}
                    >
                        Meeting Room
                    </TabButton>
                    <TabButton
                        style={{
                            background: currentlyActiveTab === 1 ? "#2D3142" : "#E9EAED",
                        }}
                        active={currentlyActiveTab === 1}
                        onClick={() => handleTabClick(1)}
                    >
                        Attendance
                    </TabButton>
                    <TabButton
                        style={{
                            background: currentlyActiveTab === 2 ? "#2D3142" : "#E9EAED",
                        }}
                        active={currentlyActiveTab === 2}
                        onClick={() => handleTabClick(2)}
                    >
                        Minutes
                    </TabButton>
                    <TabButton
                        style={{
                            background: currentlyActiveTab === 3 ? "#2D3142" : "#E9EAED",
                        }}
                        active={currentlyActiveTab === 3}
                        onClick={() => handleTabClick(3)}
                    >
                        Agenda
                    </TabButton>
                </TabContainer>

                <TabPanel active={currentlyActiveTab === 0}>
                    <Spacer space={40} />
                    <StyledBox>
                        <ModTitle>Title</ModTitle>

                        <StyledSpacer />
                        <Box>
                            <SubTitle>{data?.title}</SubTitle>
                        </Box>
                    </StyledBox>
                    <StyledSpacer space={40} />
                    <StyledBox>
                        <ModTitle>Description</ModTitle>
                        <StyledSpacer />
                        <Box>
                            <SubTitle>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: replaceNewLineWithBr(data?.description || ""),
                                    }}
                                />
                            </SubTitle>
                        </Box>
                    </StyledBox>
                    <StyledSpacer space={40} />
                    <StyledBox>
                        <ModTitle> Pre-Agenda</ModTitle>
                        <StyledSpacer />
                        <Box>
                            {data?.preAgenda ? (
                                <DocumentViewerButton documentUrl={data.preAgenda} />
                            ) : (
                                <SubTitle>No pre-agenda document available</SubTitle>
                            )}
                        </Box>
                    </StyledBox>
                    <StyledSpacer space={40} />
                    <StyledBox>
                        <ModTitle> Date & Time</ModTitle>
                        <StyledSpacer />
                        <Box>
                            <SubTitle>
                                {date} - {time}
                            </SubTitle>
                        </Box>
                    </StyledBox>
                    <StyledSpacer space={40} />
                    <StyledBox>
                        <ModTitle> Venue</ModTitle>
                        <StyledSpacer />
                        <Box>
                            <SubTitle>{data?.venue}</SubTitle>
                        </Box>
                    </StyledBox>
                    <StyledSpacer space={40} />
                    <StyledBox>
                        <ModTitle> Participants</ModTitle>
                        <StyledSpacer />
                        <Box>
                            <SubTitle>{data?.participant}</SubTitle>
                        </Box>
                    </StyledBox>
                    <StyledSpacer space={40} />
                    <StyledBox>
                        <ModTitle> Other Attachments</ModTitle>
                        <StyledSpacer />
                        <Box>
                            {data?.otherAttachment ? (
                                <DocumentViewerButton documentUrl={data.otherAttachment} />
                            ) : (
                                <SubTitle>No other document available</SubTitle>
                            )}
                        </Box>
                    </StyledBox>
                    <StyledSpacer space={40} />
                </TabPanel>
                <TabPanel active={currentlyActiveTab === 1}>
                    <Spacer space={30} />
                    <Title>Meeting Attendance</Title>
                    <StyledSpacer space={30} />
                    <SpaceBox />
                    <StyledSpacer space={10} />
                    <Text size="12px" block>
                        Automated Entries
                    </Text>

                    <TextCover>
                        <div>
                            <Text size="12px" block>
                                Total No. of attendees
                            </Text>

                            <Text block size="16px" bold>
                                {attendeeList?.length}
                            </Text>
                        </div>
                        <div>
                            <Text size="12px" block>
                                Check-ins
                            </Text>
                            <Text block size="16px" bold>
                                0
                            </Text>
                        </div>
                        <div>
                            <ArrowSquareRight size="42" color="#2C2A2A" variant="Bold" />
                        </div>
                    </TextCover>
                    <Spacer space={10} />
                    <SpaceBox />
                </TabPanel>
                <TabPanel active={currentlyActiveTab === 2}>
                    <Spacer space={30} />
                    <Title>Meeting Minutes</Title>
                    <StyledSpacer space={30} />
                    <SpaceBox />

                    <Box
                        style={{
                            display: "flex",
                            flex: "1",
                        }}
                    ></Box>
                    <Spacer space="34px" />
                    <StyledBox>
                        <ModTitle>Attachement</ModTitle>
                        <StyledSpacer />
                        <Box>
                            {!data?.minute ? (
                                <>
                                    <DocumentUploader onChange={(file) => setMinuteFile(file[0])}>
                                        <div>
                                            <Text color={theme.PrimaryBlue}>Attach File</Text>
                                        </div>
                                    </DocumentUploader>
                                    <Button
                                        progress={isUploading || isLoading}
                                        style={{ width: "100%" }}
                                        onClick={handleMinuteUpload}
                                        disabled={!minuteFile}
                                    >
                                        Attached
                                    </Button>
                                </>
                            ) : (
                                <DocumentViewerButton documentUrl={data?.minute?.attachmentUrl} />
                            )}
                        </Box>
                    </StyledBox>
                    <Spacer space="89px" />
                </TabPanel>
                <TabPanel active={currentlyActiveTab === 3}>
                    <AgendaTab meetingId={data?.id} />
                </TabPanel>
            </MeetingWrapper>
        </PageLayout>
    );
};
