import { useState, useEffect } from "react";
import { darken, transparentize } from "polished";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Box, Spacer, Button, Grid } from "@flexisaf/flexibull2";
import { Text } from "@flexisaf/flexibull2/build/typo";

import theme from "@/style/theme";

import { useAuthUserNavigate } from "@/features/auth/use-auth-user-navigate";
import { appPaths } from "@/app";
import { Modal, Icon, HookForm as Form, EmptySpace } from "@/features/ui";
import { attachSuffix } from "../meetings/useSuffix";
import { useUpdatePrayerMutation } from "./prayers/prayer-api";
import { PrayerStatuses } from "@/utils/enums";

import { useCreateCommentMutation } from "./comments/comment-api";
import { hasItems } from "@/utils/object-utils";
import { snakeToTitleCase } from "@/utils/string-utils";
import { useResponsiveValue } from "@/lib/use-responsive-value";
import styled from "styled-components";

const PrayerBoxWrapper = styled.div`
    cursor: pointer;
    :hover {
        background: ${transparentize(0.9, darken(0.2, theme.PrimaryColor))};
    }
`;

const Container = styled(Box)`
    justify-content: space-between;
    align-items: center;
    width: 100%;
    overflow-x: auto;
    ::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
`;

export const PrayerBox = (props: any) => {
    const { prayers } = props;
    const { navigateTo } = useAuthUserNavigate();
    const meetingNumberWidth = useResponsiveValue({ sm: "120px", md: "160px" });

    return (
        <Box style={{ borderTop: "1px solid #d9d9d9", padding: "20px 10px 0" }}>
            {hasItems(prayers) && prayers.length > 0 ? (
                prayers.map((prayer) => {
                    return (
                        <Box
                            key={prayer.id}
                            onClick={() => navigateTo(appPaths.getPrayerById(prayer.id))}
                        >
                            <PrayerBoxWrapper
                                style={{
                                    display: "flex",
                                    borderBottom: "1px solid #d9d9d9",
                                    justifyContent: "space-between",
                                    alignItems: "flex-end",
                                    padding: "10px",
                                }}
                            >
                                <Box>
                                    <Box>
                                        <Text>{prayer.title}</Text>
                                    </Box>
                                    <Spacer space={4} />
                                    <Box>
                                        <Text> {prayer.departmentId}</Text>
                                    </Box>
                                    <Spacer space={4} />

                                    <Box>
                                        <Text size="11px" color={theme.PrimaryGreyMid}>
                                            {snakeToTitleCase(prayer.category)}
                                        </Text>
                                    </Box>
                                </Box>
                                <Box>
                                    {/* @unresolved: For prayer that has issues */}
                                    {/* <Box>
                                    <Icon icon="warning-2" color={theme.PrimaryRed} />
                                </Box> */}

                                    <Text
                                        bold
                                        block
                                        color={theme.PrimaryGreyMid}
                                        style={{
                                            width: meetingNumberWidth,
                                            textAlign: "right",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                        }}
                                    >
                                        {attachSuffix(prayer?.meeting?.number)} Senate Meeting
                                    </Text>
                                </Box>
                            </PrayerBoxWrapper>
                        </Box>
                    );
                })
            ) : (
                <div style={{ margin: "10px 0" }}>
                    <EmptySpace>
                        <Icon icon="book-saved" size="32" color={theme.PrimaryGrey} />
                        <Text
                            style={{
                                color: theme.PrimaryGreyDark,
                            }}
                        >
                            No Prayers
                        </Text>
                    </EmptySpace>
                </div>
            )}
        </Box>
    );
};

type PrayerRowProps = {
    title?: string;
    content?: string | React.ReactNode;
    file?: boolean;
    font?: number;
};

export const PrayerRow = (props: PrayerRowProps): React.ReactElement => {
    const { title, content, file, font = 10 } = props;

    return (
        <Container display="flex">
            <Box style={{ position: "relative" }}>
                {title && (
                    <>
                        <Text bold size={font} style={{ position: "sticky", left: 0 }}>
                            {title}
                        </Text>
                    </>
                )}

                {content && (
                    <>
                        {typeof content === "string" ? (
                            <Box>
                                <div dangerouslySetInnerHTML={{ __html: content }} />
                            </Box>
                        ) : (
                            <Box>{content}</Box>
                        )}
                    </>
                )}
            </Box>
            {file && (
                <Box>
                    <Button color={theme.PrimaryGreyLight}>View</Button>
                </Box>
            )}
        </Container>
    );
};

type PrayerModalProps = {
    prayerid: string | number | undefined;
    isOpen: boolean;
    onClose: () => void;
    userId?: string;
    onRequestSuccessful?: () => void;
};

export const RaisePrayerModal = (props: PrayerModalProps) => {
    const [editPrayerMutation, { isLoading: isPrayerUpdating, isSuccess: prayerUpdated }] =
        useUpdatePrayerMutation();
    const { isOpen, onClose, prayerid, onRequestSuccessful } = props;

    useEffect(() => {
        if (prayerUpdated) {
            if (onRequestSuccessful) {
                onRequestSuccessful();
            }
            onClose();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prayerUpdated]);

    return (
        <Modal title="Raise Prayer" centeredTitle isOpen={isOpen} onClose={onClose}>
            <Box style={{ textAlign: "center" }}>
                <Box>Are you sure you want to raise this prayer?</Box>
                <Spacer space={24} />
                <Grid default="1fr 1fr">
                    <Button pale onClick={onClose}>
                        {" "}
                        Cancel
                    </Button>

                    <Button
                        progress={isPrayerUpdating}
                        onClick={() =>
                            editPrayerMutation({
                                id: prayerid,
                                status: PrayerStatuses.Raised,
                            })
                        }
                    >
                        {" "}
                        Yes
                    </Button>
                </Grid>
            </Box>
        </Modal>
    );
};

export const SubmitPrayerModal = (props: PrayerModalProps) => {
    const [editPrayerMutation, { isLoading: isPrayerUpdating, isSuccess: prayerUpdated }] =
        useUpdatePrayerMutation();
    const { isOpen, onClose, prayerid, onRequestSuccessful } = props;

    useEffect(() => {
        if (prayerUpdated) {
            if (onRequestSuccessful) {
                onRequestSuccessful();
            }
            onClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prayerUpdated]);

    return (
        <Modal title="Submit Prayer" centeredTitle isOpen={isOpen} onClose={onClose}>
            <Box style={{ textAlign: "center" }}>
                <Box>Are you sure you want to submit this prayer?</Box>
                <Spacer space={24} />
                <Grid default="1fr 1fr">
                    <Button pale onClick={onClose}>
                        {" "}
                        Cancel
                    </Button>

                    <Button
                        progress={isPrayerUpdating}
                        onClick={() =>
                            editPrayerMutation({
                                id: prayerid,
                                status: PrayerStatuses.Submitted,
                            })
                        }
                    >
                        Yes
                    </Button>
                </Grid>
            </Box>
        </Modal>
    );
};

export const WriteCommentModal = (props: PrayerModalProps) => {
    const [createCommentMutation, { isLoading: isCommentCreating, isSuccess: isCommentCreated }] =
        useCreateCommentMutation();
    const { isOpen, onClose, prayerid, userId, onRequestSuccessful } = props;

    useEffect(() => {
        if (isCommentCreated) {
            if (onRequestSuccessful) {
                onRequestSuccessful();
            }
            onClose();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCommentCreated]);

    const validation = yup.object().shape({
        content: yup.string().required("Comment content is required"),
    });

    const form = useForm({
        resolver: yupResolver(validation),
    });

    function onSubmit(values) {
        createCommentMutation({
            ...values,
            prayerId: prayerid,
            umsId: userId,
        });
    }

    return (
        <Modal centeredTitle isOpen={isOpen} onClose={onClose} topSpace={30}>
            <Box style={{ textAlign: "center" }}>
                <Form form={form}>
                    <Form.TextAreaInput
                        name="content"
                        label="Write commment"
                        form={form}
                        placeholder="Write here"
                        width="100%"
                        height="100px"
                    />
                    <Spacer space={10} />
                    <Grid default="1fr 1fr">
                        <Button pale onClick={onClose}>
                            Cancel
                        </Button>

                        <Button progress={isCommentCreating} onClick={form.handleSubmit(onSubmit)}>
                            Post Comment
                        </Button>
                    </Grid>
                </Form>
            </Box>
        </Modal>
    );
};

export const RecommendPrayerModal = (props: PrayerModalProps) => {
    const [editPrayerMutation, { isLoading: isPrayerUpdating, isSuccess: prayerUpdated }] =
        useUpdatePrayerMutation();

    const [createCommentMutation, { isLoading: isCommentCreating }] = useCreateCommentMutation();

    const validation = yup.object().shape({
        content: yup.string(),
    });

    const { isOpen, onClose, prayerid, userId, onRequestSuccessful } = props;

    useEffect(() => {
        if (prayerUpdated) {
            if (onRequestSuccessful) {
                onRequestSuccessful();
            }
            onClose();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prayerUpdated]);

    const form = useForm({
        resolver: yupResolver(validation),
    });

    async function onSubmit(values) {
        if (values.content) {
            // Check if additional note is provided
            await createCommentMutation({
                ...values,
                prayerId: prayerid,
                umsId: userId,
            });
        }

        await editPrayerMutation({
            id: prayerid,
            status: PrayerStatuses.Recommended,
        });

        onClose();
    }
    return (
        <Modal title="Recommend Prayer" centeredTitle isOpen={isOpen} onClose={onClose}>
            <Box style={{ textAlign: "center" }}>
                <Box> Confirm your decision to recommend this prayer from the senate meeting</Box>
                <Spacer space={36} />
                <Form form={form}>
                    <Form.TextAreaInput
                        name="content"
                        label="Aditional note (Optional)"
                        form={form}
                        placeholder="Write here..."
                        width="100%"
                        height="100px"
                    />

                    <Spacer space={24} />
                    <Grid default="1fr 1fr">
                        <Button pale onClick={onClose}>
                            {" "}
                            Cancel
                        </Button>

                        <Button
                            progress={isPrayerUpdating || isCommentCreating}
                            onClick={form.handleSubmit(onSubmit)}
                        >
                            {" "}
                            Recommend
                        </Button>
                    </Grid>
                </Form>
            </Box>
        </Modal>
    );
};

type ApprovalType = "conditional" | "unconditional";

export const ApprovePrayerModal = (props: PrayerModalProps) => {
    const [editPrayerMutation, { isLoading: isPrayerUpdating, isSuccess: prayerUpdated }] =
        useUpdatePrayerMutation();

    const [createCommentMutation, { isLoading: isCommentCreating }] = useCreateCommentMutation();

    const validation = yup.object().shape({
        content: yup.string().label("Reason").required(),
    });

    const [approvalType, setApprovalType] = useState<ApprovalType | null>(null);

    const { isOpen, onClose, prayerid, userId, onRequestSuccessful } = props;

    useEffect(() => {
        if (prayerUpdated) {
            if (onRequestSuccessful) {
                onRequestSuccessful();
            }
            onClose();
            handleClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prayerUpdated]);

    const form = useForm({
        resolver: yupResolver(validation),
    });

    async function onSubmit(values) {
        if (values?.content) {
            // Check if additional note is provided
            await createCommentMutation({
                ...values,
                prayerId: prayerid,
                umsId: userId,
            });
        }

        await editPrayerMutation({
            id: prayerid,
            status:
                approvalType === "conditional"
                    ? PrayerStatuses.ConditionalApproval
                    : PrayerStatuses.Approved,
        });

        onClose();
    }

    function handleApproveClick(type) {
        setApprovalType(type);
    }

    function handleClose() {
        setApprovalType(null);
        onClose();
    }

    return (
        <Modal title="Approve Prayer" centeredTitle isOpen={isOpen} onClose={handleClose}>
            <Box style={{ textAlign: "center" }}>
                {approvalType === "conditional" ? (
                    <>
                        <Box>Please state the reason for which this prayer will be approved</Box>
                        <Spacer space={36} />
                        <Form form={form}>
                            <Form.TextAreaInput
                                name="content"
                                label="Additional note (Optional)"
                                form={form}
                                placeholder="Write here..."
                                width="100%"
                                height="100px"
                            />

                            <Spacer space={24} />
                            <Grid default="1fr 1fr">
                                <Button pale onClick={handleClose}>
                                    Cancel
                                </Button>

                                <Button
                                    progress={isPrayerUpdating || isCommentCreating}
                                    onClick={form.handleSubmit(onSubmit)}
                                >
                                    Approve
                                </Button>
                            </Grid>
                        </Form>
                    </>
                ) : (
                    <>
                        <Box>How do you intend to approve this prayer?</Box>
                        <Spacer space={24} />
                        <Grid default="1fr 1fr">
                            <Button onClick={onSubmit} progress={isPrayerUpdating}>
                                {isPrayerUpdating ? "Approving" : "Unconditional"}
                            </Button>
                            <Button pale onClick={() => handleApproveClick("conditional")}>
                                Conditional
                            </Button>
                        </Grid>
                    </>
                )}
            </Box>
        </Modal>
    );
};

export const StepDownPrayerModal = (props: PrayerModalProps) => {
    const [editPrayerMutation, { isLoading: isPrayerUpdating, isSuccess: prayerUpdated }] =
        useUpdatePrayerMutation();

    const [createCommentMutation, { isLoading: isCommentCreating }] = useCreateCommentMutation();

    const validation = yup.object().shape({
        content: yup.string(),
    });

    const { isOpen, onClose, prayerid, userId, onRequestSuccessful } = props;

    useEffect(() => {
        if (prayerUpdated) {
            if (onRequestSuccessful) {
                onRequestSuccessful();
            }
            onClose();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prayerUpdated]);

    const form = useForm({
        resolver: yupResolver(validation),
    });

    async function onSubmit(values) {
        if (values.content) {
            // Check if additional note is provided
            await createCommentMutation({
                ...values,
                prayerId: prayerid,
                umsId: userId,
            });
        }

        await editPrayerMutation({
            id: prayerid,
            status: PrayerStatuses.SteppedDown,
        });

        onClose();
    }

    return (
        <Modal title="Step Down Prayer" centeredTitle isOpen={isOpen} onClose={onClose}>
            <Box style={{ textAlign: "center" }}>
                <Box> Confirm your decision to step down this prayer for the senate meeting</Box>
                <Spacer space={36} />
                <Form form={form}>
                    <Form.TextAreaInput
                        name="content"
                        label="Aditional note (Optional)"
                        form={form}
                        placeholder="Write here..."
                        width="100%"
                        height="100px"
                    />

                    <Spacer space={24} />
                    <Grid default="1fr 1fr">
                        <Button pale onClick={onClose}>
                            {" "}
                            Cancel
                        </Button>

                        <Button
                            progress={isPrayerUpdating || isCommentCreating}
                            onClick={form.handleSubmit(onSubmit)}
                        >
                            {" "}
                            Step Down
                        </Button>
                    </Grid>
                </Form>
            </Box>
        </Modal>
    );
};

export const RejectPrayerModal = (props: PrayerModalProps) => {
    const [editPrayerMutation, { isLoading: isPrayerUpdating, isSuccess: prayerUpdated }] =
        useUpdatePrayerMutation();

    const [createCommentMutation, { isLoading: isCommentCreating }] = useCreateCommentMutation();

    const validation = yup.object().shape({
        content: yup.string(),
    });

    const { isOpen, onClose, prayerid, userId, onRequestSuccessful } = props;

    useEffect(() => {
        if (prayerUpdated) {
            if (onRequestSuccessful) {
                onRequestSuccessful();
            }
            onClose();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prayerUpdated]);

    const form = useForm({
        resolver: yupResolver(validation),
    });

    async function onSubmit(values) {
        if (values.content) {
            // Check if additional note is provided
            await createCommentMutation({
                ...values,
                prayerId: prayerid,
                umsId: userId,
            });
        }

        await editPrayerMutation({
            id: prayerid,
            status: PrayerStatuses.Rejected,
        });

        onClose();
    }
    return (
        <Modal title="Reject Prayer" centeredTitle isOpen={isOpen} onClose={onClose}>
            <Box style={{ textAlign: "center" }}>
                <Box> Confirm your decision to reject this prayer for the senate meeting</Box>
                <Spacer space={36} />
                <Form form={form}>
                    <Form.TextAreaInput
                        name="content"
                        label="Aditional note (Optional)"
                        form={form}
                        placeholder="Write here..."
                        width="100%"
                        height="100px"
                    />

                    <Spacer space={24} />
                    <Grid default="1fr 1fr">
                        <Button pale onClick={onClose}>
                            {" "}
                            Cancel
                        </Button>

                        <Button
                            progress={isPrayerUpdating || isCommentCreating}
                            onClick={form.handleSubmit(onSubmit)}
                        >
                            {" "}
                            Reject
                        </Button>
                    </Grid>
                </Form>
            </Box>
        </Modal>
    );
};
