import { useEffect, useState } from "react";
import {
    useGetAttendanceQuery,
    useGetMeetingDetailsQuery,
    useAddAttendanceMutation,
} from "./meeting.api";
import { useQueryStateSync } from "@/lib/use-query-state-sync";
import { StaffParams, useGetStaffQuery } from "../users/users-api";
import { PaginationAdapterReturn, paginationAdapter } from "@/lib/pagination-adapter";
import { extractDateTime } from "@/utils/date-utils";
import { useAuth } from "../auth";
export type UseAttendanceProps = {
    meetId: number;
};

export const useAttendance = ({ meetId }: UseAttendanceProps) => {
    // Variables
    const { data: meetingDetailData } = useGetMeetingDetailsQuery(meetId);
    const { data: attendeeList } = useGetAttendanceQuery(meetId);
    const [open, setOpen] = useState(false);

    const [attendees, setAttendees] = useState([]);
    const { user } = useAuth();
    const queryStateSync = useQueryStateSync<StaffParams, PaginationAdapterReturn>(
        { limit: 10 },
        paginationAdapter
    );

    const { queryState, setQueryField } = queryStateSync;
    const { data: usersData, isLoading: usersLoading } = useGetStaffQuery(queryState);
    const [addAttendees, { isLoading: adding, isSuccess: added }] = useAddAttendanceMutation();

    useEffect(() => {
        if (user) {
            setQueryField("unit", user.currentRole.unit);
        }
    }, [setQueryField, user]);

    // Actions
    const actions = {
        searchUser: (value) => setQueryField("search-word", value),
        addAttendees: useAddAttendanceMutation(),
        save: () => {
            const attendance = attendees?.map((att) => {
                // @ts-expect-error
                return att.umsId;
            });

            const data = { meetingId: meetId, attendees: attendance };
            addAttendees(data);
        },
        setOpen,
        setAttendees,
    };

    const { date } = extractDateTime(meetingDetailData?.datetime);

    // Export variables and actions together
    return {
        variables: {
            meetId,
            meetingDetailData,
            open,
            attendees,
            queryState,
            usersData,
            usersLoading,
            date,
            adding,
            added,
            attendeeList,
        },
        actions,
    };
};
