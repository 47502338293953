import { theme } from "@/style";
import { Text, Box, Spacer } from "@flexisaf/flexibull2";
import { useGetAgendasQuery, AgendaViewer } from "@/features/agenda";
import { ApiError } from "@/types/common";

type AgendaTabProps = {
    meetingId: number;
};
export function AgendaTab(props: AgendaTabProps) {
    const { meetingId } = props;

    const {
        data: agendasData,
        isLoading,
        isFetching,
        error,
    } = useGetAgendasQuery({ meetingId }, { skip: !meetingId });
    const agenda = agendasData?.entities[0];

    if (isLoading) {
        return (
            <Box>
                <Spacer space={8} />
                <Text color={theme.PrimaryGrey}>Loading Agenda...</Text>
            </Box>
        );
    }

    return (
        <Box>
            <Spacer space={8} />
            <Text size={theme.FontSizes.lg} bold>
                Agenda
            </Text>
            <Spacer space={16} />
            {agenda ? (
                <AgendaViewer
                    agenda={agenda}
                    isLoading={isLoading}
                    isUpdating={isFetching}
                    error={error as ApiError}
                />
            ) : (
                <Box> No Agenda found for Meeting</Box>
            )}
        </Box>
    );
}
