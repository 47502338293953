import { useEffect } from "react";
import * as yup from "yup";

import { appPaths } from "@/app/app-paths";
import { useAuth } from "@/features/auth/auth.context";
import { useAuthUserNavigate } from "../use-auth-user-navigate";

const validation = yup.object({
    username: yup.string().label("Username").required(),
    password: yup.string().label("Password").required(),
});

export function useLogin() {
    const { user, login, isLoggingIn, loginError } = useAuth();
    const { navigateTo } = useAuthUserNavigate();

    useEffect(() => {
        if (user) {
            navigateTo(appPaths.dashboard);
        }
    }, [user, navigateTo]);

    return {
        login,
        validation,
        isLoggingIn,
        loginError,
    };
}
