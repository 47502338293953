import { useParams } from "react-router";

import { Box, Spacer, Avatar } from "@flexisaf/flexibull2";
import { Text } from "@flexisaf/flexibull2/build/typo";

import { theme } from "@/style";
import { PageLayout, WithLoader } from "@/features/ui";
import { formatDate } from "@/utils/date-utils";
import { ApiError } from "@/types/common";

import { useGetCommentsQuery } from "./comment-api";

export const PrayerComments = (): React.ReactElement => {
    const { prayerId } = useParams();
    const { data, isLoading, error } = useGetCommentsQuery(prayerId);

    return (
        <PageLayout>
            <WithLoader isLoading={isLoading} height={"100vh"} error={error as ApiError}>
                <>
                    <Box maxWidth="900px" margin="0 auto" pad="60px 15px 30px">
                        <Box
                            display="flex"
                            style={{ flexDirection: "column", justifyContent: "space-between" }}
                        >
                            <>
                                <Box>
                                    <Text size="22px" bold>
                                        Comments
                                    </Text>
                                </Box>
                                <Spacer space={30} />
                                <Box>
                                    {data?.map((comment) => {
                                        return (
                                            <CommentBox
                                                key={comment?.id}
                                                content={comment?.content}
                                                time={comment?.createdAt}
                                                user={comment?.staff}
                                            />
                                        );
                                    })}
                                </Box>
                            </>
                        </Box>
                    </Box>
                </>
            </WithLoader>
        </PageLayout>
    );
};

const CommentBox = (props: any): React.ReactElement => {
    const { content, time, user } = props;

    return (
        <>
            <Box style={{ borderBottom: `1px solid ${theme.PrimaryGreyLight}` }} pad="0 0 5px 0">
                <Box>
                    <Text size="14px" color={theme.PrimaryGreyDark}>
                        {content}
                    </Text>
                </Box>

                <Box
                    display="flex"
                    style={{ justifyContent: "space-between", alignItems: "center" }}
                >
                    <Text color={theme.PrimaryGreyMid}>{formatDate(time)}</Text>
                    <Box display="flex" style={{ alignItems: "center" }}>
                        <Text
                            size="12px"
                            color={theme.PrimaryGreyMid}
                        >{`${user?.firstName} ${user?.lastName}`}</Text>

                        <div style={{ marginLeft: "5px" }}>
                            <Avatar src={user?.src} name={user?.firstName || ""} round />
                        </div>
                    </Box>
                </Box>
            </Box>
            <Spacer space={20} />
        </>
    );
};
