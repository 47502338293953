import * as yup from "yup";
import { useEffect, useCallback, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";

import { useAuth } from "@/features/auth";
import { Meeting } from "@/features/meetings/meeting.api";
import { useForm } from "react-hook-form";
import { AgendaItem, PrayerItem } from "../agenda-api";

export type ItemsIdHash = { [id: string]: boolean };

export type UseAgendaFormProps = {
    meeting: Meeting;
    title?: string;
    prayerItems: PrayerItem[];
    agendaItems?: AgendaItem[];
};

export type NewAgendaItem = { position: number; title: string; file: File };
export type AgendaItemLike = AgendaItem | NewAgendaItem;

const agendaValidation = yup.object({
    title: yup.string().label("Title").required(),
});

export function useAgendaForm(props: UseAgendaFormProps) {
    const { meeting, title = "", prayerItems = [], agendaItems: initialAgendaItems = [] } = props;
    const { user } = useAuth();
    const [agendaItems, setAgendaItems] = useState<AgendaItemLike[]>(initialAgendaItems);
    const [addedItems, setAddedItems] = useState<PrayerItem[]>([]);
    const [removedItems, setRemovedItems] = useState<PrayerItem[]>([]);
    const [idHash, setIdHash] = useState<ItemsIdHash>({});

    useEffect(() => {
        let { items, idHash: newIdHash } = compareItemsId(prayerItems, idHash);
        if (items.length) {
            items = [...addedItems, ...items];
            setAddedItems(items);
            setIdHash(newIdHash);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const form = useForm({
        resolver: yupResolver(agendaValidation),
        defaultValues: {
            meetingId: meeting?.id,
            title,
            agendaItems: <AgendaItemLike[]>[],
            prayerItems: <PrayerItem[]>[],
        },
    });

    useEffect(() => {
        form.setValue("prayerItems", addedItems);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addedItems]);

    useEffect(() => {
        form.setValue("agendaItems", agendaItems);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [agendaItems]);

    const formValues = form.watch();

    const onDrop = useCallback(
        <T extends { position: number } = any>(
            result,
            list: T[],
            updateList: (list: T[]) => void
        ) => {
            const { source, destination } = result;
            if (!destination || source.index === destination.index) {
                return;
            }
            let listTemp = [...list];
            const [targetItem] = listTemp.splice(source.index, 1);
            listTemp.splice(destination.index, 0, targetItem);
            listTemp = listTemp.map((item, position) => ({ ...item, position }));
            updateList(listTemp);
        },
        []
    );

    const onDropPrayerItem = useCallback(
        (result) => onDrop<PrayerItem>(result, addedItems, setAddedItems),
        [onDrop, addedItems, setAddedItems]
    );

    const onDropAgendaItem = useCallback(
        (result) => onDrop<AgendaItemLike>(result, agendaItems, setAgendaItems),
        [onDrop, agendaItems, setAgendaItems]
    );

    const removePrayerItem = useCallback(
        (id: number) => {
            const addedItemsTemp = [...addedItems];
            const itemIndex = addedItemsTemp.findIndex((i) => i.prayer.id === id);
            const [targetItem] = addedItemsTemp.splice(itemIndex, 1);
            setAddedItems(addedItemsTemp);
            setRemovedItems((prev) => [...prev, targetItem]);
        },
        [addedItems, setAddedItems, setRemovedItems]
    );

    const addPrayerItems = useCallback(
        (itemsIdsMap) => {
            const addedItemsTemp = [...addedItems];
            const removedItemsTemp = removedItems.reduce<PrayerItem[]>((acc, item) => {
                if (itemsIdsMap[item.prayer.id] === true) {
                    addedItemsTemp.push(item);
                } else {
                    acc.push(item);
                }
                return acc;
            }, []);

            setAddedItems(addedItemsTemp);
            setRemovedItems(removedItemsTemp);
        },
        [removedItems, addedItems]
    );

    const addPrayerItem = useCallback(
        (item: PrayerItem) => setAddedItems((prev) => [...prev, item]),
        [setAddedItems]
    );

    const removeAgendaItem = useCallback(
        (position: number) =>
            setAgendaItems((prev) => prev.filter((item, index) => item.position !== position)),
        [setAgendaItems]
    );

    const addAgendaItem = useCallback(
        (item: NewAgendaItem) => setAgendaItems((prev) => [...prev, item]),
        [setAgendaItems]
    );

    const actions = {
        onDropAgendaItem,
        addAgendaItem,
        removeAgendaItem,
        onDropPrayerItem,
        addPrayerItem,
        removePrayerItem,
        addPrayerItems,
    };

    return {
        form,
        formValues,
        meeting,
        user,
        title,
        agendaItems,
        addedItems,
        removedItems,
        actions,
    };
}

function compareItemsId(prayerItems: PrayerItem[] = [], itemsIdHash: ItemsIdHash) {
    const items = <PrayerItem[]>[];
    const idHash: ItemsIdHash = {};
    prayerItems.forEach((item) => {
        if (itemsIdHash[item.prayer.id] === undefined) {
            idHash[item.prayer.id] = true;
            items.push(item);
        }
    });
    return { items, idHash };
}
