import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import { Box, Button, Spacer, Text, Checkbox } from "@flexisaf/flexibull2";

import { appPaths } from "@/app/app-paths";
import { HookForm as Form } from "@/features/ui";
import { typography } from "@/style/typography";
import { statusColors } from "@/style/colors";
import * as yup from "yup";

import { AuthLayout } from "@/features/auth/auth-layout";
import { useLogin } from "./use-login";
import { Icon } from "@/features/ui/base/icon";
import { theme } from "@/style";

const validation = yup.object({
    username: yup.string().label("Username").required(),
    password: yup.string().label("Password").required(),
});

export const Login = (): React.ReactElement => {
    const { login, isLoggingIn, loginError } = useLogin();

    const form = useForm({
        resolver: yupResolver(validation),
    });
    const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);

    return (
        <>
            <AuthLayout>
                <>
                    <Box pad="24px 0">
                        <Text {...typography.h2} bold block>
                            Sign In
                        </Text>
                    </Box>

                    <Spacer space={24} />
                    {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
                    <Form form={form} onSubmit={form.handleSubmit(login)}>
                        <Form.Input
                            name="username"
                            placeholder="Username (same as email)"
                            spaceBottom
                            block
                            label="Username"
                            type="email"
                        />
                        <Spacer space={24} />
                        <Form.Input
                            name="password"
                            placeholder="Password"
                            block
                            label="Password"
                            type={isPasswordVisible ? "text" : "password"}
                        />
                        <Spacer space={16} />

                        <Box
                            onClick={() => setIsPasswordVisible((prev) => !prev)}
                            style={{ display: "inline-block" }}
                        >
                            <Checkbox
                                label="Show Password"
                                checked={isPasswordVisible}
                                style={{ paddingLeft: 0 }}
                            />
                        </Box>
                        <Spacer space={16} />

                        <>
                            <Text
                                className="login-error"
                                color={statusColors.error.color}
                                block
                                style={{ height: "48px" }}
                            >
                                {loginError?.message}
                            </Text>
                            <Spacer space={8} />
                        </>

                        <Button
                            role="button"
                            progress={isLoggingIn}
                            block
                            onClick={form.handleSubmit((val) => login(val))}
                        >
                            {isLoggingIn ? "Signing In..." : "Sign In"}
                        </Button>
                    </Form>
                    <Spacer space={24} />
                    <Box display="flex" style={{ justifyContent: "flex-end" }}>
                        <Link to={appPaths.forgotPassword}>
                            <Box display="flex" style={{ alignItems: "center" }}>
                                <Text>Forgot Password</Text>
                                <Spacer space={4} direction="horizontal" />
                                <Icon icon="arrow-right-1" color={theme.PrimaryColor} size={12} />
                            </Box>
                        </Link>
                    </Box>
                    <Spacer space={16} />
                </>
            </AuthLayout>
        </>
    );
};
