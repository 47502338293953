import { useCallback } from "react";
import { usePostDocumentMutation, PostDocumentRequest } from "./documents-api";

export type UploadRequest = {
    file: File;
    fileName: string;
};

export type Options = Omit<PostDocumentRequest, "formData">;

const defaultOptions: Options = {
    showErrorNotification: true,
    showSuccessNotification: false,
};

export function useUploadDocument() {
    const [postDocument, { isLoading: isUploading, error: uploadError, isSuccess: isUploaded }] =
        usePostDocumentMutation();

    const upload = useCallback(
        async (req: UploadRequest, options: Options = defaultOptions) => {
            const formData = new FormData();
            formData.append("file", req.file);
            formData.append("fileName", req.fileName);
            const documentRequest = { formData, ...options };
            return await postDocument(documentRequest).unwrap();
        },
        [postDocument]
    );
    return { upload, isUploading, isUploaded, uploadError };
}
