import React, { useEffect, useState } from "react";
import { Avatar, Button } from "@flexisaf/flexibull2";
import { theme } from "@/style";
import { Icon } from "../ui";
import {
    DropdownContainer,
    DropdownList,
    UserName,
    SelectedContainer,
    SelectedItem,
} from "@/features/ui/meeting.style";

interface User {
    avatarUrl: string;
    username: string;
}

const UserDropdown: React.FC<any> = ({ users, setAttendees }) => {
    const [selectedUsers, setSelectedUsers] = useState<any[]>([]);
    const [availableUsers, setAvailableUsers] = useState<any[]>(users);

    useEffect(() => {
        setAvailableUsers(users);
    }, [users]);

    const handleSelectUser = (user: User) => {
        setSelectedUsers([...selectedUsers, user]);
        setAvailableUsers(availableUsers.filter((u) => u !== user));
        setAttendees([...selectedUsers, user]);
    };

    const handleRemoveUser = (userToRemove: User) => {
        const updatedUsers = selectedUsers.filter((user) => user !== userToRemove);
        setSelectedUsers(updatedUsers);
        setAvailableUsers([...availableUsers, userToRemove]);
        setAttendees(updatedUsers);
    };

    return (
        <DropdownContainer>
            <DropdownList>
                {availableUsers?.map((user, index) => (
                    <SelectedItem key={index} onClick={() => handleSelectUser(user)}>
                        <div>
                            <Avatar src={user.img} name={user?.firstName} round />
                            <UserName>
                                {user?.firstName} - {user?.lastName}
                            </UserName>
                        </div>
                    </SelectedItem>
                ))}
            </DropdownList>
            <h5>Selected Users</h5>
            <SelectedContainer>
                {selectedUsers.map((user, index) => (
                    <SelectedItem key={index}>
                        <div>
                            <Avatar src={user.img} name={user?.firstName} round />
                            <UserName>
                                {user?.firstName} - {user?.lastName}
                            </UserName>
                        </div>
                        <Button
                            plain
                            onClick={() => handleRemoveUser(user)}
                            color={theme.PrimaryRed}
                        >
                            <Icon icon="minus-circle" color={theme.PrimaryRed} />
                        </Button>
                    </SelectedItem>
                ))}
            </SelectedContainer>
        </DropdownContainer>
    );
};

export default UserDropdown;
