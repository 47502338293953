import { useMemo } from "react";
import { MeetingStatus, Unit } from "@/utils/enums";
import { useAuth } from "@/features/auth";
import { MeetingFilterParams, useGetFilteredMeetingsQuery } from "./meeting.api";

type UseGetUserMeetingProps = {
    status: MeetingStatus;
};

export default function useGetUserMeeting({ status }: UseGetUserMeetingProps) {
    const { user } = useAuth();

    const queryParams = useMemo<MeetingFilterParams>(() => {
        const unit =
            user?.currentRole?.unit === Unit.Secretariat
                ? Unit.Senate
                : (user.currentRole.unit as Unit);

        const roleId = user.currentRole.role.id;
        const params: MeetingFilterParams = {
            unit,
            status,
            roleId,
        };

        if (user.currentRole.unit === Unit.Department) {
            params.departmentId = user.department?.id;
            params.facultyId = user.department?.faculty.id;
        } else if (user.currentRole.unit === Unit.Faculty) {
            params.facultyId = user.faculty?.id;
        }
        return params;
    }, [user, status]);

    const { data: meetingData, ...filteredMeetingMeta } = useGetFilteredMeetingsQuery(queryParams, {
        skip: !user,
    });

    return {
        ...filteredMeetingMeta,
        meeting: meetingData?.entities[0],
    };
}
