import { useDispatch } from "react-redux";
import { useInterval } from "react-use";
import { Text } from "@flexisaf/flexibull2";

import { useGlobalSlice } from "@/app/global-slice";
import { switchRole, useAuthSlice } from "@/features/auth";
import { Modal } from "@/features/ui";

import { theme } from "@/style";
import { Duration } from "@/utils/enums";
import { titleCase } from "@/utils/string-utils";

export function RoleSwitchIndicator() {
    const dispatch = useDispatch();

    const {
        roleSwitcher = {},
        actions: { endRoleSwitching },
    } = useGlobalSlice();

    const {
        user: { currentRole },
    } = useAuthSlice();

    const switchCurrentRole = () => {
        if (roleSwitcher.targetRole) {
            dispatch(switchRole(roleSwitcher.targetRole));
            endRoleSwitching();
        }
    };

    useInterval(switchCurrentRole, roleSwitcher.isSwitching ? Duration.Mid : null);

    return (
        <Modal
            title={roleSwitcher.isSwitching ? "Switching Role..." : ""}
            isOpen={!!roleSwitcher.targetRole}
        >
            {roleSwitcher.isSwitching ? (
                <Text block>
                    {titleCase(currentRole?.role.name)} to{" "}
                    {titleCase(roleSwitcher.targetRole?.role.name)}
                </Text>
            ) : (
                <Text color={theme.PrimaryGreen} size="1.5em">
                    Successfully Switched to {roleSwitcher.targetRole?.role.name}
                </Text>
            )}
        </Modal>
    );
}
