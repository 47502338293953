export const formatNumber = (number: number, opts = { locale: "en-NG" }) =>
    new Intl.NumberFormat(opts.locale).format(number);

export const addNumberSuffix = (num: number): string => {
    const lastDigit = num % 10;
    const secondLastDigit = Math.floor(num / 10) % 10;

    let suffix = "th";

    if (secondLastDigit !== 1) {
        if (lastDigit === 1) {
            suffix = "st";
        } else if (lastDigit === 2) {
            suffix = "nd";
        } else if (lastDigit === 3) {
            suffix = "rd";
        }
    }

    return `${num}${suffix}`;
};
