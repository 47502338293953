import { useMemo, useState } from "react";
import { Box, Button, Spacer } from "@flexisaf/flexibull2";
import { theme } from "@/style";
import { FileTypes } from "@/utils/enums";
import { PreviewerWrapper } from "./styles";
import { PreviewerContext } from "./previewer-context";
import { ImageRenderer, PdfRenderer } from "./renderers";
import { useResponsiveValue } from "@/lib/use-responsive-value";
import { Icon } from "@/features/ui";

type DocumentPreviewerProps = {
    header?: React.ReactNode;
    url: string;
    ext: FileTypes;
    withDownload?: boolean;
    width?: string;
    maxWidth?: string;
};

export const DocumentPreviewer = (props: DocumentPreviewerProps) => {
    const [hasError, setHasError] = useState(false);
    const { header, ext, url, withDownload = true, width = "100vw", maxWidth = "900px" } = props;
    const [, setError] = useState("");
    const rendererProps = useMemo(() => ({ url, onLoadError: setError }), [setError, url]);

    const buttonJustifyValue = useResponsiveValue({ sm: "center", md: "right" });
    const buttonWidth = useResponsiveValue({ sm: "100%", md: "auto" });

    return (
        <PreviewerContext.Provider value={{ hasError, setHasError }}>
            <Box width={width} maxWidth={maxWidth} margin="0 auto" pad="16px">
                <PreviewerWrapper>
                    {header}
                    <Spacer space={8} />
                    {(() => {
                        if (ext === FileTypes.Pdf) {
                            return <PdfRenderer {...rendererProps} />;
                        }

                        if (ext === FileTypes.Image) {
                            return <ImageRenderer {...rendererProps} />;
                        }
                    })()}

                    <Spacer space={8} />
                    {withDownload && (
                        <Box display="flex" style={{ justifyContent: buttonJustifyValue }}>
                            <Button
                                style={{ width: buttonWidth }}
                                iconRight={
                                    <Icon icon="document-download" color={theme.PrimaryWhite} />
                                }
                            >
                                <a
                                    href={url}
                                    download
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{ color: theme.PrimaryWhite }}
                                >
                                    Download File
                                </a>
                            </Button>
                        </Box>
                    )}
                </PreviewerWrapper>
            </Box>
        </PreviewerContext.Provider>
    );
};
