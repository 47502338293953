/* eslint-disable */
// interface Comment {
//     id: number;
//     text: string;
//     commenter: {
//         id: number;
//         name: string;
//         avatarUrl: string;
//     };
// }

// type commentListProps = {
//     comments: Comment[];
// };

import { Box, Text, Avatar } from "@flexisaf/flexibull2";

export const CommentList = (props: any): React.ReactElement => {
    const { comments } = props;
    const MAX_AVATARS = 3;

    return (
        <Box>
            {comments.length === 0 ? (
                <Text>No comments</Text>
            ) : (
                <Box style={{ display: "flex", alignItems: "center" }}>
                    {comments.slice(0, MAX_AVATARS).map((comment, index) => (
                        <Avatar
                            key={index}
                            src={comment?.staff?.pictureUrl}
                            name={comment?.staff?.firstName ?? " "}
                            style={{
                                width: "2em",
                                height: "2em",
                                borderRadius: "50%",
                                position: "relative",
                                zIndex: MAX_AVATARS,
                                marginLeft: index === 0 ? "0" : "-1em",
                            }}
                        />
                    ))}

                    {comments?.length > MAX_AVATARS && (
                        <Box
                            style={{
                                width: "2em",
                                height: "2em",
                                borderRadius: "50%",
                                backgroundColor: "#999",
                                position: "relative",
                                zIndex: MAX_AVATARS,
                                marginLeft: `1em`,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontSize: "0.8em",
                                fontWeight: "bold",
                            }}
                        >
                            +{comments?.length - MAX_AVATARS}
                        </Box>
                    )}
                    <Text style={{ marginLeft: "0.5em" }}>
                        {comments?.length} comment{comments?.length !== 1 && "s"}
                    </Text>
                </Box>
            )}
        </Box>
    );
};
