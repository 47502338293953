import theme from "./theme";

export const typography = {
    h1: { size: "1.6em", block: true, bold: true },
    h2: { size: "1.5em", block: true },
    h3: { size: "1.2em", bold: true },
    h4: { size: "1.2em" },
    h5: { size: "1em", bold: true },
    caption: { size: "0.8em", color: theme.PrimaryGrey },
    subtext: { size: "32px", uppercase: true },
    fontFamily: "Manrope",
    body: {
        size: "1em",
        lineHeight: "120%",
        weight: 400,
        color: theme.SecondaryGrey,
    },
};
