import { Navigate, useLocation } from "react-router-dom";
import { appPaths } from "@/app/app-paths";
import { useAuth } from "@/features/auth/auth.context";

type AuthGuardPropsWithChildren = {
    children: React.ReactElement;
};
type AuthGuardPropsWithElement = {
    element: React.ReactElement;
};

export function AuthGuard(props: AuthGuardPropsWithElement | AuthGuardPropsWithChildren) {
    const { isAuthenticated } = useAuth();
    const location = useLocation();
    if (isAuthenticated) {
        if ("element" in props) return props.element;
        else return props.children;
    }
    return <Navigate to={appPaths.login} state={location} replace />;
}
