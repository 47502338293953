import { useEffect, useMemo, useRef } from "react";
import styled from "styled-components";

import { Box, Spacer, Button, FlexiPagination, Loader, Input } from "@flexisaf/flexibull2";
import { Text } from "@flexisaf/flexibull2/build/typo";
import { Icon } from "@/features/ui/base/icon";
import { useGetUpcomingSenateMeeting } from "@/features/meetings";

import theme from "@/style/theme";
import { useAuthUserNavigate } from "@/features/auth/use-auth-user-navigate";
import { appPaths } from "@/app";
import { PageLayout, WithLoader, Select, Section } from "../../ui";
import { ApiError } from "@/types/common";
import { useQueryStateSync } from "@/lib/use-query-state-sync";
import { PaginationAdapterReturn, paginationAdapter } from "@/lib/pagination-adapter";

import { PrayerParams, useGetPrayersQuery } from "./prayer-api";
import { PrayerBox } from "../prayers.ui";
import { prayerCategoryOptions } from "../prayers-ui-options";

import { usePrayerController } from "./use-prayer";
import { Unit } from "@/utils/enums";
import { pageOptions } from "@/utils/object-utils";
import { Privileges, usePrivileges } from "@/features/authorization";
import { getDefaultPrayersFilter } from "./prayer-utils";
import { useResponsiveValue } from "@/lib/use-responsive-value";

const IconWrapper = styled.div`
    margin-right: 5px;
`;

const FilterLabelWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const Prayers = (): React.ReactElement => {
    const { navigateTo } = useAuthUserNavigate();
    const { userDetails, departmentOptions, meetingId, filteredPrayerStatusOptions, loading } =
        usePrayerController();

    const { queryState, setQueryField, page, setPage, setLimit } = useQueryStateSync<
        PrayerParams,
        PaginationAdapterReturn
    >(getDefaultPrayersFilter(userDetails), paginationAdapter);
    const { meeting } = useGetUpcomingSenateMeeting();

    const hasSetDefaultMeetingNumber = useRef<boolean>(false);

    const handleStatusChange = (status) => setQueryField("status", status);
    const handleCategoryChange = (category) => setQueryField("category", category);

    const handleMeetingChange = (meetingNo) => setQueryField("meetingNumber", meetingNo);

    const handleDepartmentChange = (departmentId) => setQueryField("departmentId", departmentId);

    const { data: prayerList, isLoading, isFetching, error } = useGetPrayersQuery(queryState);

    const { hasPrivilege } = usePrivileges();

    const canInitiatePrayer = hasPrivilege(Privileges.InitiatePrayers);
    const buttonHeight = useResponsiveValue({
        sm: undefined,
        md: "51",
    }) as string;

    useEffect(() => {
        if (meeting?.number && !hasSetDefaultMeetingNumber.current && !queryState.meetingNumber) {
            setQueryField("meetingNumber", meeting.number);
            hasSetDefaultMeetingNumber.current = true;
        }
    }, [meeting?.number, setQueryField, queryState.meetingNumber]);

    // TEMPORARY HACK: Filtering prayer requests as a workaround until the backend issue has been fixed
    const data = useMemo(() => {
        if (!prayerList?.entities) return;
        const entities = prayerList.entities.filter((pr) => {
            if (!queryState.status) return true;
            return pr.status.toLowerCase() === queryState.status.toLowerCase();
        });
        return {
            ...prayerList,
            entities,
        };
    }, [prayerList, queryState]);

    return (
        <PageLayout pageLabel="Prayers">
            <WithLoader isLoading={isLoading} error={error as ApiError} height={"100vh"}>
                <>
                    <Section
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                        }}
                    >
                        <Box>
                            <Text size="22px" bold>
                                Prayers
                            </Text>
                            <Spacer space={20} />
                            <Box>
                                <Text color="#64748B">Search, share and track prayers</Text>
                            </Box>

                            {(userDetails.unit === Unit.Department ||
                                userDetails.unit === Unit.Faculty) && (
                                <>
                                    {userDetails.faculty && (
                                        <>
                                            <Spacer space={20} />
                                            <Box>
                                                <Text bold>
                                                    Faculty of {userDetails.faculty.name}
                                                </Text>
                                            </Box>
                                        </>
                                    )}
                                    {userDetails.department?.name && (
                                        <>
                                            <Spacer space={5} />
                                            <Box>
                                                <Text color="#64748B">
                                                    Department of {userDetails.department.name}
                                                </Text>
                                            </Box>
                                        </>
                                    )}
                                </>
                            )}
                        </Box>
                        {canInitiatePrayer && (
                            <Button
                                disabled={!meetingId}
                                onClick={() => navigateTo(appPaths.createPrayer)}
                                width={160}
                                height={buttonHeight}
                            >
                                New Prayer
                            </Button>
                        )}
                    </Section>
                    <Spacer space={16} />

                    <Section>
                        <Select
                            placeholder="All Prayers"
                            name="status"
                            label={
                                <FilterLabelWrapper>
                                    <IconWrapper>
                                        <Icon icon="filter-edit" size={14} />
                                    </IconWrapper>

                                    <Text size="14px" color={theme.PrimaryGreyMid}>
                                        Filter Prayers
                                    </Text>
                                </FilterLabelWrapper>
                            }
                            options={filteredPrayerStatusOptions}
                            spaceTop
                            style={{ width: "100%" }}
                            onChange={handleStatusChange}
                            value={queryState.status ?? ""}
                        />
                        <Spacer space={20} />
                        <Box
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            {userDetails.unit === Unit.Faculty ? (
                                <Box width="49%">
                                    <Select
                                        placeholder="Select Department"
                                        name="departmentId"
                                        label={
                                            <FilterLabelWrapper>
                                                <IconWrapper>
                                                    <Icon icon="layer" size={14} />
                                                </IconWrapper>

                                                <Text size="14px" color={theme.PrimaryGreyMid}>
                                                    Department
                                                </Text>
                                            </FilterLabelWrapper>
                                        }
                                        options={departmentOptions ?? []}
                                        spaceTop
                                        style={{ width: "100%" }}
                                        isLoading={loading.isLoadingDepartments}
                                        onChange={handleDepartmentChange}
                                        value={queryState.departmentId ?? ""}
                                    />
                                </Box>
                            ) : (
                                <Box width="49%">
                                    <Select
                                        placeholder="Select Category"
                                        name="category"
                                        label={
                                            <FilterLabelWrapper>
                                                <IconWrapper>
                                                    <Icon icon="layer" size={14} />
                                                </IconWrapper>

                                                <Text size="14px" color={theme.PrimaryGreyMid}>
                                                    Category
                                                </Text>
                                            </FilterLabelWrapper>
                                        }
                                        options={prayerCategoryOptions}
                                        spaceTop
                                        style={{ width: "100%" }}
                                        onChange={handleCategoryChange}
                                        value={queryState.category ?? ""}
                                    />
                                </Box>
                            )}

                            <Box width="49%">
                                <FilterLabelWrapper>
                                    <IconWrapper>
                                        <Icon icon="judge" size={14} />
                                    </IconWrapper>

                                    <Text size="14px" color={theme.PrimaryGreyMid}>
                                        Senate Meeting Number
                                    </Text>
                                </FilterLabelWrapper>
                                <Input
                                    type="number"
                                    style={{ width: "100%" }}
                                    value={queryState.meetingNumber}
                                    onChange={(e) => handleMeetingChange(e.target.value)}
                                />
                            </Box>
                        </Box>
                        <Spacer space={30} />
                        <Text size="16px">Prayers</Text>
                        <Spacer space={5} />
                        {isFetching ? (
                            <Box
                                display="flex"
                                style={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <Loader size={48} />{" "}
                            </Box>
                        ) : (
                            <PrayerBox prayers={data?.entities} />
                        )}

                        {data && data?.entities?.length > 0 && (
                            <>
                                <Spacer space={5} />
                                <FlexiPagination
                                    pageCounts={pageOptions}
                                    total={data?.total}
                                    itemsDisplayed
                                    pageSize={queryState.limit}
                                    changePageSize={({ value }) => setLimit(value)}
                                    onChange={(val: number) => setPage(val)}
                                    current={page}
                                    style={{ maxWidth: "100%" }}
                                />
                            </>
                        )}
                    </Section>
                </>
            </WithLoader>
        </PageLayout>
    );
};
