import { PrayerStatuses } from "@/utils/enums";

export type PrayerUiOptions = {
    label: string;
    value: string | number;
};

export const prayerStatusOptions: PrayerUiOptions[] = [
    { label: "Initiated", value: PrayerStatuses.Initiated },
    { label: "Raised", value: PrayerStatuses.Raised },
    {
        label: "Submitted",
        value: PrayerStatuses.Submitted,
    },
    {
        label: "Recommended",
        value: PrayerStatuses.Recommended,
    },

    {
        label: "Stepped down",
        value: PrayerStatuses.SteppedDown,
    },

    { label: "Approved", value: PrayerStatuses.Approved },
    { label: "Conditional Approval", value: PrayerStatuses.ConditionalApproval },
    { label: "Rejected", value: PrayerStatuses.Rejected },
];

export const prayerCategoryOptions: PrayerUiOptions[] = [
    {
        label: "All Categories",
        value: "",
    },
    {
        label: "Deferment",
        value: "DEFERMENT",
    },
    {
        label: "Deregistration of Courses",
        value: "DEREGISTRATION_OF_COURSES",
    },
    {
        label: "Extension of Residency",
        value: "EXTENSION_OF_RESIDENCY",
    },
    {
        label: "Results",
        value: "RESULTS",
    },
    {
        label: "Others",
        value: "OTHERS",
    },
];

export const meetingIdOptions: PrayerUiOptions[] = [
    {
        label: "No Number",
        value: "",
    },
    {
        label: "01",
        value: 1,
    },
    {
        label: "12",
        value: 12,
    },
];

export const departmentOptions: PrayerUiOptions[] = [
    {
        label: "GST",
        value: "GST",
    },
];

export const prayerReasonUiOptions: PrayerUiOptions[] = [
    {
        label: "Medical",
        value: "MEDICAL",
    },
    {
        label: "Financial",
        value: "FINANCIAL",
    },
    {
        label: "Compassionate",
        value: "COMPASSIONATE",
    },
    {
        label: "Others",
        value: "OTHERS",
    },
    {
        label: "N/A",
        value: "NOT_APPLICABLE",
    },
];

export const semesterOptions: PrayerUiOptions[] = [
    {
        label: "First Semester",
        value: "FIRST",
    },
    {
        label: "Second Semester",
        value: "SECOND",
    },
];

export const levelOptions: PrayerUiOptions[] = [
    {
        label: "100",
        value: "ONE",
    },
    {
        label: "200",
        value: "TWO",
    },
    {
        label: "300",
        value: "THREE",
    },
    {
        label: "400",
        value: "FOUR",
    },
    {
        label: "500",
        value: "FIVE",
    },
];
