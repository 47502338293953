import styled from "styled-components";

import { Box, Text, Avatar, DropDown } from "@flexisaf/flexibull2";
import { theme } from "@/style";

const ProfileWrapper = styled.div`
    padding: 20px 0px;
    display: grid;
    grid-template-columns: max-content auto max-content;
    width: 100%;
    grid-gap: 10px;
    align-items: center;

    & .text-profile {
        max-width: 190px;
        color: ${theme.PrimaryGreyDark};
        text-align: right;
    }
    & .popper {
        border-radius: 2px;
        transition: ${(props: any) => props.theme.PrimaryTransition};
        cursor: pointer;

        &:hover {
            background: rgba(0, 0, 0, 0.05);
        }
    }
`;

export type ProfileHolderMenuItem = {
    onClick: () => void;
    label: string;
    iconLeft?: string;
    iconRight?: string;
};

type ProfileHolderProps = {
    imageUrl?: string;
    status?: string;
    fullName: string;
    username: string;
    menuList: ProfileHolderMenuItem[];
};

export const ProfileHolder = (props: ProfileHolderProps) => {
    const { imageUrl, status, username, fullName, menuList } = props;

    return (
        <DropDown
            block
            trigger="click"
            label={
                <ProfileWrapper style={{ cursor: "pointer" }}>
                    <Box className="text-profile">
                        <Text block bold truncate>
                            {fullName}
                        </Text>
                        <Text size="0.8em" truncate>
                            {username}
                        </Text>
                    </Box>
                    <Box>
                        <Avatar size="40px" round name={username} src={imageUrl} status={status} />
                    </Box>
                </ProfileWrapper>
            }
            menuAlign="bottom right"
            menuList={menuList}
        />
    );
};
