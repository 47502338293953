import { Text, Spacer, Button, Box } from "@flexisaf/flexibull2";

import { Icon } from "@/features/ui/base/icon";
import { useEffect, useRef } from "react";
import { theme } from "@/style";
import { useClickAway } from "react-use";
import { ModalBodyProps, ModalBody, ModalOverlay } from "./modal.styles";

export type ModalProps = ModalBodyProps & {
    title?: React.ReactNode;
    subtitle?: string;
    centeredTitle?: boolean;
    topSpace?: number;
    children: React.ReactElement;
    isOpen: boolean;
    closeIf?: boolean;
    autoclose?: boolean;
    titleSize?: string;
    onClose?: Function;
    style?: React.CSSProperties;
    bodyStyle?: React.CSSProperties;
    withCloseButton?: boolean;
};

export function Modal(props: ModalProps) {
    const {
        title,
        subtitle,
        children,
        centeredTitle,
        isOpen,
        onClose,
        closeIf,
        position = "center",
        topSpace = 16,
        autoclose = true,
        titleSize = theme.FontSizes.xl,
        withCloseButton,
        style = {},
        bodyStyle = {},
    } = props;
    const modalBodyRef = useRef<HTMLDivElement | null>(null);

    useClickAway(modalBodyRef, () => {
        if (autoclose && onClose) {
            onClose();
        }
    });

    useEffect(() => {
        if (closeIf && onClose) onClose();
    }, [closeIf, onClose]);

    return (
        <ModalOverlay isOpen={isOpen}>
            <ModalBody position={position}>
                <div ref={modalBodyRef} style={{ width: "360px", ...style }}>
                    <Box
                        display="flex"
                        style={{
                            justifyContent: "space-between",
                            alignItems: "center",
                            borderTopRightRadius: "8px",
                            borderTopLeftRadius: "8px",
                        }}
                    >
                        {title && (
                            <>
                                <Box
                                    style={{
                                        margin: `${centeredTitle ? "0 auto 0 auto" : "none"}`,
                                        padding: "16px",
                                    }}
                                >
                                    <Text block size={titleSize} bold>
                                        {title}
                                    </Text>
                                    {subtitle && (
                                        <>
                                            <Spacer space="4" />
                                            <Text block color={theme.PrimaryGrey}>
                                                {subtitle}
                                            </Text>
                                        </>
                                    )}
                                </Box>
                            </>
                        )}
                        {withCloseButton && (
                            <Button plain onClick={onClose} style={{ marginLeft: "auto" }}>
                                <Icon icon="close-circle" size="1.5em" />
                            </Button>
                        )}
                    </Box>
                    <Box style={{ padding: "16px", ...bodyStyle }}>
                        <Spacer space={topSpace} />
                        {children}
                    </Box>
                </div>
            </ModalBody>
        </ModalOverlay>
    );
}
