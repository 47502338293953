import { Notify } from "@flexisaf/flexibull2";

import { updateCurrentMeetingAgendaPrayerItems } from "@/features/agenda";
import { baseApi } from "@/store/base-api";
import { encodeInterpolatedUrl } from "@/utils/string-utils";
import { MeetingStatus, Methods, PrayerStatuses, Unit } from "@/utils/enums";
import { List, QueryFulfilledError } from "@/types/common";
import { Department, Faculty } from "@/features/users/users-api";

export const prayersApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getPrayers: builder.query<List<Prayer>, PrayerParams>({
            query: (params) => ({
                url: "/prayers",
                params,
            }),

            providesTags: ["PRAYERS"],
        }),

        getPrayerById: builder.query<Prayer, string>({
            query: (id) => ({
                url: `/prayers/${id}`,
            }),
            providesTags: ["PRAYER"],
        }),
        createPrayer: builder.mutation<any, any>({
            query: (data) => ({
                url: "/prayers",
                method: Methods.Post,
                data,
            }),
            invalidatesTags: ["PRAYERS"],
            async onQueryStarted(_, { queryFulfilled: qf }) {
                qf.then(() => Notify("Prayer Created Successfully")).catch(
                    (err: QueryFulfilledError) => Notify(err.error?.message, { status: "error" })
                );
            },
        }),
        updatePrayer: builder.mutation<any, any>({
            query: ({ id, ...data }) => ({
                url: `/prayers/${id}`,
                method: Methods.Put,
                data,
            }),
            invalidatesTags: ["PRAYER", "PRAYERS", "COMMENTS"],
            async onQueryStarted(_, { queryFulfilled: qf, dispatch }) {
                qf.then(({ data }) => {
                    updateCurrentMeetingAgendaPrayerItems(data, dispatch);
                    Notify("Prayer Updated Successfully");
                }).catch((err: QueryFulfilledError) =>
                    Notify(err.error?.message, { status: "error" })
                );
            },
        }),
        detelePrayer: builder.mutation<any, any>({
            query: (id) => ({
                url: `/prayers/${id}`,
                method: Methods.Delete,
            }),
            async onQueryStarted(req, { queryFulfilled: qf }) {
                qf.then(() => Notify("Prayer Deleted Successfully")).catch(
                    (err: QueryFulfilledError) => Notify(err.error?.message, { status: "error" })
                );
            },
        }),
        getSRMSResults: builder.query<any, any>({
            query: ({ subProgramId, sessionId, ...params }) => ({
                url: encodeInterpolatedUrl`/srms/student-result/${subProgramId}/${sessionId}`,
                params,
            }),
        }),
        getSRMSSessions: builder.query<any, void>({
            query: () => ({
                url: "srms/sessions",
            }),
        }),
        getSRSMSSubprograms: builder.query<any, any>({
            query: (params) => ({
                url: "srms/subprograms",
                params,
            }),
        }),
        getSRMSDepartments: builder.query<any, any>({
            query: (params) => ({
                url: "srms/departments",
                params,
            }),
        }),
        getSRMSFaculties: builder.query<any, void>({
            query: () => ({
                url: "srms/faculties",
            }),
        }),
    }),
});

export interface LocalResult {
    id: number;
    url: string;
}

export interface Result extends LocalResult {
    level: string;
    semester: string;
    session: string;
    subprogram: string;
}

export interface PrayerParams {
    category?: string;
    departmentId?: string;
    facultyId?: string;
    meetingId?: number;
    meetingNumber?: number;
    type?: string;
    limit?: number;
    offset?: number;
    status?: string;
}

export interface Prayer {
    category: string;
    createdAt: string;
    department?: Department;
    faculty?: Faculty;
    description: string;
    id: number;
    meeting: Meeting;
    organization: string;
    results: [] | Result[];
    localResults: [] | LocalResult[];
    reason?: string;
    status: PrayerStatuses;
    title: string;
    updatedAt: string;
    otherAttachments: string;
}

interface Meeting {
    createdAt: string;
    datetime: string;
    departmentId: string;
    description: string;
    facultyId: string;
    id: number;
    organization: string;
    otherAttachment: string;
    preAgenda: string;
    status: MeetingStatus;
    title: string;
    type: string;
    unit: Unit;
    updatedAt: string;
    venue: string;
}

export const {
    useGetPrayersQuery,
    useGetPrayerByIdQuery,
    useCreatePrayerMutation,
    useUpdatePrayerMutation,
    useGetSRMSResultsQuery,
    useGetSRMSSessionsQuery,
    useGetSRSMSSubprogramsQuery,
    useGetSRMSDepartmentsQuery,
    useGetSRMSFacultiesQuery,
} = prayersApi;
