import { theme } from "@/style";
import { Box } from "@flexisaf/flexibull2";
import { darken, transparentize } from "polished";
import styled, { css } from "styled-components";

export const DataItemWrapper = styled(Box)<{ hasOnClick: boolean }>`
    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: space-between;
    ${(p) =>
        p.hasOnClick &&
        css`
            cursor: pointer;
            :hover {
                background: ${transparentize(0.9, darken(0.2, theme.PrimaryColor))};
            }
        `};
`;
