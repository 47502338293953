import { useEffect, useState } from "react";
import { Box, Button, Spacer } from "@flexisaf/flexibull2";

import { Icon } from "@/features/ui";
import { theme } from "@/style";

/* PAGINATION  */

type PaginationProps = {
    onPrevious: Function;
    onNext: Function;
    currentPage: number;
    totalPages: number;
};

export const Pagination = (props: PaginationProps) => {
    const { currentPage, totalPages, onPrevious, onNext } = props;

    const prevButtonDisabled = currentPage === 1;
    const nextButtonDisabled = currentPage === totalPages;

    return (
        <Box
            className="pagination-wrapper"
            display="flex"
            style={{
                alignItems: "center",
                padding: "1em",
                justifyContent: "center",
            }}
        >
            <Button pad="4px" height={24} plain onClick={onPrevious} disabled={prevButtonDisabled}>
                <Icon icon="arrow-left" color={prevButtonDisabled ? theme.PrimaryGrey : "auto"} />
            </Button>
            <Spacer space={8} direction="horizontal" />
            <Box>
                {currentPage} of {totalPages}
            </Box>
            <Spacer pad="4px" height={24} space={8} direction="horizontal" />
            <Button pad="4px" height={24} plain onClick={onNext} disabled={nextButtonDisabled}>
                <Icon
                    icon="arrow-right-1"
                    color={nextButtonDisabled ? theme.PrimaryGrey : "auto"}
                />
            </Button>
        </Box>
    );
};

/* ZOOMER  */

type ZoomerProps = {
    onZoomChange: (zoom: number) => void;
    min?: number;
    max?: number;
    step?: number;
};

export const Zoomer = (props: ZoomerProps) => {
    const { min = 1, max = 2, step = 0.1, onZoomChange } = props;
    const [zoom, setZoom] = useState(min);

    useEffect(() => onZoomChange(zoom), [zoom, onZoomChange]);

    const zoomInDisabled = zoom + step > max;
    const zoomOutDisabled = zoom - step < min;

    return (
        <Box
            className="zoomer-wrapper"
            display="flex"
            style={{
                alignItems: "center",
                padding: "1em",
                justifyContent: "center",
            }}
        >
            <Button
                pad="4px"
                height={24}
                plain
                onClick={() => setZoom((prev) => prev - step)}
                disabled={zoomOutDisabled}
            >
                <Icon icon="minus" color={zoomOutDisabled ? theme.PrimaryGrey : "auto"} />
            </Button>
            <Spacer space={8} direction="horizontal" />
            <Box>{zoom.toFixed(1)}x</Box>
            <Spacer space={8} direction="horizontal" />
            <Button
                plain
                pad="4px"
                height={24}
                onClick={() => setZoom((prev) => prev + step)}
                disabled={zoomInDisabled}
            >
                <Icon icon="add" color={zoomInDisabled ? theme.PrimaryGrey : "auto"} />
            </Button>
        </Box>
    );
};
