import { Unit } from "@/utils/enums";
import { useAuth } from "../auth";
import { useUserUnitValue } from "../users";
import {
    useCreateMeetingMutation,
    useStartMeetingMutation,
    usePauseMeetingMutation,
    useResumeMeetingMutation,
    useUpdateMeetingMutation,
    useCancelMeetingMutation,
    useEndMeetingMutation,
    MeetingFilterParams,
    useGetPreviousMeetingsWithSearchQuery,
} from "./meeting.api";
import { PaginationAdapterReturn, paginationAdapter } from "@/lib/pagination-adapter";
import { useQueryStateSync } from "@/lib/use-query-state-sync";

export const useMeeting = () => {
    const { user } = useAuth();
    const unitParam = useUserUnitValue({
        SECRETARIAT: Unit.Senate,
        default: user.currentRole.unit,
    });
    const { queryState } = useQueryStateSync<MeetingFilterParams, PaginationAdapterReturn>(
        {
            limit: 3,
            unit: unitParam,
            departmentId: user.department?.id,
            facultyId: user.faculty?.id,
        },
        paginationAdapter
    );

    const {
        data: previousMeetings,
        isLoading: isLoadingPreviousMeetings,
        error: previousMeetingError,
    } = useGetPreviousMeetingsWithSearchQuery(queryState);

    const [
        createMeeting,
        {
            isLoading: loading,
            error: meetingError,
            isSuccess: meetingCreated,
            data: meetingResponse,
        },
    ] = useCreateMeetingMutation();

    const [
        initiateMeeting,
        { isLoading: starting, error: startError, isSuccess: successfullyStarted },
    ] = useStartMeetingMutation();

    const [pauseMeeting, { isLoading: pausingMeeting }] = usePauseMeetingMutation();
    const [resumeMeeting, { isLoading: resumingMeeting }] = useResumeMeetingMutation();

    const [
        endMeeting,
        { isLoading: stoppingMeeting, error: stopError, isSuccess: successfullyStopped },
    ] = useEndMeetingMutation();

    const [
        cancelMeeting,
        { isLoading: cancelling, error: errorOnCancel, isSuccess: successfullyCancelled },
    ] = useCancelMeetingMutation();

    const [updateMeeting, { isLoading: updating, error: updateError, isSuccess: updateSuccess }] =
        useUpdateMeetingMutation();

    return {
        user,
        isLoadingPreviousMeetings,
        previousMeetingError,
        previousMeetings: previousMeetings?.entities,
        createMeeting,
        meetingError,
        meetingCreated,
        loading,
        meetingResponse,
        initiateMeeting,
        starting,
        startError,
        successfullyStarted,
        updateMeeting,
        updating,
        updateError,
        updateSuccess,
        cancelMeeting,
        cancelling,
        errorOnCancel,
        successfullyCancelled,
        endMeeting,
        stoppingMeeting,
        stopError,
        successfullyStopped,
        pauseMeeting,
        pausingMeeting,
        resumeMeeting,
        resumingMeeting,
    };
};
