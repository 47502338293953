import { Spacer } from "@flexisaf/flexibull2/build/layout";
import { Add, BookSaved, ClipboardText, Document, Notepad2 } from "iconsax-react";
import { Button } from "@flexisaf/flexibull2/build/button";
import { DataItem, PageLayout, WithLoader } from "@/features/ui";
import { ApiError } from "@/types/common";
import { appPaths } from "@/app";
import { useAuthUserNavigate } from "@/features/auth/use-auth-user-navigate";
import { Text } from "@flexisaf/flexibull2/build/typo";
import {
    EmptySpace,
    TitleContainer,
    Title,
    QuickLinks,
    QuickLinksContainer,
    ModSubText,
    FlexContainer,
    MeetingWrapper,
} from "@/features/ui/meeting.style";
import { formatDate } from "@/utils/date-utils";
import { usePrivileges, Privileges } from "@/features/authorization";
import { MeetingStatus } from "@/utils/enums";
import { theme } from "@/style";

import useGetUserMeeeting from "./use-get-user-meeting";
import { attachSuffix } from "./useSuffix";
import { useMeeting } from "./meeting.controller";

export const Welcome = () => {
    const { isLoadingPreviousMeetings, previousMeetingError, previousMeetings } = useMeeting();
    const { navigateTo, buildPath } = useAuthUserNavigate();
    const { hasPrivilege } = usePrivileges();
    const {
        meeting: upcomingMeeting,
        isLoading: isLoadingUserMeeting,
        error: getUserMeetingError,
    } = useGetUserMeeeting({ status: MeetingStatus.Inactive });

    const disableMeetingButton = !hasPrivilege(Privileges.CreateMeeting) || !!upcomingMeeting;

    const pageError = (previousMeetingError ?? getUserMeetingError) as ApiError;
    const pageLoading = isLoadingPreviousMeetings || isLoadingUserMeeting;

    return (
        <PageLayout>
            <WithLoader isLoading={pageLoading} height={"100vh"} error={pageError}>
                <>
                    <FlexContainer>
                        <div>
                            <Title>Welcome ✋🏾</Title>
                            <p
                                style={{
                                    color: theme.PrimaryGreyDark,
                                    fontSize: "14px",
                                }}
                            >
                                This is your workspace.
                            </p>
                        </div>
                        <div>
                            <Button
                                disabled={disableMeetingButton}
                                style={{ width: "100%" }}
                                onClick={() => navigateTo(appPaths.newMeetings)}
                            >
                                New Meeting <Add size="20" />
                            </Button>
                        </div>
                    </FlexContainer>
                    <Spacer space={16} />
                    <MeetingWrapper>
                        <TitleContainer>
                            <h3>Quick links</h3>
                        </TitleContainer>
                        <QuickLinksContainer>
                            <QuickLinks to="#">
                                <div className="icon">
                                    <Notepad2 size="28" variant="Bold" />
                                </div>
                                <p>Minutes</p>
                            </QuickLinks>
                            <QuickLinks to={buildPath(appPaths.agendas) as string}>
                                <div className="icon">
                                    <ClipboardText size="28" variant="Bold" />
                                </div>
                                <p>Agenda</p>
                            </QuickLinks>
                            <QuickLinks to={buildPath(appPaths.prayers) as string}>
                                <div className="icon">
                                    <BookSaved size="28" variant="Bold" />
                                </div>
                                <p>Prayer</p>
                            </QuickLinks>
                        </QuickLinksContainer>
                    </MeetingWrapper>
                    <Spacer space={16} />
                    <MeetingWrapper>
                        <TitleContainer>
                            <h3>Upcoming Meetings</h3>
                        </TitleContainer>

                        {upcomingMeeting ? (
                            <DataItem
                                key={upcomingMeeting.id}
                                onClick={() =>
                                    navigateTo(
                                        `${appPaths.getMeeting(upcomingMeeting.id.toString())}`
                                    )
                                }
                                style={{ padding: "16px" }}
                                title={
                                    <div>
                                        <ModSubText>{upcomingMeeting.title}</ModSubText>
                                        <p
                                            style={{
                                                fontSize: "11px",
                                                color: theme.PrimaryGreyMid,
                                            }}
                                        >
                                            {formatDate(upcomingMeeting.datetime)} |{" "}
                                            {upcomingMeeting.type}
                                        </p>
                                    </div>
                                }
                                rightContent={
                                    <div>
                                        <b
                                            style={{
                                                fontSize: "16px",
                                                color: "#4F5D75",
                                                fontWeight: "bolder",
                                            }}
                                        >
                                            {attachSuffix(upcomingMeeting?.number)}
                                        </b>
                                    </div>
                                }
                            />
                        ) : (
                            <div style={{ margin: "10px 0" }}>
                                <EmptySpace>
                                    <Document size="32" color="#5b6576" />
                                    <Text
                                        style={{
                                            color: "#4F5D75",
                                        }}
                                    >
                                        You don’t have any upcoming Meetings
                                    </Text>
                                </EmptySpace>
                            </div>
                        )}

                        <Spacer space={20} />
                        <TitleContainer>
                            <h3>Previous Meetings</h3>
                            {previousMeetings?.length > 2 && (
                                <Button
                                    plain
                                    size="15px"
                                    onClick={() => navigateTo(appPaths.previousMeeting)}
                                >
                                    View More
                                </Button>
                            )}
                        </TitleContainer>
                        {previousMeetings?.map((meeting) => (
                            <DataItem
                                style={{ padding: "16px" }}
                                title={
                                    <div>
                                        <ModSubText>{meeting.title}</ModSubText>
                                        <p
                                            style={{
                                                fontSize: "11px",
                                                color: theme.PrimaryGreyMid,
                                            }}
                                        >
                                            {formatDate(meeting.datetime)} | {meeting.type}
                                        </p>
                                    </div>
                                }
                                key={meeting.id}
                                onClick={() => navigateTo(`${appPaths.getPastMeeting(meeting.id)}`)}
                                rightContent={
                                    <div>
                                        <div
                                            style={{
                                                fontSize: "16px",
                                                color: "#4F5D75",
                                                fontWeight: "bolder",
                                            }}
                                        >
                                            {attachSuffix(meeting?.number)}
                                        </div>
                                    </div>
                                }
                            />
                        ))}
                    </MeetingWrapper>
                </>
            </WithLoader>
        </PageLayout>
    );
};
