import { useParams } from "react-router-dom";
import { useAuthUserNavigate } from "@/features/auth";
import { useMemo } from "react";
import { appPaths } from "@/app";
import { ApiError } from "@/types/common";
import { useGetMeetingDetailsQuery } from "@/features/meetings";
import { PageLayout, WithLoader } from "@/features/ui";
import { useUploadDocument } from "@/features/documents";

import AgendaForm from "../agenda-form/agenda-form";
import { useGetAgendaQuery, useUpdateAgendaMutation } from "../agenda-api";

export default function UpdateAgenda() {
    const { id } = useParams();
    const { data: agenda, isLoading, error } = useGetAgendaQuery(id as string);
    const [updateAgendaReq, { isLoading: isUpdating, isSuccess: isUpdated }] =
        useUpdateAgendaMutation();
    const { navigateTo } = useAuthUserNavigate();
    const { data: meeting, ...getMeetingDetailsQuery } = useGetMeetingDetailsQuery(
        agenda?.meeting.id,
        {
            skip: !agenda,
        }
    );
    const { upload, isUploading } = useUploadDocument();

    const data = useMemo(
        () => ({
            meeting,
            title: agenda?.title,
            agendaItems: agenda?.agendaItems,
            prayerItems: agenda?.prayerItems ?? [],
        }),
        [meeting, agenda]
    );

    const onSubmit = async (data) => {
        const { agendaItems, prayerItems = [], ...reqBody } = data;

        if (agendaItems?.length) {
            // eslint-disable-next-line prefer-const
            for (let index = 0; index < agendaItems.length; index++) {
                const item = agendaItems[index];
                if (item.file) {
                    const documentUrl = await upload({ file: item.file, fileName: File.name });
                    delete item.file;
                    item.documentUrl = documentUrl;
                    agendaItems[index] = item;
                }
            }
            reqBody.agendaItems = agendaItems;
        }

        reqBody.prayerItems = prayerItems.map(({ position, prayer }) => ({
            position,
            prayerId: prayer.id,
        }));
        reqBody.id = id;

        updateAgendaReq(reqBody);
    };

    const pageLoading = isLoading || getMeetingDetailsQuery.isLoading;
    const pageError = (error ?? getMeetingDetailsQuery.error) as ApiError;

    return (
        <PageLayout pageLabel="Agenda" minHeight={"95vh"}>
            <WithLoader isLoading={pageLoading} error={pageError} height="70vh">
                <AgendaForm
                    onSubmit={onSubmit}
                    data={data}
                    isEdit={true}
                    isSubmitting={isUploading || isUpdating}
                    isSubmitted={isUpdated}
                    onBack={() => navigateTo(appPaths.agendas)}
                />
            </WithLoader>
        </PageLayout>
    );
}
