import { Controller, useController } from "react-hook-form";

import { ExtInput } from "@flexisaf/flexibull2/build/forms";
import { Input as FlexiInput } from "@flexisaf/flexibull2/build/input";
import { Spacer } from "@flexisaf/flexibull2/build/layout";
import { Text } from "@flexisaf/flexibull2/build/typo";

import { theme } from "@/style";
import { BaseFormElementProps } from "./types";
import { Checkbox, TextArea } from "@flexisaf/flexibull2";

type InputProps = BaseFormElementProps & {
    ext?: boolean;
    placeholder?: string;
    [restProp: string]: unknown;
};

export function Input(props: InputProps) {
    const {
        name,
        label,
        placeholder,
        type = "text",
        defaultValue = "",
        rules = {},
        ext,
        ...restProps
    } = props;
    const { field, fieldState } = useController({ name, defaultValue, rules });

    return ext ? (
        <>
            <ExtInput
                name={name}
                label={label}
                type={type}
                placeholder={placeholder}
                onChange={field.onChange}
                value={field.value}
                {...restProps}
            />
            {fieldState.error?.message && (
                <>
                    <Spacer space="12" />
                    <Text size="1em" color={theme.PrimaryRed}>
                        {fieldState.error?.message}{" "}
                    </Text>
                </>
            )}
        </>
    ) : (
        <FlexiInput
            name={name}
            label={label}
            type={type}
            placeholder={placeholder}
            onChange={field.onChange}
            value={field.value}
            error={fieldState.error?.message}
            {...restProps}
        />
    );
}

export function CheckboxInput(props: InputProps & { form: any }) {
    const { name, label, form, ...restProps } = props;
    const {
        field: { onChange, value },
        fieldState: { error },
    } = useController({ name });

    return (
        <Controller
            name={name}
            control={form.control}
            defaultValue=""
            render={() => (
                <>
                    <Checkbox
                        label={label}
                        name={name}
                        onChange={(event) => {
                            const selectedValue = event.target.checked ? label : "";
                            onChange(selectedValue);
                            form.setValue(name, selectedValue);
                        }}
                        checked={value === label}
                        {...restProps}
                    />
                    {error && (
                        <>
                            <Spacer space="12" />
                            <Text size="1em" color={theme.PrimaryRed}>
                                {error?.message}
                            </Text>
                        </>
                    )}
                </>
            )}
        />
    );
}

export function TimeInput(props: InputProps & { form: any }) {
    const { name, label, form, defaultValue, ...restProps } = props;
    const {
        field: { onChange, value },
    } = useController({ name, defaultValue });

    return (
        <Controller
            name={name}
            control={form.control}
            defaultValue=""
            render={() => (
                <Input
                    type="datetime-local"
                    name={name}
                    value={value}
                    onChange={(event) => {
                        const selectedValue = event.target.value;
                        onChange(selectedValue);
                        form.setValue(name, selectedValue);
                    }}
                    {...restProps}
                />
            )}
        />
    );
}

type TextAreaInputProps = InputProps & {
    onPressEnter?: (evt: KeyboardEvent) => void;
};

export function TextAreaInput(props: TextAreaInputProps) {
    const { name, label, form, defaultValue, onPressEnter, ...restProps } = props;
    const {
        field,
        fieldState: { error },
    } = useController({ name, defaultValue });

    const onKeyDown = (evt: KeyboardEvent) => {
        if (evt.key === "Enter") {
            evt.stopPropagation();
            onPressEnter && onPressEnter(evt);
        }
    };

    return (
        <>
            <TextArea
                name={name}
                label={label}
                value={field.value}
                onChange={field.onChange}
                onKeyDown={onKeyDown}
                {...restProps}
            />
            {error && (
                <Text size="1em" color={theme.PrimaryRed}>
                    {error?.message}
                </Text>
            )}
        </>
    );
}
