import { theme } from "@/style";
import { Box, Text } from "@flexisaf/flexibull2";

import { Icon } from "./icon";
import { useToggle } from "react-use";

type DropdownProps = {
    title: string;
    children: React.ReactElement;
};
export default function Dropdown(props: DropdownProps) {
    const { title, children } = props;
    const [isOpen, toggleOpen] = useToggle(false);

    return (
        <Box>
            <Box
                onClick={toggleOpen}
                display="flex"
                style={{
                    justifyContent: "space-between",
                    padding: 8,
                    alignItens: "center",
                    background: isOpen ? theme.TertiarySurface : "none",
                }}
            >
                <Text bold>{title}</Text>
                <Box
                    style={{
                        transform: `rotateZ( ${isOpen ? "180deg" : "0deg"})`,
                        transition: ".4s ease-in-out",
                    }}
                >
                    <Icon icon={"arrow-down-1"} />
                </Box>
            </Box>

            <Box
                style={{
                    overflowY: "hidden",
                    padding: isOpen ? 8 : "0",
                    transition: "height 1s ease-in-out",
                    height: isOpen ? "max-content" : "0",
                    border: `.8px solid ${theme.PrimarySurface}`,
                }}
            >
                {children}
            </Box>
        </Box>
    );
}
