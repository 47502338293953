import styled from "styled-components";

export const PageHeader = styled.div`
    padding: 32px;
    border-bottom: 1px solid ${(props: any) => props.theme.PrimaryBorderColor};
    min-height: 32px;
`;

export const ProfileHeader = styled.div`
    display: grid;
    grid-template-columns: max-content auto max-content;
    padding: ${(props) => props.theme.PrimarySpace * 2}px;
    /* border-bottom: 1px solid ${(props) => props.theme.PrimaryBorderColor}; */
    align-items: center;

    & .pagetitle {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
    }
`;
