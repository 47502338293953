import { Text } from "@flexisaf/flexibull2/build/typo";
import { Box } from "@flexisaf/flexibull2/build/layout";

import theme from "@/style/theme";
import { OneOrMoreOf } from "@/types/common";
import { Privileges } from "./privileges";
import { usePrivileges } from "./use-privileges";
import { PageLayout } from "../ui";

type BasePrivliegeGuardProps = {
    privileges: OneOrMoreOf<Privileges>;
    anyOf?: boolean;
    fallback?: boolean | JSX.Element;
};

type PrivliegeGuardPropsWithChildren = BasePrivliegeGuardProps & {
    children: React.ReactElement;
};
type PrivliegeGuardPropsWithElement = BasePrivliegeGuardProps & {
    element: React.ReactElement;
};

export function PrivilegeGuard(
    props: PrivliegeGuardPropsWithChildren | PrivliegeGuardPropsWithElement
) {
    const { privileges, anyOf, fallback } = props;
    const { hasPrivilege } = usePrivileges();

    if (!hasPrivilege(privileges, anyOf)) {
        if (fallback) {
            if (fallback === true) return <DefaultFallback />;
            return fallback;
        }
        return null;
    }

    if ("element" in props) return props.element;
    return props.children;
}

const DefaultFallback = () => (
    <PageLayout>
        <Box display="flex">
            <Text color={theme.PrimaryRed}>You are not authorized to access this resource </Text>
        </Box>
    </PageLayout>
);
