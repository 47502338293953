import { useState } from "react";
import { Outlet } from "react-router-dom";
import { Text, Box, Button, Spacer, FlexiPagination, DropDown } from "@flexisaf/flexibull2";
import { theme } from "@/style";
import {
    PageLayout,
    SearchBar,
    Section,
    Table,
    WithLoader,
    Icon,
    LabelWithIcon,
} from "@/features/ui";
import { titleCase } from "@/utils/string-utils";
import { pageOptions } from "@/utils/object-utils";
import { useRoles } from "./use-roles";
import { SingleRole } from "./roles-api";
import RolesModal from "./roles-modal";

type GetColumnsArgs = { onEdit: (role: SingleRole) => void; onDelete: (id: string) => void };

const getColumns = ({ onEdit, onDelete }: GetColumnsArgs) => [
    {
        title: "Role",
        render: ({ name }) => <Text style={{ width: "240px" }}>{titleCase(name)}</Text>,
        fixed: true,
    },
    {
        title: "Description",
        render: ({ description }) => (
            <Text color={theme.PrimaryGreyDark}>{titleCase(description)}</Text>
        ),
    },
    {
        title: "",
        width: "60px",
        render: (role: SingleRole) => (
            <DropDown
                label={
                    <Button
                        icon={<Icon icon="more" />}
                        style={{
                            transform: "rotateZ(90deg)",
                            width: "min-content",
                            backgroundColor: "white",
                        }}
                    />
                }
                menuAlign="top right"
            >
                <Button onClick={() => onEdit(role)}>
                    <LabelWithIcon icon="edit-line" text="Edit Role" color={theme.PrimaryDark} />
                </Button>
                <Button onClick={() => onDelete(role.id)}>
                    <LabelWithIcon icon="trash" text="Delete Role" color={theme.PrimaryRed} />
                </Button>
            </DropDown>
        ),
    },
];

export default function Roles() {
    const { rolesData, queryState, actions, loading, page, errors, selectedRole } = useRoles();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);

    const editRole = (role: SingleRole) => {
        actions.setSelectedRole(role);
        setShowModal(true);
        setIsEdit(true);
    };
    return (
        <>
            <Outlet />
            <PageLayout pageLabel="Roles" header="Roles">
                <Box>
                    <Spacer space={16} />
                    <Section
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                        }}
                    >
                        <Box>
                            <Text bold block size="1.5em" style={{ color: theme.PrimaryGreyDark }}>
                                Roles
                            </Text>

                            <Spacer space={8} />
                            <Text> Manage roles/priviledges</Text>
                        </Box>
                        <Box>
                            <Button
                                iconRight={<Icon icon="add" color={theme.PrimaryWhite} />}
                                onClick={() => setShowModal(true)}
                            >
                                New Role
                            </Button>
                        </Box>
                    </Section>
                    <Spacer space={16} />
                    <Section>
                        <SearchBar
                            value={queryState["search-word"] ?? ""}
                            onChange={actions.searchRole}
                            placeholder="Search for Role..."
                            isSearching={loading.isUpdatingRoles}
                        />
                        <Spacer space={24} />
                        <WithLoader isLoading={loading.isLoadingRoles} error={errors.rolesError}>
                            <>
                                <Table
                                    columns={getColumns({
                                        onEdit: editRole,
                                        onDelete: actions.deleteRole,
                                    })}
                                    data={rolesData?.entities ?? []}
                                    isLoading={loading.isUpdatingRoles}
                                />
                                <Spacer space={16} />
                                <FlexiPagination
                                    pageCounts={pageOptions}
                                    total={rolesData?.total}
                                    itemsDisplayed
                                    pageSize={queryState.limit}
                                    changePageSize={({ value }) => actions.setLimit(value)}
                                    onChange={(val: number) => actions.setPage(val)}
                                    current={page}
                                    style={{ maxWidth: "100%" }}
                                />
                            </>
                        </WithLoader>
                        <RolesModal
                            setIsEdit={setIsEdit}
                            isEdit={isEdit}
                            showModal={showModal}
                            setShowModal={setShowModal}
                            selectedRole={selectedRole}
                        />
                    </Section>
                </Box>
            </PageLayout>
        </>
    );
}
