import type { ReactElement } from "react";
import {
    Layout,
    Box,
    SplitLoginLayout as SplitAuthLayout,
    Spacer,
    Text,
} from "@flexisaf/flexibull2";

import { ReactComponent as SplashImage } from "@/assets/splash.svg";

import theme from "@/style/theme";

import { AuthLayoutWrapper } from "./auth-layout-styles";

type AuthLayoutProps = {
    children: ReactElement;
};

const year = new Date().getFullYear();

export function AuthLayout(props: AuthLayoutProps) {
    return (
        <>
            <Layout theme={theme}>
                <AuthLayoutWrapper>
                    <SplitAuthLayout
                        leftFoot={leftFooter}
                        objectImage={<SplashImage width="90%" height="90%" />}
                    >
                        <Box maxWidth="400px" style={{ padding: "16px" }}>
                            <Box
                                display="flex"
                                style={{
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textAlign: "center",
                                }}
                            >
                                <Spacer space="12" />
                                <Text size="1.2em" bold block>
                                    Paperless Senate
                                </Text>
                                <Spacer space="8" />
                                <Text size="1em">
                                    Streamlining university senate proceedings for a more efficient
                                    and effective governance.
                                </Text>
                            </Box>
                            {props.children}
                        </Box>
                    </SplitAuthLayout>
                </AuthLayoutWrapper>
            </Layout>
        </>
    );
}

const leftFooter = (
    <>
        <span style={{ color: theme.SecondaryGrey }}>© Flexisaf Edusoft Limited {year}</span>
    </>
);
