import { useCallback, useMemo } from "react";

import { Loader, Button } from "@flexisaf/flexibull2";
import theme from "@/style/theme";
import { ApiError } from "@/types/common";
import { WithLoaderWrapper, ErrorOccuredWrapper } from "./with-loader.styles";

type WithLoaderProps = {
    canRender?: boolean;
    loader?: React.ReactElement;
    isLoading: boolean;
    isUpdating?: boolean;
    isUpdatingLoader?: React.ReactElement;
    retry?: Function;
    loaderSize?: number;
    retriableOn?: number[];
    children: React.ReactElement;
    error?: ApiError;
    renderError?: (error: ApiError) => JSX.Element;
    width?: string | number;
    height?: string | number;
};

const UNKNOWN_STATE = "Something unexpected occured! Can't proceed with action at this time";
const DEFAULT_LOADER_SIZE = 64;

export function WithLoader(props: WithLoaderProps) {
    const {
        isLoading,
        isUpdating,
        loaderSize = DEFAULT_LOADER_SIZE,
        isUpdatingLoader,
        error,
        children,
        retry: retryFn,
        retriableOn = [],
        loader,
        renderError,
        ...styleProps
    } = props;

    const isShowUpdatingScreen = useMemo(
        () => isUpdating && isUpdatingLoader,
        [isUpdating, isUpdatingLoader]
    );

    const renderState = useCallback(() => {
        if (isLoading) return loader ?? <Loader size={loaderSize} />;
        if (isShowUpdatingScreen) return isUpdatingLoader;
        if (error) {
            const retriable = retriableOn.includes(error.status);
            return renderError ? (
                renderError(error)
            ) : (
                <ErrorOccured message={error?.message} retry={retriable ? retryFn : null} />
            );
        }
        return <ErrorOccured message={UNKNOWN_STATE} />;
    }, [
        isLoading,
        error,
        isShowUpdatingScreen,
        isUpdatingLoader,
        loader,
        retriableOn,
        retryFn,
        renderError,
        loaderSize,
    ]);

    if (!error && !isLoading && !isShowUpdatingScreen) return children;

    return <WithLoaderWrapper {...styleProps}> {renderState()}</WithLoaderWrapper>;
}

export default function ErrorOccured(props: { message: string; retry?: Function | null }) {
    const { message, retry } = props;

    return (
        <ErrorOccuredWrapper>
            <div className="message"> {message}</div>
            {retry && (
                <Button color={theme.PrimaryRed} onClick={retry}>
                    Retry
                </Button>
            )}
        </ErrorOccuredWrapper>
    );
}
