export const APP_PREFIX = "paperless-platform";
export const APP_TITLE = "PAPERLESS PLATFORM";
export const INSTITUTION_KEY = "institutionId";
export const ACCESS_TOKEN_KEY = `srms_token`;
export const REFRESH_TOKEN_KEY = `srms_refresh_token`;
export const TOP_RIGHT = "top-right";
export const SUCCESS = "success";
export const ERROR_ALERT = "error";
export const WARNING = "warning";
export const INFO = "info";
export const CLEAR = "clear";
export const DEFAULT_DEBOUNCE_INTERVAL = 800;

export const CONNECTION_ERR_CODE = 0;
export const CONNECTION_ERR_MESSAGE = "Connection Error: Can't communicate with the server.";
export const DEFAULT_404_PAGE_MESSAGE = "Sorry! Page cannot be found";
export const DEFAULT_ENTITIES_SIZE = 20;

export const ORG_ID_HEADER_FIELD = "X-ORG-ID";
