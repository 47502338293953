import { useCallback, useMemo } from "react";
import { useAuth } from "@/features/auth";
import { OneOrMoreOf } from "@/types/common";
import { Privileges } from "./privileges";

type PrivilegesMap = Map<string, boolean>;

const isAuthorized = (
    userPrivilegesSet: PrivilegesMap,
    privileges: Privileges[],
    anyOf?: boolean
) => {
    const comparator = anyOf ? "some" : "every";
    return privileges[comparator]((privilege) => userPrivilegesSet.has(privilege));
};

export const usePrivileges = () => {
    const { user } = useAuth();

    const privilegesMap = useMemo(
        () =>
            user.currentRole.role.privileges.reduce((acc, privilege) => {
                acc.set(privilege.name, true);
                return acc;
            }, new Map()),
        [user.currentRole.role.privileges]
    );

    const hasPrivilege = useCallback(
        (privilege: OneOrMoreOf<Privileges>, anyOf?: boolean) => {
            const privilegesList = Array.isArray(privilege) ? privilege : [privilege];
            return isAuthorized(privilegesMap, privilegesList, anyOf);
        },
        [privilegesMap]
    );

    return {
        hasPrivilege,
    };
};
