import { theme } from "@/style";
import styled from "styled-components";

export const AuthLayoutWrapper = styled.div`
    & .flexi-login-right {
        box-shadow: none;
        position: relative;
        grid-column: 1;
        grid-row: 1;
        @media (max-width: 800px) {
            background: rgba(250, 250, 250, 0.5);
        }
    }
    /* Resetting top and bottom padded spaces */
    & .flexi-login-right-top {
        padding: 0px !important;
    }
    & .flexi-login-right-foot {
        padding: 0px !important;
    }

    & .flexi-login-splitlayout {
        grid-template-columns: 1fr;
        @media (min-width: 800px) {
            grid-template-columns: minmax(300px, 500px) 1fr;
        }
        @media (max-width: 800px) {
            background-size: cover;
        }
    }
    & .flexi-login-left {
        border-radius: ${(props) => props.theme.SecondaryRadius};
        grid-column: 2;
        grid-row: 1;
        background-color: ${theme.PrimaryColor};
        background-size: cover;

        @media (max-width: 800px) {
            width: 0;
            display: none;
        }

        & img {
            position: absolute;
            bottom: 60px;
            width: 100%;
            right: 0;
        }
    }
    & .flexi-login-brand {
        position: absolute !important;
        & span {
            @media (min-width: 800px) {
                color: ${(props) => props.theme.PrimaryFontColor};
            }
        }
    }
    & .flexi-login-left-foot {
        left: auto !important;
        bottom: 40px;
        right: 40px !important;
        text-align: right;
        @media (max-width: 800px) {
            padding: 20px 0px !important;
            text-align: center;
            position: static !important;
        }
    }
    & .noPadMobile {
        @media (max-width: 800px) {
            padding: 0 !important;
        }
    }
`;
