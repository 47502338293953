import { Loader } from "@flexisaf/flexibull2";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";

import { ErrorBoundary } from "@/features/ui/base";

import { store } from "@/store";
import { AppRouter } from "./app-router";

const persistedStore = persistStore(store);

export function App(): React.ReactElement {
    return (
        <ErrorBoundary>
            <Provider store={store}>
                <PersistGate persistor={persistedStore} loading={<Loader />}>
                    <AppRouter />
                </PersistGate>
            </Provider>
        </ErrorBoundary>
    );
}
