import { Box, Spacer } from "@flexisaf/flexibull2/build/layout";
import { Button } from "@flexisaf/flexibull2/build/button";
import { Text } from "@flexisaf/flexibull2/build/typo";
import { Modal, ModalClose } from "@flexisaf/flexibull2";
import { useLocation } from "react-router-dom";

import { useGetMeetingDetailsQuery } from "./meeting.api";
import moment from "moment";
import { useEffect, useState } from "react";
import { useToggle } from "react-use";

import * as yup from "yup";
import { useMeeting } from "./meeting.controller";
import { BackButton, HookForm as Form, PageLayout, WithLoader } from "@/features/ui";
import { BsFillPlusSquareFill } from "react-icons/bs";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { BiCloudUpload } from "react-icons/bi";
import { appPaths } from "@/app";
import { useAuth, useAuthUserNavigate } from "../auth";
import { attachSuffix } from "./useSuffix";
import { Privileges, usePrivileges } from "../authorization";
import {
    PageBanner,
    ModTitle,
    SubTitle,
    MeetingWrapper,
    StyledModalBody,
} from "@/features/ui/meeting.style";
import {
    DocumentPreviewerModal,
    DocumentUploader,
    ViewDocument,
    useUploadDocument,
} from "@/features/documents";

import { replaceNewLineWithBr } from "@/utils/string-utils";

const validation = yup.object().shape({
    title: yup.string().required("Meeting Title is required"),
    description: yup.string().required("Description is required"),
    type: yup.string(),
    datetime: yup.string().required("Time is required"),
    venue: yup.string().required("Venue is required"),
    number: yup.number(),
    unit: yup.string().required("Participants field is required"),
});

const Options = [
    { value: "SBC", label: "SBC" },
    { value: "SENATE", label: "SENATE" },
    { value: "FACULTY", label: "FACULTY" },
    { value: "DEPARTMENT", label: "DEPARTMENT" },
];

export const Meeting = () => {
    const location = useLocation();
    const meetId = location.pathname.substring(location.pathname.lastIndexOf("/") + 1);
    const [showModal, setShowModal] = useState(false);
    const [previewAgenda, setPreviewAgenda] = useToggle(false);
    const [previewOthers, setPreviewOthers] = useToggle(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showCancel, setShowCancel] = useState(false);
    const [message, setMessage] = useState("");
    const { hasPrivilege } = usePrivileges();
    const { upload, isUploading } = useUploadDocument();

    const form = useForm({
        resolver: yupResolver(validation),
    });
    const { navigateTo } = useAuthUserNavigate();
    const {
        initiateMeeting,
        starting,
        successfullyStarted,
        updateMeeting,
        updating,
        updateSuccess,
        cancelMeeting,
        cancelling,
        successfullyCancelled,
    } = useMeeting();
    const { data, isLoading } = useGetMeetingDetailsQuery(meetId);

    function isDateTimePassed(dateTimeString) {
        const givenDateTime = new Date(dateTimeString);

        const currentDate = new Date();
        const isToday =
            givenDateTime.getFullYear() === currentDate.getFullYear() &&
            givenDateTime.getMonth() === currentDate.getMonth() &&
            givenDateTime.getDate() === currentDate.getDate();

        if (isToday) {
            const isTimePassed = givenDateTime.getTime() < currentDate.getTime();

            if (isTimePassed) {
                return true;
            } else {
                const timeDiffMs = givenDateTime.getTime() - currentDate.getTime();
                const timeDiffMinutes = Math.floor(timeDiffMs / (1000 * 60));
                const timeDiffHours = Math.floor(timeDiffMinutes / 60);

                if (timeDiffHours > 0) {
                    return {
                        msg: `The meeting is scheduled for today in ${timeDiffHours} ${
                            timeDiffHours === 1 ? "hour" : "hours"
                        }.`,
                        status: false,
                    };
                } else {
                    return {
                        msg: `The meeting is scheduled for today in ${timeDiffMinutes} ${
                            timeDiffMinutes === 1 ? "minute" : "minutes"
                        }.`,
                        status: false,
                    };
                }
            }
        } else {
            if (givenDateTime.getTime() < currentDate.getTime() && !isToday) {
                return {
                    msg: `The meeting date has passed.`,
                    status: false,
                };
            } else {
                const timeDiffMs = givenDateTime.getTime() - currentDate.getTime();
                const timeDiffMinutes = Math.floor(timeDiffMs / (1000 * 60));
                const timeDiffHours = Math.floor(timeDiffMinutes / 60);
                const timeDiffDays = Math.floor(timeDiffHours / 24);

                return {
                    msg: `The meeting is scheduled for ${timeDiffDays} day(s) in the future.`,
                    status: false,
                };
            }
        }
    }

    const dateTime = moment(data?.datetime);

    const handleEdit = () => {
        form.reset({
            ...data,
        });
        setShowEdit(true);
    };

    useEffect(() => {
        if (successfullyStarted) {
            navigateTo(`${appPaths.getOngoingMeeting(meetId)}`);
        }
        if (successfullyCancelled) {
            navigateTo(`${appPaths.dashboard}`);
        }
        if (updateSuccess) {
            setShowEdit(false);
            setShowModal(false);
        }
    }, [updateSuccess, successfullyStarted, successfullyCancelled, navigateTo, meetId]);

    const deleteMeeting = () => {
        cancelMeeting(meetId);
    };

    const date = dateTime.format("YYYY-MM-DD");
    const time = dateTime.format("HH:mm:ss");

    function calculateETA(dateTimeString) {
        const now = moment();
        const eta = moment(dateTimeString);

        const diffMilliseconds = eta.diff(now);

        const remainingDays = Math.floor(diffMilliseconds / (1000 * 60 * 60 * 24));
        const remainingHours = Math.floor(
            (diffMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const remainingMinutes = Math.floor((diffMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
        const remainingSeconds = diffMilliseconds % 60;

        if (eta.isBefore(now)) {
            return "Time has already passed.";
        }

        return {
            days: remainingDays,
            hours: remainingHours,
            minutes: remainingMinutes,
            seconds: remainingSeconds,
        };
    }
    const eta = calculateETA(dateTime);
    const { user } = useAuth();

    const showSeconds = (eta) => {
        if (eta.days === 0 && eta.hours === 0 && eta.minutes === 0) {
            return true;
        }
        return false;
    };

    const secondsLeft = showSeconds(eta);
    const startMeeting = () => {
        const result = isDateTimePassed(data.datetime);
        // @ts-expect-error
        if (result?.status === false) {
            setShowModal(true);
            // @ts-expect-error
            setMessage(result?.msg);
            return;
        }
        initiateMeeting(meetId);
    };

    const onSubmit = async (values) => {
        const { preAgenda, otherAttachment, ...restForm } = values;

        if (preAgenda) {
            if (typeof preAgenda !== "string") {
                const preAgendaUrl = await upload({
                    file: preAgenda,
                    fileName: preAgenda.name,
                });
                restForm.preAgenda = preAgendaUrl;
            } else {
                restForm.preAgenda = preAgenda;
            }
        }

        if (otherAttachment) {
            if (typeof otherAttachment !== "string") {
                const otherAttachmentUrl = await upload({
                    file: otherAttachment,
                    fileName: otherAttachment.name,
                });

                restForm.preAgenda = otherAttachmentUrl;
            } else {
                restForm.otherAttachment = otherAttachment;
            }
        }

        const dataToSubmit = {
            ...restForm,
            departmentId: user?.department?.id,
            facultyId: user?.faculty ? user.faculty?.id : user?.department?.faculty.id,
        };
        updateMeeting(dataToSubmit);
    };

    return (
        <PageLayout>
            <WithLoader isLoading={isLoading} height={"100vh"}>
                <>
                    <MeetingWrapper>
                        <BackButton />
                        <Spacer space={16} />
                        <PageBanner>
                            <Box>
                                <Text
                                    style={{
                                        fontStyle: "normal",
                                        fontSize: "16px",
                                        color: "#626262",
                                    }}
                                >
                                    {attachSuffix(data?.number)} {"  "} {data?.title}
                                </Text>{" "}
                                <Spacer space={5} />
                                {typeof eta === "string" ? "" : <Text>Starting In </Text>}
                                <Spacer space={5} />
                                <Box>
                                    <Text>
                                        {typeof eta === "string" ? (
                                            eta
                                        ) : secondsLeft ? (
                                            <span style={{ color: "red" }}>
                                                {eta.seconds} Seconds
                                            </span>
                                        ) : (
                                            <>
                                                {eta.days} Days {eta.hours} Hours {eta.minutes}{" "}
                                                Minutes
                                            </>
                                        )}
                                    </Text>
                                </Box>
                            </Box>
                            <Box>
                                <Button
                                    color="#EBF5FF"
                                    onClick={() => setShowCancel(true)}
                                    fontColor="#4F5D75"
                                    disabled={!hasPrivilege(Privileges.CreateMeeting)}
                                >
                                    Cancel
                                </Button>
                            </Box>
                        </PageBanner>
                        <Spacer space={20} />
                        <Box
                            display="flex"
                            style={{ justifyContent: "flex-end", alignItems: "center" }}
                        >
                            <Button
                                onClick={handleEdit}
                                pale
                                color="#1890FF"
                                spaceRight="1em"
                                disabled={!hasPrivilege(Privileges.CreateMeeting)}
                                style={{ border: "none" }}
                            >
                                Edit Meeting
                            </Button>
                            <Button
                                progress={starting}
                                iconRight={<i className="flexibull-angle-right" />}
                                onClick={startMeeting}
                                color="#1890FF"
                                disabled={!hasPrivilege(Privileges.CreateMeeting)}
                            >
                                Start Meeting
                            </Button>
                        </Box>
                    </MeetingWrapper>
                    <Spacer space={10} />
                    <MeetingWrapper>
                        <Box>
                            <Box
                                style={{ borderBottom: "1px solid #d9d9d9", paddingBottom: "10px" }}
                            >
                                <ModTitle> Title</ModTitle> <Spacer space={5} />
                                <Box>
                                    <SubTitle>
                                        {" "}
                                        {attachSuffix(data?.number)} {"  "}
                                        {data?.title}
                                    </SubTitle>
                                </Box>
                            </Box>
                            <Spacer space={40} />
                            <Box
                                style={{ borderBottom: "1px solid #d9d9d9", paddingBottom: "10px" }}
                            >
                                <ModTitle> Description</ModTitle>
                                <Spacer space={5} />
                                <Box>
                                    <SubTitle>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: replaceNewLineWithBr(
                                                    data?.description || ""
                                                ),
                                            }}
                                        />
                                    </SubTitle>
                                </Box>
                            </Box>
                            <Spacer space={40} />
                            <Box
                                style={{ borderBottom: "1px solid #d9d9d9", paddingBottom: "10px" }}
                            >
                                <ModTitle size="12px"> Pre-Agenda</ModTitle> <Spacer space={5} />
                                {data?.preAgenda && (
                                    <Box>
                                        <ViewDocument onClick={() => setPreviewAgenda(true)} />
                                    </Box>
                                )}
                            </Box>
                            <Spacer space={40} />
                            <Box
                                style={{ borderBottom: "1px solid #d9d9d9", paddingBottom: "10px" }}
                            >
                                <ModTitle> Date & Time</ModTitle> <Spacer space={5} />
                                <Box>
                                    <SubTitle>
                                        {date} - {time}
                                    </SubTitle>
                                </Box>
                            </Box>
                            <Spacer space={40} />
                            <Box
                                style={{ borderBottom: "1px solid #d9d9d9", paddingBottom: "10px" }}
                            >
                                <ModTitle> Venue</ModTitle> <Spacer space={5} />
                                <Box>
                                    <SubTitle>{data?.venue}</SubTitle>
                                </Box>
                            </Box>
                            <Spacer space={40} />
                            <Box
                                style={{ borderBottom: "1px solid #d9d9d9", paddingBottom: "10px" }}
                            >
                                <ModTitle> Participants</ModTitle> <Spacer space={5} />
                                <Box>
                                    <SubTitle>{data?.participant}</SubTitle>
                                </Box>
                            </Box>
                            <Spacer space={40} />
                            {data?.ptherAttachment && (
                                <>
                                    {" "}
                                    <Box style={{ paddingBottom: "10px" }}>
                                        <ModTitle> Other Attachments</ModTitle> <Spacer space={5} />
                                        <Box>
                                            <ViewDocument onClick={() => setPreviewOthers(true)} />
                                        </Box>
                                    </Box>
                                    <Spacer space={40} />
                                </>
                            )}
                        </Box>
                    </MeetingWrapper>
                    <Modal outerclick onClose={() => setShowModal(false)} open={showModal}>
                        <StyledModalBody>
                            <div
                                style={{
                                    textAlign: "center",
                                }}
                            >
                                <Box pad="20px">
                                    <h3>{message}</h3>
                                </Box>
                            </div>
                            <div
                                style={{
                                    textAlign: "center",
                                    padding: "10px 20px",
                                }}
                            >
                                <Button spaceRight pale onClick={() => setShowModal(false)}>
                                    Close
                                </Button>
                                <Button onClick={handleEdit}>Reschedule</Button>
                                <Spacer space={20} />
                            </div>
                        </StyledModalBody>
                    </Modal>
                    <Modal outerclick onClose={() => setShowCancel(false)} open={showCancel}>
                        <StyledModalBody>
                            <div
                                style={{
                                    textAlign: "center",
                                }}
                            >
                                <Box pad="20px">
                                    <h3>Are you sure you want to cancel this meeting? </h3>
                                </Box>
                            </div>
                            <div
                                style={{
                                    textAlign: "center",
                                    padding: "10px 20px",
                                }}
                            >
                                <Button spaceRight pale onClick={() => setShowCancel(false)}>
                                    No
                                </Button>
                                <Button
                                    style={{ background: "red" }}
                                    loading={cancelling}
                                    onClick={deleteMeeting}
                                >
                                    Yes
                                </Button>
                                <Spacer space={20} />
                            </div>
                        </StyledModalBody>
                    </Modal>
                    <Modal outerclick onClose={() => setShowEdit(false)} open={showEdit}>
                        <StyledModalBody>
                            <ModalClose onClick={() => setShowEdit(false)}>&times;</ModalClose>
                            <div
                                style={{
                                    padding: "0 20px",
                                }}
                            >
                                <h3>Edit Meeting</h3>
                            </div>
                            <Form form={form} onSubmit={form.handleSubmit(onSubmit)}>
                                <div
                                    style={{
                                        padding: "20px",
                                    }}
                                >
                                    <Spacer space={39} />
                                    <Box>
                                        <Form.Input
                                            name="title"
                                            label="Meeting Title *"
                                            style={{
                                                width: "100%",
                                            }}
                                            placeholder="title"
                                        />
                                    </Box>
                                    <Spacer space={39} />
                                    <Box>
                                        <Text size="12px" bold>
                                            Description
                                        </Text>
                                        <Spacer space={5} />
                                        <Form.ControlledEditor
                                            name="description"
                                            control={form.control}
                                            defaultValue={data?.description}
                                        />
                                    </Box>
                                    <Spacer space={39} />
                                    <Text size="12px" bold>
                                        Type of meeting *
                                    </Text>
                                    <Spacer space={5} />
                                    <Box
                                        style={{
                                            display: "flex",
                                            flex: "1",
                                        }}
                                    >
                                        <Form.CheckboxInput
                                            form={form}
                                            label="Regular"
                                            name="type"
                                            round
                                            block
                                            checked={data?.type === "REGULAR"}
                                        />
                                        <Form.CheckboxInput
                                            form={form}
                                            label="Special"
                                            name="type"
                                            round
                                            block
                                            checked={data?.type === "SPECIAL"}
                                        />
                                    </Box>
                                    <Spacer space={39} />
                                    <Text size="12px" bold>
                                        Pre-agenda *
                                    </Text>
                                    <Box
                                        style={{
                                            display: "flex",
                                            flex: "1",
                                        }}
                                    >
                                        <DocumentUploader
                                            onChange={(files: File[]) =>
                                                form.setValue("preAgenda", files[0])
                                            }
                                            fileUrls={[data?.preAgenda]}
                                        >
                                            <Box pad="0 0 16px 0">
                                                <BiCloudUpload size={30} />
                                            </Box>
                                            <Box>
                                                <Text>
                                                    Browse and chose the files you want to upload
                                                    from your computer
                                                </Text>
                                            </Box>
                                            <Spacer space={20} />
                                            <Box>
                                                <BsFillPlusSquareFill color="#4F5D75" size={30} />
                                            </Box>
                                        </DocumentUploader>
                                    </Box>
                                    <Spacer space={39} />
                                    <Spacer space={20} />
                                    <Box>
                                        <label>Date</label>
                                        <Form.TimeInput
                                            label="Time *"
                                            form={form}
                                            name="datetime"
                                            style={{
                                                width: "100%",
                                            }}
                                            placeholder="time"
                                        />
                                    </Box>
                                    <Spacer space={39} />
                                    <Box>
                                        <Form.Input
                                            name="venue"
                                            form={form}
                                            label="Venue"
                                            style={{
                                                width: "100%",
                                            }}
                                            placeholder="Where will this meeting take place?"
                                        />
                                    </Box>
                                    <Spacer space={39} />
                                    <Box>
                                        <Form.Select
                                            name="unit"
                                            label="Participants *"
                                            style={{
                                                width: "100%",
                                            }}
                                            options={Options}
                                        />
                                    </Box>
                                    <Spacer space={39} />
                                    <Text bold>Other attachment</Text>
                                    <DocumentUploader
                                        onChange={(files: File[]) =>
                                            form.setValue("otherAttachment", files[0])
                                        }
                                        fileUrls={[data?.otherAttachment]}
                                    >
                                        <BiCloudUpload size={30} />
                                        <br />
                                        Browse and chose the files you want to upload from your
                                        computer
                                        <Spacer space={20} />
                                        <Box>
                                            <BsFillPlusSquareFill color="#4F5D75" size={30} />
                                        </Box>
                                    </DocumentUploader>
                                    <Spacer space={16} />
                                    <Box>
                                        <Form.Input
                                            name="number"
                                            type="number"
                                            label="Meeting Number *"
                                            style={{
                                                width: "100%",
                                            }}
                                            placeholder="number"
                                        />
                                    </Box>
                                    <Spacer size="14px" />
                                    <Button
                                        style={{
                                            width: "100%",
                                        }}
                                        progress={updating || isUploading}
                                        onClick={form.handleSubmit(onSubmit)}
                                    >
                                        Update Meeting{" "}
                                    </Button>
                                    <Spacer space={39} />
                                </div>
                            </Form>
                        </StyledModalBody>
                    </Modal>
                    <DocumentPreviewerModal
                        isOpen={previewAgenda}
                        onClose={() => setPreviewAgenda(false)}
                        url={data?.preAgenda}
                    />
                    <DocumentPreviewerModal
                        isOpen={previewOthers}
                        onClose={() => setPreviewOthers(false)}
                        url={data?.otherAttachment}
                    />
                </>
            </WithLoader>
        </PageLayout>
    );
};
