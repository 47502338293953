import { PrayerStatuses, Unit } from "@/utils/enums";
import { PrayerParams } from "./prayer-api";

// Types
export type UserDetails = {
    unit: Unit;
    faculty: {
        name: string | undefined;
        id: string | undefined;
    };
    department: {
        name: string | undefined;
        id: string | undefined;
    };
    userId: string;
};

// Values
export const defaultStatusByUnit = {
    DEPARTMENT: PrayerStatuses.Initiated,
    FACULTY: PrayerStatuses.Raised,
    SBC: PrayerStatuses.Submitted,
    SECRETARIAT: PrayerStatuses.Recommended,
};

// Utlity Functions
export const getDefaultPrayersFilter = (userDetails: UserDetails) => {
    const defaultParams: PrayerParams = {
        limit: 20,
        status: defaultStatusByUnit[userDetails.unit],
    };
    const departmentId = userDetails.department?.id;
    const facultyId = userDetails.faculty?.id;

    if (userDetails.unit === Unit.Department && departmentId) {
        defaultParams.departmentId = departmentId;
    }

    if (userDetails.unit === Unit.Faculty && facultyId) {
        defaultParams.facultyId = facultyId;
    }
    return defaultParams;
};
