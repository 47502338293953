import { useGetSRMSSessionsQuery, useGetSRSMSSubprogramsQuery } from "../prayers/prayer-api";
import { PrayerUiOptions } from "../prayers-ui-options";
import { useAuth } from "@/features/auth";

export function useResults() {
    const { data: srsmsSessionData, isLoading: isSessionsLoading } = useGetSRMSSessionsQuery();
    const { user } = useAuth();

    const { data: subProgramData, isLoading: isSubprogramsLoading } = useGetSRSMSSubprogramsQuery({
        "department-code": user?.department?.id,
    });

    const sessionOptions = getSessionOptions(srsmsSessionData);
    const subProgramOptions = convertSubProgramCodeMapToArray(subProgramData);

    return {
        sessionOptions,
        isSessionsLoading,
        subProgramOptions,
        isSubprogramsLoading,
    };
}

function getSessionOptions(arr: string[] | undefined): PrayerUiOptions[] {
    const transformedArr = arr?.map((item: string) => {
        return {
            label: `${item.split("/")[0]}/${item.split("/")[1]} Session`,
            value: item,
        };
    });
    transformedArr?.sort((a, b) => b.value.localeCompare(a.value));
    return transformedArr ?? [];
}

type SubProgramCodeMap = Record<string, string>;

function convertSubProgramCodeMapToArray(
    subprogramCodeMap: SubProgramCodeMap = {}
): PrayerUiOptions[] {
    return Object.entries(subprogramCodeMap).map(([label, value]) => ({ label, value }));
}
