import { useState } from "react";
import moment from "moment";

import { Spacer, Box } from "@flexisaf/flexibull2/build/layout";
import { Input, FlexiPagination } from "@flexisaf/flexibull2";
import { Text } from "@flexisaf/flexibull2/build/typo";
import { theme } from "@/style";
import { Calendar } from "iconsax-react";
import {
    TitleContainer,
    ModSubText,
    FilterLabelWrapper,
    IconWrapper,
    MeetingWrapper,
} from "@/features/ui/meeting.style";

import { useAuth, useAuthUserNavigate } from "../auth";
import { appPaths } from "@/app";
import { attachSuffix } from "./useSuffix";
import { MeetingFilterParams, useGetPreviousMeetingsWithSearchQuery } from "./meeting.api";
import { WithLoader, Icon, PageLayout } from "@/features/ui";
import { useQueryStateSync } from "@/lib/use-query-state-sync";
import { PaginationAdapterReturn } from "@/lib/pagination-adapter";
import { pageOptions } from "@/utils/object-utils";
import { Unit } from "@/utils/enums";
import { useUserUnitValue } from "../users";
import { DEFAULT_ENTITIES_SIZE } from "@/utils/constants";

const PreviousList = () => {
    const { user } = useAuth();
    const { navigateTo } = useAuthUserNavigate();
    const [meetingDate, setMeetingDate] = useState<Date | null>(null);
    const unitParam = useUserUnitValue({
        SECRETARIAT: Unit.Senate,
        default: user.currentRole.unit,
    });
    const queryStateSync = useQueryStateSync<MeetingFilterParams, PaginationAdapterReturn>({
        limit: DEFAULT_ENTITIES_SIZE,
        unit: unitParam,
        departmentId: user.department?.id,
        facultyId: user.faculty?.id,
    });
    const { queryState, setQueryField, page, setPage, setLimit } = queryStateSync;
    const { data: previousMeetings, isLoading } = useGetPreviousMeetingsWithSearchQuery(queryState);

    const filterByMeeting = (meetingTitle: string) => setQueryField("meetingTitle", meetingTitle);

    const filterByDate = (val: Date) => {
        const formattedDate = moment(val).format("YYYY-MM-DD");
        setMeetingDate(val);
        setQueryField("meetingDate", formattedDate);
    };

    const handleMeetingChange = (meetingNumber) => setQueryField("meetingNumber", meetingNumber);

    return (
        <PageLayout>
            <WithLoader isLoading={isLoading} height={"100vh"}>
                <>
                    <MeetingWrapper>
                        <Box>
                            <h2> Meeting History</h2>
                        </Box>
                    </MeetingWrapper>
                    <Spacer space={16} />
                    <MeetingWrapper>
                        <Box label="Submission">
                            <Input
                                type="search"
                                value={queryState.meetingTitle ?? ""}
                                onChange={(e) => filterByMeeting(e.target.value)}
                                placeholder="Search..."
                                spaceTop
                                style={{ width: "100%" }}
                            />
                            <Spacer space={20} />
                            <Box
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Box style={{ width: "44%" }}>
                                    <Input
                                        type="date"
                                        value={
                                            meetingDate
                                                ? moment(meetingDate).format("YYYY-MM-DD")
                                                : ""
                                        }
                                        onChange={(e) => filterByDate(e.target.value)}
                                        label={
                                            <FilterLabelWrapper>
                                                <IconWrapper
                                                    style={{
                                                        marginTop: "2px",
                                                    }}
                                                >
                                                    <Calendar
                                                        size="14"
                                                        color={theme.PrimaryGreyMid}
                                                    />
                                                </IconWrapper>

                                                <Text size="14px" color={theme.PrimaryGreyMid}>
                                                    Date
                                                </Text>
                                            </FilterLabelWrapper>
                                        }
                                        spaceTop
                                        style={{ width: "100%" }}
                                    />
                                </Box>
                                <Box width="48%">
                                    <FilterLabelWrapper>
                                        <IconWrapper>
                                            <Icon icon="judge" size={14} />
                                        </IconWrapper>

                                        <Text size="14px" color={theme.PrimaryGreyMid}>
                                            Meeting Number
                                        </Text>
                                    </FilterLabelWrapper>
                                    <Input
                                        type="number"
                                        style={{ width: "100%", margin: "2px" }}
                                        placeholder="Meeting Number"
                                        value={queryState.meetingNumber}
                                        onChange={(e) => handleMeetingChange(e.target.value)}
                                    />
                                </Box>
                            </Box>
                            <Spacer space={10} />
                            <Box>
                                <h3>Previous Meetings</h3>
                            </Box>

                            {previousMeetings !== undefined &&
                                previousMeetings?.entities?.map((meeting) => (
                                    <div
                                        key={meeting.id}
                                        onClick={() =>
                                            navigateTo(`${appPaths.getPastMeeting(meeting.id)}`)
                                        }
                                        style={{
                                            padding: "20px 10px 0 10px",
                                        }}
                                    >
                                        <TitleContainer>
                                            <div>
                                                <ModSubText>{meeting.title}</ModSubText>
                                                <p>
                                                    {moment(meeting.datetime).format(
                                                        "M/D/YYYY - h:mmA"
                                                    )}{" "}
                                                    | {meeting.type}
                                                </p>
                                            </div>
                                            <div>
                                                <b
                                                    style={{
                                                        fontSize: "16px",
                                                        color: "#4F5D75",
                                                        fontWeight: "bolder",
                                                    }}
                                                >
                                                    {attachSuffix(meeting?.number)}
                                                </b>
                                            </div>
                                        </TitleContainer>
                                    </div>
                                ))}
                            <FlexiPagination
                                pageCounts={pageOptions}
                                total={queryState.limit ?? 30}
                                itemsDisplayed
                                pageSize={queryState.limit}
                                changePageSize={({ value }) => setLimit(value)}
                                onChange={(val: number) => setPage(val)}
                                current={page}
                                style={{ maxWidth: "100%" }}
                            />
                        </Box>
                    </MeetingWrapper>
                </>
            </WithLoader>
        </PageLayout>
    );
};

export default PreviousList;
