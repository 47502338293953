import noop from "lodash/noop";
import { DevTool } from "@hookform/devtools";
import React, { ReactNode, useCallback } from "react";
import { FormProvider, UseFormReturn } from "react-hook-form";

import { Input, CheckboxInput, TimeInput, TextAreaInput } from "./input.component";
import { Environments } from "@/utils/enums";
import { isEnv } from "@/utils/object-utils";

import { Select } from "./select";
import { Datepicker } from "./date-picker.component";
import { HiddenInput } from "./hidden-input.component";
import { ControlledEditor } from "./rich-editor";

const isDevEnv = isEnv(Environments.Development);

type FormProps = {
    form: UseFormReturn<any>;
    onSubmit?: (data?: any) => void | Promise<void>;
    children: ReactNode;
    preventEnterKeyToSubmit?: boolean;
};

function FormComponent(props: FormProps) {
    const { form, onSubmit = noop, preventEnterKeyToSubmit, children } = props;

    const innerOnSubmit = useCallback(
        (evt: React.SyntheticEvent) => {
            evt.preventDefault();
            onSubmit();
        },
        [onSubmit]
    );

    const handleKeyDown = useCallback(
        (evt: React.KeyboardEvent<HTMLFormElement>) => {
            if (evt.key === "Enter" && !preventEnterKeyToSubmit) {
                onSubmit();
            }
        },
        [onSubmit, preventEnterKeyToSubmit]
    );

    return (
        <FormProvider {...form}>
            <form onSubmit={innerOnSubmit} onKeyDown={handleKeyDown}>
                {children}
            </form>
            {isDevEnv && <DevTool control={form.control} />}
        </FormProvider>
    );
}

export const HookForm = Object.assign(FormComponent, {
    Input,
    Select,
    HiddenInput,
    Datepicker,
    CheckboxInput,
    TimeInput,
    TextAreaInput,
    ControlledEditor,
});
