import { theme } from "@/style";
import { Box } from "@flexisaf/flexibull2";
import styled from "styled-components";

export const PreviewerWrapper = styled(Box)`
    /* position: relative; */
`;

export const RendererWrapper = styled(Box)`
    background: ${theme.SecondaryGrey};
    max-height: 80vh;
    overflow: hidden;
    width: 100%;
    margin: auto;
    /* border: 1px solid ${theme.PrimaryGreyLight}; */
`;

export const ButtonFABWrapper = styled(Box)`
    position: sticky;
    right: 4;
    display: grid;
    place-items: center;
    bottom: 4;
`;
