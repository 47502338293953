import { Notify } from "@flexisaf/flexibull2";

import { baseApi } from "@/store/base-api";
import { ERROR_ALERT } from "@/utils/constants";
import { MeetingStatus, Methods, Unit } from "@/utils/enums";
import { QueryFulfilledError, List } from "@/types/common";

export const meetingApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getMeetings: builder.query<List<Meeting>, undefined>({
            query: () => ({
                url: "/meetings/organization",
            }),
            providesTags: ["MEETING"],
        }),
        getPreviousMeetingsWithSearch: builder.query<any, MeetingFilterParams>({
            query: (params) => ({
                url: `meetings/filter?get-previous-meeting=${true}`,
                params,
            }),
        }),
        getAttendance: builder.query<any, any>({
            query: (id) => ({
                url: `/attendance/${id}`,
            }),
            providesTags: ["MEETING"],
            async onQueryStarted(_args, { queryFulfilled: qf }) {
                qf.then((res) => res).catch((err: QueryFulfilledError) =>
                    Notify(err.error?.message, { status: ERROR_ALERT })
                );
            },
        }),
        getAttendee: builder.query<any, AttendeeParams>({
            query: ({ meetingId, userId }) => ({
                url: `/attendance/attendee/${meetingId}`,
                params: {
                    "user-id": userId,
                },
            }),
            providesTags: ["MEETING"],
        }),
        getUpcomingMeetings: builder.query<any, any>({
            query: () => ({
                url: "/meetings/upcoming-meetings",
            }),
            providesTags: ["MEETING"],
        }),
        getFilteredMeetings: builder.query<List<Meeting>, MeetingFilterParams>({
            query: (params) => ({
                url: "/meetings/filter",
                params,
            }),
            providesTags: ["MEETING"],
        }),
        getPreviousMeetings: builder.query<any, any>({
            query: () => ({
                url: "/meetings/previous-meetings",
            }),
            providesTags: ["MEETING"],
        }),
        getMeetingDetails: builder.query<any, any>({
            query: (id: any) => ({
                url: `/meetings/${id}`,
            }),
            providesTags: ["MEETING"],
        }),
        getMeetingDuration: builder.query<any, any>({
            query: (id) => ({
                url: `/meetings/${id}/duration`,
            }),
            providesTags: ["MEETING"],
        }),
        createMeeting: builder.mutation<any, any>({
            query: (data: any) => ({
                url: `/meetings`,
                method: Methods.Post,
                data,
            }),
            invalidatesTags: ["MEETING"],
            async onQueryStarted(_args, { queryFulfilled: qf }) {
                qf.then((res) => Notify("Meeting Created Successfully")).catch(
                    (err: QueryFulfilledError) =>
                        Notify(err.error?.message, { status: ERROR_ALERT })
                );
            },
        }),
        startMeeting: builder.mutation<any, any>({
            query: (id: any) => ({
                url: `/meetings/${id}/start`,
                method: Methods.Put,
                id,
            }),
            invalidatesTags: ["MEETING"],
            async onQueryStarted(_args, { queryFulfilled: qf }) {
                qf.then((res) => Notify("Meeting Started Successfully")).catch(
                    (err: QueryFulfilledError) =>
                        Notify(err.error?.message, { status: ERROR_ALERT })
                );
            },
        }),
        addAttendance: builder.mutation({
            query: (data) => ({
                url: `/attendance/${data.meetingId}/attendees`,
                method: Methods.Post,
                data: data.attendees,
            }),
            invalidatesTags: ["MEETING"],
            async onQueryStarted(_args, { queryFulfilled: qf }) {
                qf.then((res) => Notify("Attendee(s) Added")).catch((err: QueryFulfilledError) =>
                    Notify(err.error?.message, { status: ERROR_ALERT })
                );
            },
        }),
        endMeeting: builder.mutation({
            query: ({ id, ...payload }) => ({
                url: `/meetings/${id}/stop?duration=${payload.duration}`,
                method: Methods.Put,
                payload,
            }),
            invalidatesTags: ["MEETING"],
            async onQueryStarted(_args, { queryFulfilled: qf }) {
                qf.then((res) => Notify("Meeting Ended Successfully")).catch(
                    (err: QueryFulfilledError) =>
                        Notify(err.error?.message, { status: ERROR_ALERT })
                );
            },
        }),
        pauseMeeting: builder.mutation({
            query: ({ id }) => ({
                url: `/meetings/${id}/pause`,
                method: Methods.Patch,
            }),
            invalidatesTags: ["MEETING"],
        }),
        resumeMeeting: builder.mutation({
            query: ({ id }) => ({
                url: `/meetings/${id}/resume`,
                method: Methods.Patch,
            }),
            invalidatesTags: ["MEETING"],
        }),
        cancelMeeting: builder.mutation<any, any>({
            query: (id) => ({
                url: `/meetings/${id}`,
                method: Methods.Delete,
                id,
            }),
            invalidatesTags: ["MEETING"],
            async onQueryStarted(_args, { queryFulfilled: qf }) {
                qf.then((res) => Notify("Meeting Cancelled Successfully")).catch(
                    (err: QueryFulfilledError) =>
                        Notify(err.error?.message, { status: ERROR_ALERT })
                );
            },
        }),
        updateMeeting: builder.mutation<any, any>({
            query: ({ id, ...data }) => ({
                url: `/meetings/${id}`,
                method: Methods.Put,
                data,
            }),
            invalidatesTags: ["MEETING"],
            async onQueryStarted(_args, { queryFulfilled: qf }) {
                qf.then((res) => Notify("Meeting Updated Successfully")).catch(
                    (err: QueryFulfilledError) =>
                        Notify(err.error?.message, { status: ERROR_ALERT })
                );
            },
        }),
        uploadMinute: builder.mutation<any, MinuteRequest>({
            query: (data) => ({
                url: "/minute",
                method: Methods.Post,
                data,
            }),
            invalidatesTags: ["MEETING"],
            async onQueryStarted(_args, { queryFulfilled: qf }) {
                qf.then(() => Notify("Minute Uploaded Successfully")).catch(
                    (err: QueryFulfilledError) =>
                        Notify(err.error?.message, { status: ERROR_ALERT })
                );
            },
        }),
        getMeetingMinute: builder.query<Minute, string>({
            query: (id) => ({
                url: `minute/meetings/${id}`,
            }),
        }),
    }),
});

export interface AttendeeParams {
    meetingId?: number;
    userId?: string;
}

interface MinuteRequest {
    meetingId: string;
    attachmentUrl: string;
    organization: string;
}

export interface Minute {
    attachmentUrl: string;
    createdAt: string;
    id: number;
    organization: string;
    updatedAt: string;
}

export interface Meeting {
    createdAt: string;
    startTime: string;
    datetime: string;
    departmentId: string;
    description: string;
    facultyId: string;
    id: number;
    number: number;
    organization: string;
    otherAttachment: string;
    preAgenda: string;
    status: string;
    title: string;
    type: string;
    unit: string;
    updatedAt: string;
    venue: string;
    entities: [];
}

export interface MeetingFilterParams {
    limit?: number;
    offset?: number;
    status?: MeetingStatus;
    unit?: Unit;
    roleId?: string;
    meetingTitle?: string;
    meetingDate?: string;
    meetingNumber?: string;
    departmentId?: string;
    facultyId?: string;
}

export const {
    useGetMeetingsQuery,
    useGetMeetingDetailsQuery,
    useCreateMeetingMutation,
    useGetUpcomingMeetingsQuery,
    useGetMeetingDurationQuery,
    useStartMeetingMutation,
    usePauseMeetingMutation,
    useResumeMeetingMutation,
    useGetPreviousMeetingsQuery,
    useGetFilteredMeetingsQuery,
    useUpdateMeetingMutation,
    useCancelMeetingMutation,
    useEndMeetingMutation,
    useGetAttendanceQuery,
    useAddAttendanceMutation,
    useGetPreviousMeetingsWithSearchQuery,
    useUploadMinuteMutation,
    useGetMeetingMinuteQuery,
    useGetAttendeeQuery,
} = meetingApi;
