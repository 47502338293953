import { StyledBox, StyledSpacer, ModTitle, SubTitle } from "@/features/ui/meeting.style";
import { Box, Spacer } from "@flexisaf/flexibull2";
import { useGetMeetingDetailsQuery } from "./meeting.api";
import { UseAttendanceProps } from "./attendance.controller";
import moment from "moment";
import { replaceNewLineWithBr } from "@/utils/string-utils";
import { memo } from "react";
import DocumentViewerButton from "./document-viewer-button";

export const MeetingRoom = memo(({ meetId }: UseAttendanceProps) => {
    const { data } = useGetMeetingDetailsQuery(meetId);
    const dateTime = moment(data?.datetime);
    const date = dateTime.format("YYYY-MM-DD");
    const time = dateTime.format("HH:mm:ss");
    return (
        <>
            <Spacer space={40} />
            <StyledBox>
                <ModTitle>Title</ModTitle>
                <StyledSpacer />
                <Box>
                    <SubTitle>{data?.title}</SubTitle>
                </Box>
            </StyledBox>
            <StyledSpacer space={40} />
            <StyledBox>
                <ModTitle> Description</ModTitle>
                <StyledSpacer />
                <Box>
                    <SubTitle>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: replaceNewLineWithBr(data?.description || ""),
                            }}
                        />
                    </SubTitle>
                </Box>
            </StyledBox>
            <StyledSpacer space={40} />
            <StyledBox>
                <ModTitle> Pre-Agenda</ModTitle>
                <StyledSpacer />
                <Box>
                    {data?.preAgenda ? (
                        <DocumentViewerButton documentUrl={data.preAgenda} />
                    ) : (
                        <SubTitle>No pre-agenda document available</SubTitle>
                    )}
                </Box>
            </StyledBox>
            <StyledSpacer space={40} />
            <StyledBox>
                <ModTitle> Date & Time</ModTitle>
                <StyledSpacer />
                <Box>
                    <SubTitle>
                        {date} - {time}
                    </SubTitle>
                </Box>
            </StyledBox>
            <StyledSpacer space={40} />
            <StyledBox>
                <ModTitle> Venue</ModTitle>
                <StyledSpacer />
                <Box>
                    <SubTitle>{data?.venue}</SubTitle>
                </Box>
            </StyledBox>
            <StyledSpacer space={40} />
            <StyledBox>
                <ModTitle> Participants</ModTitle>
                <StyledSpacer />
                <Box>
                    <SubTitle>{data?.participant}</SubTitle>
                </Box>
            </StyledBox>
            <StyledSpacer space={40} />
            <StyledBox>
                <ModTitle> Other Attachments</ModTitle>
                <StyledSpacer />
                <Box>
                    {data?.otherAttachment ? (
                        <DocumentViewerButton documentUrl={data.otherAttachment} />
                    ) : (
                        <SubTitle>No other document available</SubTitle>
                    )}
                </Box>
            </StyledBox>
            <StyledSpacer space={40} />
        </>
    );
});
