import { useAuth } from "@/features/auth";
import { Unit } from "@/utils/enums";

type Configuration = {
    [Unit.Department]?: any;
    [Unit.Faculty]?: any;
    [Unit.Sbc]?: any;
    [Unit.Secretariat]?: any;
    [Unit.Senate]?: any;
    default?: any;
};

export function useUserUnitValue(configuration: Configuration) {
    const { user } = useAuth();

    // eslint-disable-next-line prefer-const
    for (let unit in configuration) {
        if (unit === user.currentRole.unit) {
            return configuration[unit];
        }
    }

    if (configuration.default) return configuration.default;
}
