import { useToggle } from "react-use";
import { useEffect } from "react";
import { Text, Box, Button, Spacer } from "@flexisaf/flexibull2";

import { HookForm as Form, Section, DropContainer } from "@/features/ui";
import { Icon } from "@/features/ui/base/icon";
import { DragDataItem } from "@/features/ui/base/drag-data-field/drag-data-field";
import { theme } from "@/style";
import { useResponsiveValue } from "@/lib/use-responsive-value";
import { hasItems } from "@/utils/object-utils";

import { UseAgendaFormProps, useAgendaForm } from "./use-agenda-form";
import AddPrayerItemView from "./add-prayer-item-view";
import { AddAgendaItemModal } from "./add-agenda-item-modal";
import { getDataItemTextProps } from "../utils";

type AgendaData = UseAgendaFormProps;
type AgendaFormProps = {
    onSubmit: (data) => void | Promise<void>;
    isSubmitting: boolean;
    isUpdating?: boolean;
    data: AgendaData;
    isEdit?: boolean;
    isSubmitted: boolean;
    onBack: () => void;
};

export default function AgendaForm(props: AgendaFormProps) {
    const { onSubmit, onBack, data, isSubmitting, isUpdating, isEdit, isSubmitted } = props;

    const { form, title, addedItems, agendaItems, removedItems, actions, meeting, user } =
        useAgendaForm(data);
    const [showAddPrayerView, toggleShowAddPrayerView] = useToggle(false);
    const [showAddAgendaItemModal, toggleShowAddAgendaItemModal] = useToggle(false);

    useEffect(() => {
        if (isSubmitted) onBack();
    }, [isSubmitted, onBack]);

    const submitText = isEdit ? "Update" : "Create";
    const submitTextLoading = isEdit ? "Updating" : "Creating";

    const buttonWrapperJustify = useResponsiveValue({
        sm: "space-between",
        md: "flex-end",
    }) as string;

    const hasRemovedItems = hasItems(removedItems);

    return (
        <>
            <AddPrayerItemView
                isOpen={showAddPrayerView}
                onClose={() => toggleShowAddPrayerView(false)}
                onAddItem={actions.addPrayerItem}
                onAddItems={actions.addPrayerItems}
                removedItems={removedItems}
                meetingId={meeting?.id}
            />

            {/* Short circuiting to clear document uploader state when modal is closed  */}
            {showAddAgendaItemModal && (
                <AddAgendaItemModal
                    isOpen={showAddAgendaItemModal}
                    targetPosition={agendaItems?.length ?? 0}
                    onClose={() => toggleShowAddAgendaItemModal(false)}
                    onSubmit={actions.addAgendaItem}
                />
            )}

            <Box>
                <Section>
                    <Box>
                        <Text bold block size="1.5em" style={{ color: theme.PrimaryGreyDark }}>
                            {isEdit ? "Edit" : "New"} Agenda
                        </Text>
                        <Spacer space={8} />
                        <Text> {meeting.title}</Text>
                    </Box>
                </Section>
                <Spacer space={16} />
                <Section
                    style={{
                        minHeight: "75vh",
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                    }}
                >
                    <Box>
                        <Form form={form}>
                            <Spacer space={16} />
                            <Form.Input
                                name="title"
                                defaultValue={title}
                                label="Agenda Title *"
                                style={{ width: "100%" }}
                            />
                            <Spacer space={24} />

                            {isUpdating && (
                                <>
                                    <Box display="flex" style={{ justifyContent: "flex-end" }}>
                                        <Text size={theme.FontSizes.sm} color={theme.PrimaryGrey}>
                                            <i> Updating List...</i>
                                        </Text>
                                    </Box>
                                    <Spacer space={8} />
                                </>
                            )}
                            <Text block>Agenda Items</Text>
                            <hr color={theme.SecondaryGrey} />
                            <DropContainer onDrop={actions.onDropAgendaItem}>
                                {agendaItems.map((item, index) => {
                                    let caption = "";
                                    if ("documentUrl" in item || "file" in item) {
                                        caption = "Has attachment";
                                    }

                                    return (
                                        <DragDataItem
                                            key={index}
                                            index={index}
                                            draggableId={index.toString()}
                                            dataItemProps={(_, snapshot) => ({
                                                leftContent: (
                                                    <span>
                                                        <Icon
                                                            icon="menu-lines"
                                                            color={
                                                                snapshot.isDragging
                                                                    ? theme.PrimaryGrey
                                                                    : theme.SecondaryGrey
                                                            }
                                                        />
                                                    </span>
                                                ),
                                                subtitle: item.title,
                                                caption,
                                                rightContent: (
                                                    <span
                                                        onClick={() =>
                                                            actions.removeAgendaItem(item.position)
                                                        }
                                                    >
                                                        <Icon icon="minus" />
                                                    </span>
                                                ),
                                            })}
                                        />
                                    );
                                })}
                            </DropContainer>

                            <Spacer space={16} />
                            <Box display="flex" style={{ justifyContent: "flex-end" }}>
                                <Button
                                    plain
                                    iconRight={<Icon icon="add" color={theme.PrimaryColor} />}
                                    onClick={toggleShowAddAgendaItemModal}
                                >
                                    Add Agenda Item
                                </Button>
                            </Box>

                            <Spacer space={24} />
                            <Text block>Prayer Items</Text>
                            <hr color={theme.SecondaryGrey} />
                            <DropContainer onDrop={actions.onDropPrayerItem}>
                                {addedItems.map(({ prayer }, index) => (
                                    <DragDataItem
                                        key={prayer.id}
                                        index={index}
                                        draggableId={prayer.id.toString()}
                                        dataItemProps={(_, snapshot) => ({
                                            ...getDataItemTextProps(prayer, user),
                                            leftContent: (
                                                <span>
                                                    <Icon
                                                        icon="menu-lines"
                                                        color={
                                                            snapshot.isDragging
                                                                ? theme.PrimaryGrey
                                                                : theme.SecondaryGrey
                                                        }
                                                    />
                                                </span>
                                            ),
                                            subtitle: prayer.title,
                                            rightContent: (
                                                <span
                                                    onClick={() =>
                                                        actions.removePrayerItem(prayer.id)
                                                    }
                                                >
                                                    <Icon icon="minus" />
                                                </span>
                                            ),
                                        })}
                                    />
                                ))}
                            </DropContainer>
                        </Form>

                        <Spacer space={16} />

                        {hasRemovedItems && (
                            <Box display="flex" style={{ justifyContent: "flex-end" }}>
                                <Button
                                    plain
                                    iconRight={<Icon icon="add" color={theme.PrimaryColor} />}
                                    onClick={toggleShowAddPrayerView}
                                >
                                    Add Removed Item(s)
                                </Button>
                            </Box>
                        )}
                        <Spacer space={24} />
                    </Box>

                    <Box display="flex" style={{ justifyContent: buttonWrapperJustify }}>
                        <Button pale onClick={onBack} spaceRight width={theme.ButtonDesktopWidth}>
                            Back
                        </Button>
                        <Button
                            iconRight={<Icon icon="add" color={theme.PrimaryWhite} />}
                            onClick={form.handleSubmit(onSubmit)}
                            progress={isSubmitting}
                            width={theme.ButtonDesktopWidth}
                        >
                            {isSubmitting ? submitTextLoading : submitText}
                        </Button>
                    </Box>
                </Section>
            </Box>
        </>
    );
}
