import { createContext, useContext, useState, useEffect } from "react";
import { Box, Checkbox, Button, Text, Spacer } from "@flexisaf/flexibull2";

import { DataItem, Modal } from "@/features/ui";
import { ItemsIdHash } from "./use-agenda-form";
import { theme } from "@/style";
import { Prayer, PrayerCreate } from "@/features/prayers";
import { useAuth } from "@/features/auth";
import { getDataItemTextProps } from "../utils";
import { PrayerItem } from "../agenda-api";

type AddItemViewProps = {
    removedItems: PrayerItem[];
    onClose: () => void;
    isOpen: boolean;
    onAddItem: (item: PrayerItem) => void;
    onAddItems: (val: ItemsIdHash) => void;
    meetingId: number;
};

enum Views {
    Default = "default",
    AddPrayerItem = "add-item",
    CreateNewPrayer = "create-new-prayer",
}

const Context = createContext<any>({});

export default function AddPrayerItemView(props: AddItemViewProps) {
    const { removedItems, isOpen, onClose, onAddItems, onAddItem, meetingId } = props;
    const { user } = useAuth();
    const [currView, setView] = useState(Views.Default);

    return (
        <Context.Provider
            value={{
                currView,
                setView,
                isOpen,
                onClose,
                onAddItem,
                onAddItems,
                removedItems,
                meetingId,
                user,
            }}
        >
            <AddPrayerItemModal />
        </Context.Provider>
    );
}

function AddPrayerItemModal() {
    const { removedItems, isOpen, onClose, onAddItems, user } = useContext(Context);
    const [idHash, setIdHash] = useState<{ [id: string]: boolean }>({});

    useEffect(() => {
        if (removedItems) {
            const newIdHash = {};
            removedItems.forEach(({ id }) => {
                newIdHash[id] = false;
            });
            setIdHash(newIdHash);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const togglePrayerItem = (id: number) => {
        setIdHash((prev) => ({ ...prev, [id]: !prev[id] }));
    };

    const handleAddPrayerItems = () => {
        onAddItems(idHash);
        setIdHash({});
        onClose();
    };

    return (
        <Modal isOpen={isOpen} title="Available Prayers" onClose={onClose}>
            <Box>
                {removedItems.map((item) => (
                    <Box
                        key={item.id}
                        style={{
                            padding: "1em 0",
                            background: "white",
                            borderBottom: theme.Border.light,
                        }}
                    >
                        <DataItem
                            {...getDataItemTextProps(item.prayer, user)}
                            subtitle={item.prayer.title}
                            rightContent={
                                <span onClick={() => togglePrayerItem(item.prayer.id)}>
                                    <Checkbox checked={idHash[item.prayer.id]} />
                                </span>
                            }
                        />
                    </Box>
                ))}
                <Spacer space={32} />

                <Button style={{ width: "100%" }} onClick={handleAddPrayerItems}>
                    Add Prayers
                </Button>
            </Box>
        </Modal>
    );
}

export function CreatePrayerItemModal() {
    const { onClose, onAddItem, isOpen, meetingId } = useContext(Context);

    const handlePrayerCreated = (prayerItem: Prayer) => {
        onAddItem(prayerItem);
        onClose();
    };
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <Box>
                <PrayerCreate
                    onPrayerCreated={handlePrayerCreated}
                    meetingId={meetingId}
                    showBackButton={false}
                />
            </Box>
        </Modal>
    );
}

export function DefaultViewModal() {
    const { onClose, setView, isOpen } = useContext(Context);
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <Box style={{ textAlign: "center" }}>
                <Text bold size="22px">
                    How do you intend to add prayer?
                </Text>

                <Spacer space={16} />

                <Box display="flex" style={{ flexDirection: "column", gap: "1em" }}>
                    <Button pale onClick={() => setView(Views.AddPrayerItem)}>
                        From Existing
                    </Button>
                    <Button onClick={() => setView(Views.CreateNewPrayer)}>Create New</Button>
                </Box>
            </Box>
        </Modal>
    );
}
