import Table from "rc-table";
import styled, { css } from "styled-components";

import { theme } from "@/style";
import { SortDirection } from "@/utils/enums";
import { MaybeUndefined } from "@/types/common";

type StyledTableProps = {
    isLoading?: boolean;
    isUpdating?: boolean;
    cellPadding?: string;
    cellBg?: string;
    hoverColor?: boolean | string;
};

export const StyledTable = styled(Table)<StyledTableProps>`
    ${(p) =>
        (p.isLoading ?? p.isUpdating) &&
        css`
            opacity: 0.4 !important;
        `}

    .rc-table-cell {
        background-color: ${(p) => p.cellBg ?? "white"} !important;
        padding: ${(p) => p.cellPadding ?? "1em"} !important;
        :hover {
            background-color: none;
        }
    }
`;

export const StyledTitleCell = styled.div<{ fieldSortDirection: MaybeUndefined<SortDirection> }>`
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
        opacity: 0.8;
    }

    & .title {
        margin-right: 0.5em;
    }

    & .sort-icons-wrapper {
        display: flex;
        flex-flow: column nowrap;
        & svg {
            opacity: 0.4;
            width: 0.8em;
            height: 0.8em;
            transition: 0.4 opacity ease-in-out;
        }

        ${({ fieldSortDirection }) => {
            if (!fieldSortDirection) return "";
            const activeIndex = fieldSortDirection === SortDirection.Asc ? 1 : 2;
            return css`
                & svg:nth-child(${activeIndex}) {
                    opacity: 1;
                }
            `;
        }}
    }
`;

export const StyledActionsWrapper = styled.div`
    display: flex;
`;

export const StyledActionItem = styled.div<{ disabled: boolean }>`
    cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    opacity: ${(p) => (p.disabled ? 0.6 : 1)};
    width: 32px;
    height: 32px;
    border-radius: 100%;

    &:hover {
        background-color: ${(p) => (p.disabled ? "none" : theme.PrimaryInputOutline)};
    }

    & > .icon svg {
        width: 16px;
        height: 16px;
    }
`;
