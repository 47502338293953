import { Box, Text } from "@flexisaf/flexibull2";
import { theme } from "@/style";

type FieldProps = {
    title: string;
    value: string | number;
    flexAlign?: "center" | "flex-end" | "flex-start";
    titleStyles?: React.CSSProperties;
    valueStyles?: React.CSSProperties;
};

export function Field(props: FieldProps) {
    const { title, value, flexAlign = "flex-start", titleStyles = {}, valueStyles = {} } = props;
    return (
        <Box display="flex" style={{ flexDirection: "column", flexGap: 8, alignItems: flexAlign }}>
            <Text bold size="1.5em" style={{ fontWeight: 20, ...valueStyles }}>
                {value}
            </Text>
            <Text color={theme.PrimaryGrey} style={titleStyles}>
                {title}
            </Text>
        </Box>
    );
}
