import { theme } from "@/style";
import styled from "styled-components";

type NetworkIndicatorWrapperProps = {
    isOnline: boolean;
};
export const NetworkIndicatorWrapper = styled.div<NetworkIndicatorWrapperProps>`
    position: fixed;
    z-index: 9000;
    bottom: 0;
    left: 0;
    width: 100vw;
    padding: 0.5em 1em;
    text-align: center;
    background: ${(p) => (p.isOnline ? theme.PrimaryGreen : "black")};
    color: ${(p) => (p.isOnline ? "white" : theme.PrimaryRed)};
`;
