import { ReactNode, useState, useEffect } from "react";
import Dropzone, { FileRejection } from "react-dropzone";

import { Text, Box, Spacer, Button, Tag } from "@flexisaf/flexibull2";

import { DropzoneContainer, Preview, PreviewInner } from "./styles";

import { theme } from "@/style";
import { Icon } from "../../ui/base/icon";
import { getFileDetailsFromUrl } from "@/utils/string-utils";

type DocumentUploaderProps = {
    children: ReactNode;
    onChange: (files: File[]) => void;
    attachedError?: string | undefined;
    fileUrls?: string[] | null;
};

const acceptedFileTypes = {
    "image/png": [".png", ".jpg", ".jpeg"],
    "application/pdf": [".pdf"],
};

export const DocumentUploader = ({
    children,
    onChange,
    attachedError,
    fileUrls = null,
}: DocumentUploaderProps) => {
    const [filePreviews, setFilePreviews] = useState<
        Array<{ name: string; type: string; preview: string }>
    >([]);
    const [fileErrors, setFileErrors] = useState<string[]>([]);
    const [fileUrlStates, setFileUrlStates] = useState<string[]>([]);

    useEffect(() => {
        if (attachedError !== undefined) {
            setFileErrors([attachedError]);
        }
    }, [attachedError]);

    const handleDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
        if (fileRejections.length > 0) {
            // Handle file rejections (e.g., file size, file type restrictions)
            const errorMessages = generateFileErrorMessages(fileRejections);
            setFilePreviews([]);
            setFileErrors(errorMessages);
        } else {
            // Display file previews

            const newFilePreviews = acceptedFiles.map((file) => ({
                name: file.name,
                preview: URL.createObjectURL(file),
                type: file.type,
            }));
            onChange(acceptedFiles); // handles parent for when files have been dropped
            setFileErrors([]);
            setFilePreviews(newFilePreviews);
            setFileUrlStates([]);
        }
    };

    const generateFileErrorMessages = (fileRejections: FileRejection[]) => {
        const errorMessages: string[] = [];
        fileRejections.forEach((rejection) => {
            rejection.errors.forEach((error) => {
                errorMessages.push(error.message);
            });
        });
        return errorMessages;
    };

    const handleRemoveFile = (index: number): void => {
        const updatedFilePreviews = filePreviews.filter((file, i) => i !== index);
        setFilePreviews(updatedFilePreviews);
        const updatedFileUrlStates = fileUrlStates.filter((url, i) => i !== index);
        setFileUrlStates(updatedFileUrlStates);
        setFileErrors([]);
    };

    const useEffectDependency = fileUrls?.[0];

    useEffect(() => {
        if (fileUrls) {
            setFilePreviews([]);
            setFileErrors([]);
            setFileUrlStates(fileUrls);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [useEffectDependency]);

    return (
        <DropzoneContainer>
            <Dropzone
                onDrop={handleDrop}
                accept={acceptedFileTypes}
                maxSize={50 * 1024 * 1024} // 50MB in bytes
                multiple
            >
                {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        {children}
                    </div>
                )}
            </Dropzone>
            {filePreviews.map((filePreview, index) => (
                <div key={index}>
                    <Text>{filePreview.name}</Text>
                    <Spacer space="5" />
                    <Box>
                        {filePreview.type.includes("image") ? (
                            <Preview>
                                <PreviewInner
                                    src={filePreview.preview}
                                    alt="File Preview"
                                ></PreviewInner>
                            </Preview>
                        ) : (
                            <embed
                                src={filePreview.preview}
                                width="80px"
                                height="80px"
                                type="application/pdf"
                            />
                        )}
                    </Box>
                    <Spacer space="10" />
                    <Button plain onClick={() => handleRemoveFile(index)}>
                        Remove File
                    </Button>
                </div>
            ))}
            {fileUrls &&
                fileUrlStates[0] !== undefined &&
                fileUrlStates?.map((fileUrl, index) => {
                    return (
                        <div
                            key={index}
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                            }}
                        >
                            <Text>{getFileDetailsFromUrl(fileUrl).name}</Text>
                            <Spacer space="5" />
                            <Box>
                                <Tag
                                    round={false}
                                    style={{ border: "none", padding: "24px" }}
                                    color={theme.PrimaryGrey}
                                >
                                    <Icon
                                        icon="document-with-text-folded"
                                        size={24}
                                        color={theme.PrimaryGrey}
                                    />
                                </Tag>
                            </Box>
                            <Spacer space="10" />
                            <Button onClick={() => handleRemoveFile(index)} plain>
                                Remove File
                            </Button>
                        </div>
                    );
                })}
            {fileErrors.length > 0 && (
                <Box>
                    {fileErrors.map((error, index) => (
                        <Text key={index} size="12px" color={theme.PrimaryRed}>
                            {error}
                        </Text>
                    ))}
                </Box>
            )}
        </DropzoneContainer>
    );
};
