import { useState } from "react";
import styled from "styled-components";
import { Spacer, Box, Text } from "@flexisaf/flexibull2";
import { ViewportWidth } from "@/utils/enums";
import { theme } from "@/style";
// import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

interface ModalProps {
    isOpen: boolean;
}
interface TagProps {
    color?: string;
}

const TitleContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
`;

const Tag = styled.div<TagProps>`
    padding: 1px 10px;
    margin-top: 2px;
    // width: 58px;
    // height: 16px;

    /* Others/Pending */

    background: ${({ color }) => color ?? "#ffeac1"};
    border-radius: 15px;
`;

export const ModalWrapper = styled.div<ModalProps>`
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #ffffff;
    padding: 20px;
    box-shadow: ${({ isOpen }) => (isOpen ? "0px -5px 15px rgba(0, 0, 0, 0.3)" : "none")};
    border-top-left-radius: ${({ isOpen }) => (isOpen ? "20px" : "none")};
    border-top-right-radius: ${({ isOpen }) => (isOpen ? "20px" : "none")};
    transform: translateY(${({ isOpen }) => (isOpen ? "0%" : "100%")});
    transition: transform 0.3s ease-in-out;
    width: 100%;
    z-index: 2; /* Add this line */

    overflow-y: auto;
    max-height: 98vh; /* Add these two lines */

    // @media screen and (min-width: 768px) {
    //     position: fixed;
    //     // top: ${({ isOpen }) => (isOpen ? "50%" : "50%")};
    //     // left: ${({ isOpen }) => (isOpen ? "50%" : "-100%")};
    //     // transform: translate(-50%, -50%);
    //     // width: 300px;
    //     box-shadow: none;
    //     // background-color: rgb(242, 244, 246);
    //     border-top-right-radius: ${({ isOpen }) => (isOpen ? "0" : "20px 20px 0 0")};
    //     border-top-left-radius: ${({ isOpen }) => (isOpen ? "0" : "20px 20px 0 0")};
    //     border-radius: 5px;
    // }
    @media screen and (min-width: 768px) {
        left: ${({ isOpen }) => (isOpen ? "0" : "-100%")}; // initially off-screen
        top: 0;
        bottom: 0;
        width: 400px;
        transform: translateX(${({ isOpen }) => (isOpen ? "0%" : "-100%")});
        transition: transform 0.3s ease-in-out, left 0.3s ease-in-out;
        box-shadow: none;
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0;

        max-height: 100vh; /* Add these two lines */
    }
`;

export const DropzoneContainer = styled.div`
    border: 1px dashed #d0d5dd;
    padding: 12px 24px;
    text-align: center;
    cursor: pointer;
    margin: 10px 0 20px 0;
    // font-weight: 300;
    width: 100%;
    background: #ffffff;
    /* Neutral / 200 */
    color: #191d23;
    // border: 1px dashed #d0d5dd;
    // font-size: 16px;
    border-radius: 4px;

    // font-family: Manrope;
    font-size: 14px;
    font-weight: 300;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
`;

export const ModalToggleButton = styled.button`
    position: fixed;
    bottom: 20px;
    right: auto;
    left: 20px;
    top: 20px;
    // background-color: #ccc;
    // border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    font-size: 26px;

    &:hover {
        background-color: #bbb;
    }

    &:active {
        background-color: #aaa;
    }

    @media screen and (min-width: 768px) {
        right: auto;
        left: 20px;
        top: 20px;
    }
`;

export const AppWrapper = styled.div<{ isModalOpen: boolean }>`
    position: relative; /* Make sure the overlay is positioned relative to the container */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

    @media screen and (min-width: 768px) {
        background-color: rgb(242, 244, 246);
    }

    /* Add overlay when menu is open */
    &::before {
        content: "";
        display: ${({ isModalOpen }) => (isModalOpen ? "block" : "none")};
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #2d314282; /* Semi-transparent black color */
        z-index: 1;
    }
`;

export const ModalDot = styled.div`
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;

    .dot {
        background: #4f5d75;
        border-radius: 48px;
        // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        color: #fff;
        width: 40px;
        height: 4px;
        margin-top: -12px;
    }
`;
export const List = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const ListItem = styled.li`
    padding: 8px;
    // background-color: #f8f8f8;
    border-bottom: 1px solid #d9d9d9;
    // font-family: "Manrope";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #2d3142;
    &:hover {
        color: #1890ff;
    }
`;

export const UserRole = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 27px 16px;
    gap: 10px;

    width: 100%;
    margin-top: 30px;
    color: white;
    font-weight: 300;

    background: ${theme.PrimaryColor};
    border-radius: 10px;
    @media screen and (min-width: ${ViewportWidth.sm}px) {
        background: white;
        padding: 16px 12px;
        color: ${theme.PrimaryColor};
    }
`;

export const But1 = styled.div`
    padding: 16px 10px;

    color: #fff;
    height: 52px;
    // left: 20px;
    // top: 177px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;

    font-family: "Manrope";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;

    /* Primary/Main

Links and other tags
*/
    background: #1890ff;
    border-radius: 4px;
`;

export const Title = styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    /* identical to box height */

    /* Text/Dark */

    color: #2d3142;
`;

const QuickLinks = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px 3px;
    // margin: 0 5px;
    // gap: 2px;

    width: 60px;
    height: 75px;

    /* Surface/tag */

    background: #ebf5ff;
    border-radius: 10px;

    /* Inside auto layout */

    // flex: none;
    // order: 0;
    // flex-grow: 0;

    .icon {
        width: 25px;
        height: 25px;

        background: #dfdfdf;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        /* Inside auto layout */

        // flex: none;
        // order: 0;
        // flex-grow: 0;
    }
    font-size: 9px;
    p {
    }
    margin: 0 5px;
`;

const QuickLinksContainer = styled.div`
    display: flex;
    flex: 1;
    margin: 0 10px;
    // justify-content: space-around;
    margin-bottom: 2em;
`;

// eslint-disable-next-line react/prop-types
export const Menu = ({ children }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleToggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    return (
        <AppWrapper isModalOpen={isModalOpen}>
            <ModalToggleButton onClick={handleToggleModal}>
                <i className="icon-menu"></i>
            </ModalToggleButton>
            <ModalWrapper isOpen={isModalOpen}>
                <ModalDot>
                    <span className="dot" onClick={() => setIsModalOpen(false)}></span>
                </ModalDot>
            </ModalWrapper>
        </AppWrapper>
    );
};

export default Menu;

export const MenuItems = () => {
    return (
        <Menu>
            <UserRole>Secretariate</UserRole>
            <Spacer space="34px" />
            <List>
                <ListItem>
                    {" "}
                    <i
                        className="icon-calendar"
                        style={{
                            marginRight: "10px",
                        }}
                    />
                    Meetings
                </ListItem>
                <ListItem>
                    {" "}
                    <i
                        className="icon-print"
                        style={{
                            marginRight: "10px",
                        }}
                    />
                    Attendance
                </ListItem>
                <ListItem>
                    {" "}
                    <i
                        className="icon-back-in-time"
                        style={{
                            marginRight: "10px",
                        }}
                    />
                    Minutes
                </ListItem>
                <ListItem>
                    {" "}
                    <i
                        className="icon-chat"
                        style={{
                            marginRight: "10px",
                        }}
                    />
                    Notification
                </ListItem>
                <ListItem>
                    {" "}
                    <i
                        className="icon-laptop"
                        style={{
                            marginRight: "10px",
                        }}
                    />
                    Prayer
                </ListItem>
                <ListItem>
                    {" "}
                    <i
                        className="icon-file-pdf"
                        style={{
                            marginRight: "10px",
                        }}
                    />
                    Documents
                </ListItem>
                <ListItem>
                    {" "}
                    <i
                        className="icon-user-outline"
                        style={{
                            marginRight: "10px",
                        }}
                    />
                    Users
                </ListItem>
                <ListItem>
                    {" "}
                    <i
                        className="icon-power"
                        style={{
                            marginRight: "10px",
                        }}
                    />
                    Logout
                </ListItem>
            </List>
        </Menu>
    );
};

export const EditMeeting = () => {
    return (
        <Menu>
            <Spacer space="24px" />
            <Title>Welcome</Title>
            <Text
                style={{
                    color: "#64748B",
                    fontSize: "14x",
                }}
            >
                This is your secretariat workplace.
            </Text>
            {/* <Spacer size="5px" /> */}
            <But1>New Meeting </But1>
            <Spacer space="24px" />

            <h3>Quick links</h3>
            <QuickLinksContainer>
                <QuickLinks>
                    <div className="icon">
                        <i className="icon-calendar" />
                    </div>
                    <Text>Meetings</Text>
                </QuickLinks>
                <QuickLinks>
                    <div className="icon">
                        <i className="icon-print" />
                    </div>
                    <Text>Attendance</Text>
                </QuickLinks>
                <QuickLinks>
                    <div className="icon">
                        <i className="icon-laptop" />
                    </div>
                    <Text>Prayer</Text>
                </QuickLinks>
                <QuickLinks>
                    <div className="icon">
                        <i className="icon-file-pdf" />
                    </div>
                    <Text>Documents</Text>
                </QuickLinks>
            </QuickLinksContainer>

            <TitleContainer>
                <div>
                    <h3>Upcoming Meetings</h3>
                </div>
                <div>
                    <p
                        style={{
                            color: "#1890FF",
                            // marginTop: "20px",
                            lineHeight: "19.12px",
                            fontSize: "15px",
                        }}
                    >
                        Calender
                    </p>
                </div>
            </TitleContainer>
            <div
                style={{
                    padding: "20px 10px 10px 10px",
                    borderTop: "1px solid #d9d9d9",
                    borderBottom: "1px solid #d9d9d9",
                    marginTop: "10px",
                }}
            >
                <TitleContainer>
                    <div>
                        {" "}
                        <span
                            style={{
                                fontStyle: "normal",
                                fontWeight: 600,
                                fontSize: " 18px",
                                lineHeight: "19px",

                                color: "#626262",
                            }}
                        >
                            {" "}
                            Senate Meeting
                        </span>{" "}
                        <p>3/27/2023 - 2:00PM | Regular</p>
                    </div>
                    <div>
                        <b
                            style={{
                                fontSize: "16px",
                                color: "#4F5D75",
                                fontWeight: "bolder",
                            }}
                        >
                            241th
                        </b>
                        <Tag>Pending</Tag>
                    </div>
                </TitleContainer>
            </div>
            <div
                style={{
                    padding: "20px 10px 10px 10px",
                    borderTop: "1px solid #d9d9d9",
                    borderBottom: "1px solid #d9d9d9",
                    marginTop: "10px",
                }}
            >
                <TitleContainer>
                    <div>
                        {" "}
                        <span
                            style={{
                                fontStyle: "normal",
                                fontWeight: 600,
                                fontSize: " 18px",
                                lineHeight: "19px",

                                color: "#626262",
                            }}
                        >
                            {" "}
                            OATT Meeting
                        </span>{" "}
                        <p>3/28/2023 - 2:00PM | Emergency</p>
                    </div>
                    <div>
                        <b
                            style={{
                                fontSize: "16px",
                                color: "#4F5D75",
                                fontWeight: "bolder",
                            }}
                        >
                            241th
                        </b>
                        <Tag color="#E7E7E7">closed</Tag>
                    </div>
                </TitleContainer>
            </div>
            <TitleContainer>
                <div>
                    <h3>Previous Meetings</h3>
                </div>
                <div>
                    <p
                        style={{
                            color: "#1890FF",
                            // marginTop: "20px",
                            lineHeight: "19.12px",
                            fontSize: "15px",
                        }}
                    >
                        View More
                    </p>
                </div>
            </TitleContainer>
            <div
                style={{
                    padding: "20px 10px 10px 10px",
                    borderTop: "1px solid #d9d9d9",
                    borderBottom: "1px solid #d9d9d9",
                    marginTop: "10px",
                }}
            >
                <TitleContainer>
                    <div>
                        {" "}
                        <span
                            style={{
                                fontStyle: "normal",
                                fontWeight: 600,
                                fontSize: " 18px",
                                lineHeight: "19px",

                                color: "#626262",
                            }}
                        >
                            {" "}
                            Senate Meeting
                        </span>{" "}
                        <p>3/27/2023 - 2:00PM | Regular</p>
                    </div>
                    <div>
                        <b
                            style={{
                                fontSize: "16px",
                                color: "#4F5D75",
                                fontWeight: "bolder",
                            }}
                        >
                            241th
                        </b>
                        <Tag>Pending</Tag>
                    </div>
                </TitleContainer>
            </div>
            <div
                style={{
                    padding: "20px 10px 10px 10px",
                    borderTop: "1px solid #d9d9d9",
                    borderBottom: "1px solid #d9d9d9",
                    marginTop: "10px",
                }}
            >
                <TitleContainer>
                    <div>
                        {" "}
                        <span
                            style={{
                                fontStyle: "normal",
                                fontWeight: 600,
                                fontSize: " 18px",
                                lineHeight: "19px",

                                color: "#626262",
                            }}
                        >
                            {" "}
                            OATT Meeting
                        </span>{" "}
                        <p>3/28/2023 - 2:00PM | Emergency</p>
                    </div>
                    <div>
                        <b
                            style={{
                                fontSize: "16px",
                                color: "#4F5D75",
                                fontWeight: "bolder",
                            }}
                        >
                            241th
                        </b>
                        <Tag color="#E7E7E7">closed</Tag>
                    </div>
                </TitleContainer>
            </div>
        </Menu>
    );
};

export const MeetingRoom = () => {
    return (
        <Menu>
            <Spacer space="25" />
            <Title
                style={{
                    padding: "0 8px",
                }}
            >
                Meeting actions
            </Title>
            <Spacer space="5" />
            <Box
                style={{
                    // color: "#64748B",
                    // fontSize: "13px",
                    padding: "0 8px",
                    // textAlign: "center",
                }}
            >
                <Text
                    style={{
                        color: "#64748B",
                        fontSize: "14px",
                        // padding: "0 8px",
                        // textAlign: "center",
                    }}
                >
                    Create instant poll for vote, send broadcast message and pass special prayers.{" "}
                </Text>
            </Box>
            <Spacer space="5" />
            <List>
                <ListItem>
                    {" "}
                    <i
                        className="icon-layers"
                        style={{
                            marginRight: "10px",
                        }}
                    />
                    Create Poll
                </ListItem>
                <ListItem>
                    {" "}
                    <i
                        className="icon-ok"
                        style={{
                            marginRight: "10px",
                        }}
                    />
                    Vote
                </ListItem>
                <ListItem>
                    {" "}
                    <i
                        className="icon-paper-plane-empty"
                        style={{
                            marginRight: "10px",
                        }}
                    />
                    Special Request
                </ListItem>
            </List>
            <Spacer size="34px" />
        </Menu>
    );
};
