import theme from "@/style/theme";
import styled from "styled-components";

type WithLoaderWrapperProps = {
    width?: string | number;
    height?: string | number;
};

export const WithLoaderWrapper = styled.div<WithLoaderWrapperProps>`
    width: ${(p) => p.width};
    height: ${(p) => p.height};
    display: flex;
    justify-content: center;
    align-items: center;
`;

WithLoaderWrapper.defaultProps = {
    width: "100%",
    height: "auto",
};

export const ErrorOccuredWrapper = styled.div`
    text-align: center;
    & .message {
        margin-bottom: 1em;
        color: ${theme.PrimaryRed};
    }
`;
