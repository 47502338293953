import { useEffect, useState } from "react";

import { SortDirection } from "@/utils/enums";
import { MaybeUndefined } from "@/types/common";
import { StyledTitleCell } from "./table.styles";

type TitleCellWithSortProps = {
    title: string;
    field: string;
    currentSortField: string;
    currentSortDirection: MaybeUndefined<SortDirection>;
    onSort: Function;
};
export function TitleCellWithSort(props: TitleCellWithSortProps) {
    const { title, field, currentSortField, currentSortDirection, onSort } = props;
    const [sortDirection, setSortDirection] = useState<SortDirection>();

    useEffect(() => {
        if (currentSortField === field) {
            setSortDirection(currentSortDirection);
            return;
        }
        if (sortDirection) setSortDirection(undefined);
    }, [currentSortField, field, sortDirection, currentSortDirection]);

    return (
        <StyledTitleCell
            onClick={() => onSort({ field })}
            // It only updates sort icons' opacity if the current sort field is
            // this particular field
            fieldSortDirection={field === currentSortField ? currentSortDirection : undefined}
        >
            <span className="title">{title}</span>
            <i className={`sort-icons-wrapper icon-angle-up`} />
            <i className={`sort-icons-wrapper icon-angle-down`} />
        </StyledTitleCell>
    );
}
