import { ActionItem, TableAction } from "./action-item";
import { StyledActionsWrapper } from "./table.styles";

type ActionsProps = {
    // eslint-disable-next-line @typescript-eslint/array-type
    actions: Array<TableAction>;
    item: Record<string, any>;
};

export function Actions(props: ActionsProps) {
    const { actions, item } = props;

    return (
        <StyledActionsWrapper>
            {actions.map((action) => (
                <ActionItem
                    key={action.label as any}
                    label={action.label}
                    icon={action.icon}
                    title={action.title}
                    onClick={() => action.onClick(item)}
                    disabled={action.disabled}
                />
            ))}
        </StyledActionsWrapper>
    );
}
