import { useParams } from "react-router";

import { ApiError } from "@/types/common";
import { PageLayout, WithLoader } from "@/features/ui";

import { Prayer, useGetPrayerByIdQuery } from "./prayer-api";

import { PrayerViewer } from "./prayer-viewer.component";

export const PrayerDetails = (): React.ReactElement => {
    const { id } = useParams();
    const { data: prayerData, isLoading, error } = useGetPrayerByIdQuery(id as string);

    return (
        <PageLayout>
            <WithLoader isLoading={isLoading} error={error as ApiError} height={"100vh"}>
                <PrayerViewer prayer={prayerData as Prayer} />
            </WithLoader>
        </PageLayout>
    );
};
