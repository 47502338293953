import { Box, Text, Spacer, Tag, Button } from "@flexisaf/flexibull2";
import SwipeableViews from "react-swipeable-views";

import { BackButton, DataItem, Dropdown, Icon, StatusIndicator } from "@/features/ui";
import { theme } from "@/style";

import { useAgendaContext } from "./agenda-viewer";
import { useMemo, useState } from "react";
import { Prayer } from "@/features/prayers";
import { hasItems } from "@/utils/object-utils";
import { AuthUser } from "@/features/auth";
import { titleCase } from "@/utils/string-utils";
import {
    OTHERS_LABEL,
    getStatusTagProps,
    groupDepartmentByFaculties,
    groupedByDepartment,
} from "../utils";

/** FACULTY GROUPED VIEW **/
export function FacultyGroupedView() {
    const {
        agenda,
        user,
        showPrayerItemStatus,
        setAgendaItemsView,
        onSelectPrayer,
        hasAgendaItems,
    } = useAgendaContext();
    const [selectedDepartment, setSelectedDepartment] = useState<string | null>(null);
    const [index, setIndex] = useState<number>(0);

    const facultyGroups = groupDepartmentByFaculties(agenda?.prayerItems ?? []);

    const departmentalPrayerItems = useMemo(
        () =>
            agenda.prayerItems
                .filter((item) => {
                    if (selectedDepartment === OTHERS_LABEL && !item.prayer?.department?.name) {
                        return true;
                    }
                    return item.prayer?.department?.name === selectedDepartment;
                })
                ?.map(({ prayer }) => prayer) || [],
        [agenda.prayerItems, selectedDepartment]
    );

    const onChangeIndex = (index: number) => setIndex(index);

    const onSetDepartmentalView = (department) => {
        setSelectedDepartment(department);
        onChangeIndex(1);
    };

    const onSetFacultyView = () => {
        setSelectedDepartment(null);
        onChangeIndex(0);
    };
    return (
        <SwipeableViews index={index} onChangeIndex={onChangeIndex} disabled={true}>
            <Box>
                <Box display="flex">
                    {hasAgendaItems && <BackButton onClick={setAgendaItemsView} />}{" "}
                    <Text block>Faculties</Text>
                </Box>
                <hr color={theme.SecondaryGrey} />
                <Spacer space={8} />

                {Object.entries(facultyGroups).map(([faculty, departments]) => (
                    <Dropdown title={faculty} key={faculty}>
                        <>
                            {Object.values(departments).map(({ name, count }) => (
                                <>
                                    <Box
                                        key={name}
                                        style={{
                                            border: `.8px solid ${theme.TertiarySurface}`,
                                            cursor: "pointer",
                                            padding: "8px 0px",
                                        }}
                                        onClick={() => onSetDepartmentalView(name)}
                                    >
                                        <DataItem
                                            subtitle={name}
                                            rightContent={
                                                <Text color={theme.PrimaryGrey}>
                                                    {count} Prayers
                                                </Text>
                                            }
                                        />
                                    </Box>
                                    <Spacer space={8} />
                                </>
                            ))}
                        </>
                    </Dropdown>
                ))}
            </Box>
            <Box>
                <DepartmentExpendedView
                    user={user}
                    showStatus={showPrayerItemStatus}
                    prayers={departmentalPrayerItems}
                    onSelectPrayer={onSelectPrayer}
                    onBack={onSetFacultyView}
                />
            </Box>
        </SwipeableViews>
    );
}

/** DEPARTMENT EXPANDED VIEW **/
type DepartmentExpandedViewProps = {
    user: AuthUser;
    prayers: Prayer[];
    onSelectPrayer: (prayer: Prayer) => void;
    showStatus: boolean;
    onBack: () => void;
};

function DepartmentExpendedView(props: DepartmentExpandedViewProps) {
    const { user, prayers, onSelectPrayer, onBack, showStatus } = props;
    const department = prayers[0]?.department?.name ?? OTHERS_LABEL;

    return (
        <Box>
            <Button onClick={onBack} plain color={theme.PrimaryGrey}>
                <Box display="flex">
                    <span>
                        <Icon icon="arrow-left-plain" size={12} color={theme.PrimaryGrey} />
                    </span>
                    <Spacer space={8} direction="horizontal" />{" "}
                    <Text block>{department} Prayer Items</Text>
                </Box>
            </Button>

            <hr color={theme.SecondaryGrey} />
            <Spacer space={8} />
            {prayers.map((prayerItem, index) => (
                <>
                    <DataItem
                        key={prayerItem.id}
                        style={{ border: `.8px solid ${theme.TertiarySurface}`, padding: 8 }}
                        subtitle={`${index + 1}. ${prayerItem.title}`}
                        caption={titleCase(prayerItem.category)}
                        onClick={() => onSelectPrayer(prayerItem)}
                        rightContent={
                            showStatus && (
                                <StatusIndicator
                                    {...getStatusTagProps(prayerItem.status, user.currentRole.unit)}
                                />
                            )
                        }
                    />
                    <Spacer space={4} />
                </>
            ))}
        </Box>
    );
}

/** DEPARTMENT GROUPED VIEW **/
export function DepartmentGroupedView() {
    const {
        agenda,
        user,
        showPrayerItemStatus,
        setAgendaItemsView,
        onSelectPrayer,
        hasAgendaItems,
    } = useAgendaContext();
    const departmentalGroups = groupedByDepartment(agenda?.prayerItems ?? []);

    return (
        <Box style={{ backgroundColor: "white" }}>
            <Box display="flex">
                {hasAgendaItems && <BackButton onClick={setAgendaItemsView} />}{" "}
                <Text block>Departments</Text>
            </Box>
            <hr color={theme.SecondaryGrey} />
            <Spacer space={8} />
            {Object.entries(departmentalGroups).map(([departmentName, deptItems]) => (
                <Dropdown title={departmentName} key={departmentName}>
                    <>
                        {deptItems.items.map(({ prayer }, index) => (
                            <>
                                <DataItem
                                    key={prayer.id}
                                    style={{
                                        border: `1px solid ${theme.SecondarySurface}`,
                                        padding: 8,
                                    }}
                                    onClick={() => onSelectPrayer(prayer)}
                                    subtitle={`${index + 1}. ${prayer.title}`}
                                    caption={titleCase(prayer.category)}
                                    rightContent={
                                        showPrayerItemStatus && (
                                            <StatusTag
                                                {...getStatusTagProps(
                                                    prayer.status,
                                                    user.currentRole.unit
                                                )}
                                            />
                                        )
                                    }
                                />

                                <Spacer space={4} />
                            </>
                        ))}
                    </>
                </Dropdown>
            ))}
        </Box>
    );
}

/** PLAIN VIEW **/
export function PlainView() {
    const {
        agenda,
        user,
        showPrayerItemStatus,
        setAgendaItemsView,
        onSelectPrayer,
        hasAgendaItems,
    } = useAgendaContext();
    const items = agenda?.prayerItems.filter(({ prayer }) => !!prayer) || [];

    return (
        <Box>
            <Box display="flex" style={{ alignItems: "center" }}>
                {hasAgendaItems && <BackButton onClick={setAgendaItemsView} />}
                <Text block>Prayer Items</Text>
            </Box>
            <hr color={theme.SecondaryGrey} />
            <Spacer space={8} />

            {hasItems(items) ? (
                items.map(({ prayer }, index) => (
                    <>
                        <DataItem
                            key={prayer.id}
                            style={{
                                padding: 8,
                            }}
                            onClick={() => onSelectPrayer(prayer)}
                            subtitle={`${index + 1}. ${prayer.title}`}
                            caption={titleCase(prayer.category)}
                            rightContent={
                                showPrayerItemStatus && (
                                    <StatusTag
                                        {...getStatusTagProps(prayer.status, user.currentRole.unit)}
                                    />
                                )
                            }
                        />

                        <Spacer space={8} />
                    </>
                ))
            ) : (
                <Box> Nothing to display</Box>
            )}
        </Box>
    );
}

type StatusTagProps = { status?: string; color?: string };
function StatusTag({ status, color }: StatusTagProps) {
    return (
        <Tag style={{ border: 0 }} color={color}>
            <Text bold size={"9px"}>
                {status}
            </Text>
        </Tag>
    );
}
