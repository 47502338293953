import { theme } from "@/style";
import styled from "styled-components";

export const DropzoneContainer = styled.div`
    border: 1px dashed ${theme.PrimaryGreyLight};
    padding: 12px 24px;
    text-align: center;
    cursor: pointer;
    margin: 10px 0 20px 0;
    width: 100%;
    background: #ffffff;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 300;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const Preview = styled.div`
    display: "flex";
    justify-content: center;
    align-items: center;
`;

export const PreviewInner = styled.img`
    background-image: transparent;
    max-width: 120px;
    max-height: 80px;
    width: auto;
    height: auto;
    display: block;
`;
