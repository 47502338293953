import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Spacer, Text, Box, Button, Checkbox } from "@flexisaf/flexibull2/";

import { appPaths } from "@/app/app-paths";
import { typography } from "@/style/typography";
import { HookForm as Form } from "@/features/ui";
import { AuthLayout } from "@/features/auth/auth-layout";
import { statusColors } from "@/style/colors";
import theme from "@/style/theme";

import { PasswordFormBody, usePasswordReset } from "./use-password-reset";

const PASSWORD_RESET_MSG = "Please provide and confirm the new password you want to use.";

export function PasswordReset() {
    const { isInvalidPasswordResetLink, validation, actions, loading, errors, success } =
        usePasswordReset();
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const form = useForm<PasswordFormBody>({
        resolver: yupResolver(validation),
    });

    const passwordFieldType = isPasswordVisible ? "text" : "password";

    const renderPasswordResetContent = () => {
        if (isInvalidPasswordResetLink) {
            return (
                <Box display="flex" style={{ justifyContent: "center" }}>
                    <Text color={theme.PrimaryRed}>
                        Sorry, this appears to be invalid password reset link
                    </Text>
                </Box>
            );
        }

        if (success.isPasswordResetSuccess) {
            return (
                <Box>
                    <Text {...typography.h2} color={theme.PrimaryGreen}>
                        {" "}
                        Congrats!!
                    </Text>
                    <Spacer space={8} />
                    <Text>
                        Your password has been updated successfully, button below to login with your
                        new password
                    </Text>
                    <Spacer space={24} />
                    <Link to={appPaths.login}>
                        <Button>Proceed to Login Page</Button>
                    </Link>
                </Box>
            );
        }

        return (
            <Box>
                <Text {...typography.h2} block>
                    Reset Password
                </Text>

                <Text color={theme.PrimaryGrey} block>
                    {PASSWORD_RESET_MSG}
                </Text>
                <Spacer space={16} />

                <Form form={form} onSubmit={form.handleSubmit(actions.resetPassword)}>
                    <Form.Input
                        name="password"
                        placeholder="New Password"
                        spaceBottom
                        block
                        type={passwordFieldType}
                    />
                    <Form.Input
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        block
                        type={passwordFieldType}
                    />

                    <>
                        <Text
                            className="login-error"
                            color={statusColors.error.color}
                            block
                            style={{ height: "48px" }}
                        >
                            {errors?.passwordResetError?.message}
                        </Text>
                        <Spacer space={8} />
                    </>

                    <Box
                        onClick={() => setIsPasswordVisible((prev) => !prev)}
                        style={{ display: "inline-block" }}
                    >
                        <Checkbox
                            label="Show Password"
                            checked={isPasswordVisible}
                            style={{ paddingLeft: 0 }}
                        />
                    </Box>
                    <Spacer space={16} />

                    <Button
                        progress={loading.isResettingPassword}
                        block
                        onClick={form.handleSubmit(actions.resetPassword)}
                    >
                        Reset Password
                    </Button>
                    <Spacer space={16} />
                    <Link to={appPaths.login}>
                        <Text>
                            <i className="icon-angle-left" />
                            Back
                        </Text>
                    </Link>
                </Form>
            </Box>
        );
    };

    return (
        <AuthLayout>
            <Box pad="24px 0">{renderPasswordResetContent()}</Box>
        </AuthLayout>
    );
}
