import { useState, useMemo } from "react";
import { Box, Spacer, Button, Tag } from "@flexisaf/flexibull2";
import { Text } from "@flexisaf/flexibull2/build/typo";

import { useAuthUserNavigate } from "@/features/auth";
import { BackButton, Icon, Section } from "@/features/ui";
import { appPaths } from "@/app";
import { theme } from "@/style";
import { PrayerStatuses, Unit } from "@/utils/enums";
import { Privileges, usePrivileges } from "@/features/authorization";
import { formatResultUrl, getFileDetailsFromUrl, snakeToTitleCase } from "@/utils/string-utils";
import { useUserUnitValue } from "@/features/users";

import { Prayer, Result } from "./prayer-api";
import { CommentList } from "../comments/comment-list.component";
import { SubProgramResults } from "../results/results.component";
import { useGetCommentsQuery } from "../comments/comment-api";
import { usePrayerController } from "./use-prayer";
import {
    ApprovePrayerModal,
    PrayerRow,
    RaisePrayerModal,
    RecommendPrayerModal,
    RejectPrayerModal,
    StepDownPrayerModal,
    SubmitPrayerModal,
    WriteCommentModal,
} from "../prayers.ui";

import { DocumentPreviewerModal, ViewDocument } from "@/features/documents";

type SuccessfulRequest = {
    hasRaisedPrayer?: boolean;
    hasSubmittedPrayer?: boolean;
    hasSteppedDownPrayer?: boolean;
    hasRecommendedPrayer?: boolean;
    hasApprovedPrayer?: boolean;
    hasRejectedPrayer?: boolean;
};

type PrayerViewerProps = {
    prayer: Prayer;
    onBack?: () => void;
};

export const PrayerViewer = (props: PrayerViewerProps): React.ReactElement => {
    const { prayer: prayerData, onBack } = props;

    const { data: commentData, isLoading: isCommentsLoading } = useGetCommentsQuery(prayerData?.id);
    const [selectedResult, setSelectedResult] = useState<Result | null>(null);
    const [successfulRequest, setSuccessfulRequest] = useState<SuccessfulRequest>({});

    const [isResultPreviewerOpen, setIsResultPreviewerOpen] = useState(false);
    const [isRaisePrayerModalOpen, setIsRaisePrayerModalOpen] = useState(false);
    const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);
    const [isPrayerCommentModalOpen, setPrayerCommentModal] = useState(false);
    const [isPrayerRecommendModalOpen, setPrayerRecommendModalOpen] = useState(false);
    const [isPrayerStepDownModalOpen, setPrayerStepDownModal] = useState(false);
    const [isApprovedModalOpen, setApprovedModal] = useState(false);
    const [isRejectedModalOpen, setRejectedModal] = useState(false);
    const [previewOthers, setPreviewOthers] = useState(false);

    const updateSuccessfulRequest = (val: Partial<SuccessfulRequest>) =>
        setSuccessfulRequest((prev) => ({ ...prev, ...val }));

    const openResultPreview = (result) => {
        setSelectedResult(result);
        setIsResultPreviewerOpen(true);
    };

    const showDepartmentOrFacultyInfo = useUserUnitValue({
        [Unit.Department]: true,
        [Unit.Faculty]: true,
        default: false,
    });

    const commentsAvailable = commentData?.length > 0;

    const { navigateTo } = useAuthUserNavigate();

    const { userDetails } = usePrayerController();
    const { hasPrivilege } = usePrivileges();

    const prayerPrivileges = useMemo(
        () => ({
            canEditPrayer: hasPrivilege(Privileges.UpdatePrayers),
            canWriteComments: hasPrivilege(Privileges.CommentOnPrayers),
            canRecommendPrayer: hasPrivilege(Privileges.RecommendPrayers),
            canStepDownPrayer: hasPrivilege(Privileges.StepDownPrayers),
            canApprovePrayer: hasPrivilege(Privileges.ApprovePrayers),
            canRejectPrayer: hasPrivilege(Privileges.RejectPrayers),
            canSubmitPrayer: hasPrivilege(Privileges.SubmitPrayer),
            canRaisePrayer: hasPrivilege(Privileges.RaisePrayer),
        }),
        [hasPrivilege]
    );

    const buttonVisibility = useMemo(() => {
        const showEditButton = prayerPrivileges.canEditPrayer;

        const hideRecommendAndStepDown = Boolean(
            ([PrayerStatuses.Recommended, PrayerStatuses.SteppedDown].includes(
                prayerData?.status
            ) ||
                successfulRequest.hasSteppedDownPrayer) ??
                successfulRequest.hasRecommendedPrayer
        );

        const hideApproveAndReject = Boolean(
            ([
                PrayerStatuses.Approved,
                PrayerStatuses.Rejected,
                PrayerStatuses.ConditionalApproval,
            ].includes(prayerData?.status) ||
                successfulRequest.hasApprovedPrayer) ??
                successfulRequest.hasRejectedPrayer
        );

        const showSubmitButton = Boolean(
            prayerPrivileges.canSubmitPrayer &&
                !(
                    prayerData?.status === PrayerStatuses.Submitted ||
                    successfulRequest.hasSubmittedPrayer
                )
        );

        const showStepDownButton = Boolean(
            prayerPrivileges.canStepDownPrayer && !hideRecommendAndStepDown
        );

        const showRecommendButton = Boolean(
            prayerPrivileges.canRecommendPrayer && !hideRecommendAndStepDown
        );

        const showRaiseButton = Boolean(
            prayerPrivileges.canRaisePrayer &&
                !(prayerData?.status === PrayerStatuses.Raised || successfulRequest.hasRaisedPrayer)
        );

        const showRejectButton = Boolean(prayerPrivileges.canRejectPrayer && !hideApproveAndReject);

        const showApproveButton = Boolean(
            prayerPrivileges.canRejectPrayer && !hideApproveAndReject
        );

        return {
            showEditButton,
            showStepDownButton,
            showRejectButton,
            showRaiseButton,
            showRecommendButton,
            showApproveButton,
            showSubmitButton,
        };
    }, [prayerPrivileges, prayerData, successfulRequest]);

    const getPrayerStatusColor = () => {
        const status = prayerData.status.toUpperCase();
        switch (status) {
            case PrayerStatuses.Approved:
                return theme.PrimaryGreen;
            case PrayerStatuses.Rejected:
                return theme.PrimaryRed;
            case PrayerStatuses.Submitted:
                return theme.PrimaryBlue;
            case PrayerStatuses.Raised:
                return theme.PrimaryBlue;
            case PrayerStatuses.Recommended:
                return theme.PrimaryOrange;
            case PrayerStatuses.SteppedDown:
                return theme.PrimaryGrey;
            case PrayerStatuses.ConditionalApproval:
                return theme.PrimaryGreen;
            default:
                return theme.PrimaryGrey;
        }
    };
    const statusColor = getPrayerStatusColor();

    return (
        <Box>
            <Section>
                <Box>
                    <Box display="flex" style={{ alignItems: "center", justifyContent: "center" }}>
                        <Text color={theme.PrimaryGrey} bold>
                            Prayer Details
                        </Text>
                    </Box>
                    <Box>
                        <BackButton onClick={onBack} />
                    </Box>
                    {showDepartmentOrFacultyInfo && (
                        <>
                            {userDetails.faculty && (
                                <>
                                    <Spacer space={20} />
                                    <Box>
                                        <Text color="#64748B">{userDetails.faculty.name}</Text>
                                    </Box>
                                </>
                            )}
                            {userDetails.department && (
                                <>
                                    <Spacer space={5} />
                                    <Box>
                                        <Text color="#64748B">{userDetails.department.name}</Text>
                                    </Box>
                                </>
                            )}
                        </>
                    )}
                </Box>
                <Spacer space={30} />
                <Box>
                    <PrayerRow title="Prayer Title" content={prayerData?.title} />
                    <Spacer space={30} />
                    <PrayerRow title="Description" content={prayerData?.description} />
                    <Spacer space={30} />
                    <PrayerRow title="Department" content={prayerData?.department?.name ?? ""} />
                    <Spacer space={30} />
                    <PrayerRow
                        title="Prayer Category"
                        content={snakeToTitleCase(prayerData?.category)}
                    />
                    <Spacer space={30} />
                    <PrayerRow
                        title="Prayer Status"
                        content={
                            <PrayerStatusTag status={snakeToTitleCase(prayerData.status)} color={statusColor}/>
                        }
                        />
                    {prayerData?.category === "RESULTS" && (
                        <>
                            <Spacer space={30} />
                            <Box>
                                <Text bold>Uploaded Results</Text>
                                <Spacer space={10} />
                                {prayerData?.results?.length > 0 &&
                                    prayerData?.results?.map((result) => {
                                        return (
                                            <Box pad=" 0 10px" key={result.id}>
                                                <SubProgramResults
                                                    key={result.id}
                                                    level={result.level}
                                                    subprogram={result.subprogram}
                                                    session={result.session}
                                                    semester={result.semester}
                                                    onOpen={() => openResultPreview(result)}
                                                />
                                                <Spacer space={10} />
                                            </Box>
                                        );
                                    })}

                                {prayerData?.localResults?.length > 0 &&
                                    prayerData?.localResults?.map((result) => {
                                        return (
                                            <>
                                                <Box
                                                    display="flex"
                                                    style={{
                                                        justifyContent: "space-between",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <PrayerRow
                                                        content={
                                                            getFileDetailsFromUrl(result.url).name
                                                        }
                                                    />
                                                    <ViewDocument
                                                        onClick={() => openResultPreview(result)}
                                                    />
                                                </Box>
                                            </>
                                        );
                                    })}
                            </Box>
                        </>
                    )}
                    {prayerData?.otherAttachments && (
                        <>
                            <Spacer space={30} />
                            <Box
                                display="flex"
                                style={{ justifyContent: "space-between", alignItems: "center" }}
                            >
                                <PrayerRow
                                    title="Other Attachments"
                                    content={
                                        getFileDetailsFromUrl(prayerData?.otherAttachments).name
                                    }
                                />
                                <ViewDocument onClick={() => setPreviewOthers(true)} />
                            </Box>
                        </>
                    )}
                    <Spacer space={30} />
                    <PrayerRow
                        title="Prayer Reason"
                        content={snakeToTitleCase(prayerData?.reason ?? "N/A")}
                    />
                    <Spacer space={30} />
                </Box>

                <Box>
                    <Text bold>Comments</Text>
                    <Spacer space={10} />
                    <Box
                        display="flex"
                        style={{ justifyContent: "space-between", alignItems: "center" }}
                    >
                        {isCommentsLoading ? (
                            <Text>
                                <i>Loading comments... </i>
                            </Text>
                        ) : (
                            <>
                                {commentsAvailable ? (
                                    <CommentList comments={commentData} />
                                ) : (
                                    <Text>No Comments</Text>
                                )}
                                {commentsAvailable && (
                                    <Text>
                                        <Box
                                            onClick={() =>
                                                navigateTo(
                                                    appPaths.getPrayerComments(
                                                        prayerData?.id.toString()
                                                    )
                                                )
                                            }
                                            display="flex"
                                            style={{ justifyContent: "flex-end" }}
                                        >
                                            <Text color={theme.PrimaryColor}>View Comments</Text>
                                        </Box>
                                    </Text>
                                )}
                            </>
                        )}
                        {prayerPrivileges.canWriteComments && (
                            <Box
                                display="flex"
                                style={{ justifyContent: "flex-end" }}
                                onClick={() => setPrayerCommentModal(true)}
                            >
                                <Button plain color={theme.PrimaryColor}>
                                    <Text color={theme.PrimaryColor}>Write Comments</Text>
                                </Button>
                            </Box>
                        )}
                    </Box>
                </Box>
                <Spacer space={60} />
                <Box
                    display="flex"
                    style={{ justifyContent: "space-between", alignItems: "center" }}
                >
                    {buttonVisibility.showEditButton && (
                        <Button
                            pale
                            pad="20px"
                            onClick={() => {
                                navigateTo(appPaths.editPrayer(prayerData.id.toString()));
                            }}
                        >
                            Edit Prayer
                        </Button>
                    )}

                    {buttonVisibility.showRaiseButton ? (
                        <Button pad="20px" onClick={() => setIsRaisePrayerModalOpen(true)}>
                            Raise Prayer{" "}
                        </Button>
                    ) : buttonVisibility.showSubmitButton ? (
                        <Button pad="20px" onClick={() => setIsSubmitModalOpen(true)}>
                            Submit Prayer{" "}
                        </Button>
                    ) : null}
                </Box>

                {buttonVisibility.showStepDownButton || buttonVisibility.showRecommendButton ? (
                    <>
                        <Spacer space={10} />
                        <Box
                            display="flex"
                            style={{
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            {buttonVisibility.showStepDownButton && (
                                <Button
                                    pad="20px"
                                    onClick={() => setPrayerStepDownModal(true)}
                                    pale
                                >
                                    Step Down Prayer{" "}
                                </Button>
                            )}
                            {buttonVisibility.showRecommendButton && (
                                <Button
                                    pad="20px"
                                    onClick={() => setPrayerRecommendModalOpen(true)}
                                >
                                    Recommend Prayer{" "}
                                </Button>
                            )}
                        </Box>
                    </>
                ) : null}

                {prayerPrivileges.canApprovePrayer || prayerPrivileges.canRejectPrayer ? (
                    <>
                        <Spacer space={10} />
                        <Box
                            display="flex"
                            style={{
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            {buttonVisibility.showRejectButton && (
                                <Button pad="20px" pale onClick={() => setRejectedModal(true)}>
                                    Reject Prayer{" "}
                                </Button>
                            )}
                            {buttonVisibility.showApproveButton && (
                                <Button pad="20px" onClick={() => setApprovedModal(true)}>
                                    Approve Prayer{" "}
                                </Button>
                            )}
                        </Box>
                    </>
                ) : null}

                {/* Modals */}
                <DocumentPreviewerModal
                    isOpen={isResultPreviewerOpen}
                    onClose={() => setIsResultPreviewerOpen(false)}
                    url={formatResultUrl(selectedResult?.url ?? "")}
                />
                <DocumentPreviewerModal
                    isOpen={previewOthers}
                    onClose={() => setPreviewOthers(false)}
                    url={prayerData?.otherAttachments}
                />
                <RaisePrayerModal
                    prayerid={prayerData.id}
                    isOpen={isRaisePrayerModalOpen}
                    onClose={() => setIsRaisePrayerModalOpen(false)}
                    onRequestSuccessful={() => updateSuccessfulRequest({ hasRaisedPrayer: true })}
                />
                <SubmitPrayerModal
                    prayerid={prayerData.id}
                    isOpen={isSubmitModalOpen}
                    onClose={() => setIsSubmitModalOpen(false)}
                    onRequestSuccessful={() =>
                        updateSuccessfulRequest({ hasSubmittedPrayer: true })
                    }
                />
                <WriteCommentModal
                    prayerid={prayerData.id}
                    isOpen={isPrayerCommentModalOpen}
                    onClose={() => setPrayerCommentModal(false)}
                    userId={userDetails.userId}
                />
                <RecommendPrayerModal
                    prayerid={prayerData.id}
                    isOpen={isPrayerRecommendModalOpen}
                    onClose={() => setPrayerRecommendModalOpen(false)}
                    userId={userDetails.userId}
                    onRequestSuccessful={() =>
                        updateSuccessfulRequest({ hasRecommendedPrayer: true })
                    }
                />
                <StepDownPrayerModal
                    prayerid={prayerData.id}
                    isOpen={isPrayerStepDownModalOpen}
                    onClose={() => setPrayerStepDownModal(false)}
                    userId={userDetails.userId}
                    onRequestSuccessful={() =>
                        updateSuccessfulRequest({ hasSteppedDownPrayer: true })
                    }
                />
                <ApprovePrayerModal
                    prayerid={prayerData.id}
                    isOpen={isApprovedModalOpen}
                    onClose={() => setApprovedModal(false)}
                    userId={userDetails.userId}
                    onRequestSuccessful={() => updateSuccessfulRequest({ hasApprovedPrayer: true })}
                />
                <RejectPrayerModal
                    prayerid={prayerData.id}
                    isOpen={isRejectedModalOpen}
                    onClose={() => setRejectedModal(false)}
                    userId={userDetails.userId}
                    onRequestSuccessful={() => updateSuccessfulRequest({ hasRejectedPrayer: true })}
                />
            </Section>
        </Box>
    );
};

export const PrayerNotification = () => {
    return (
        <Box
            display="flex"
            pad="10px"
            margin="10px 0"
            style={{
                justifyContent: "space-between",
                alignItems: "flex-start",
                background: "#FFEAC1",
                borderRadius: "4px",
            }}
        >
            <Text size="12px">
                {" "}
                Kindly review the request below, and submit updated copy before the close of senate.
            </Text>
            <Icon icon="warning-2" color={theme.PrimaryRed} />
        </Box>
    );
};
type PrayerStatusTagProps = { status?: string; color?: string };

function PrayerStatusTag({ status, color }: PrayerStatusTagProps) {
    return (
        <Tag style={{ border: 0, marginTop: "5px" }} color={color}>
            <Text bold size={"9px"}>
                {status}
            </Text>
        </Tag>
    );
}
