import { useCreatePrayerMutation } from "./prayer-api";
import { useAuthUserNavigate } from "@/features/auth";
import { appPaths } from "@/app";
import { PrayerForm } from "./prayer-form";
import { useEffect } from "react";
import { useGetUpcomingSenateMeeting } from "@/features/meetings";
import { BackButton, PageLayout, Section } from "@/features/ui";

type PrayerCreateProps = {
    onPrayerCreated?: (data) => void;
    meetingId?: number;
    showBackButton?: boolean;
};

export const PrayerCreate = (props: PrayerCreateProps): React.ReactElement => {
    const { navigateTo } = useAuthUserNavigate();
    const {
        meetingId,
        onPrayerCreated = () => navigateTo(appPaths.prayers),
        showBackButton = true,
    } = props;
    const [
        initiatePrayerMutation,
        { data, isLoading: isPrayerCreating, isSuccess: isPrayerCreated },
    ] = useCreatePrayerMutation();

    const { meeting } = useGetUpcomingSenateMeeting();

    useEffect(() => {
        if (isPrayerCreated) {
            onPrayerCreated(data);
        }
    }, [isPrayerCreated, onPrayerCreated, data]);

    return (
        <PageLayout pageLabel="Create Prayer">
            <Section>
                {showBackButton && <BackButton />}
                <PrayerForm
                    submit={initiatePrayerMutation}
                    isSubmitting={isPrayerCreating}
                    isEdit={false}
                    meetingId={meetingId ?? meeting?.id}
                />
            </Section>
        </PageLayout>
    );
};
