export enum Privileges {
    CreateUsers = "users:c",
    ReadUsers = "users:r",
    UpdateUsers = "users:u",
    DeleteUsers = "users:d",

    CreateRoles = "roles:c",
    ReadRoles = "roles:r",
    UpdateRoles = "roles:u",
    DeleteRoles = "roles:d",

    InitiatePrayers = "prayers:i",
    ReadPrayers = "prayers:r",
    UpdatePrayers = "prayers:u",
    StepDownPrayers = "prayers:sd",
    RecommendPrayers = "prayers:rec",
    CommentOnPrayers = "prayers:comment",
    DeletePrayers = "prayers:d",
    ApprovePrayers = "prayers:a",
    AprrovePrayerConditionally = "prayers:ac",
    RejectPrayers = "prayers:reject",
    RequestChangesOnPrayer = "prayers:rc",
    SubmitPrayer = "prayers:sub",
    RaisePrayer = "prayers:raise",

    CreateMinutes = "minutes:c",
    ReadMinutes = "minutes:r",
    UpdateMinutes = "minutes:u",
    DeleteMinutes = "minutes:d",

    CreateAttendances = "attendances:c",
    ReadAttendances = "attendances:r",
    UpdateAttendances = "attendances:u",
    DeleteAttendances = "attendances:d",

    CreateMeeting = "meeting:c",
    ReadMeeting = "meeting:r",
    UpdateMeeting = "meeting:u",
    DeleteMeeting = "meeting:d",
    StartMeeting = "meeting:s",
    EndMeeting = "meeting:e",

    CreateDocuments = "documents:c",
    ReadDocuments = "documents:r",
    UpdateDocuments = "documents:u",
    DeleteDocuments = "documents:d",

    CreateAgenda = "agenda:c",
    ApproveAgenda = "agenda:a",
    ReadAgenda = "agenda:r",
    UpdateAgenda = "agenda:u",
    DeleteAgenda = "agenda:d",
}
