import noop from "lodash/noop";
import { Box, Button, Avatar, Spacer, Text } from "@flexisaf/flexibull2";

import { Icon } from "@/features/ui";
import { theme } from "@/style";

type UserCardProps = {
    name: string;
    id: string;
    subtext: string;
    avatarSrc?: string;
    onClick?: () => void;
    onDelete?: (id: string) => void;
};

export function UserCard(props: UserCardProps) {
    const { name, id, subtext, avatarSrc, onClick = noop, onDelete } = props;
    return (
        <Box
            onClick={onClick}
            display="flex"
            style={{ padding: "24px 12px", alignItems: "center", justifyContent: "space-between" }}
        >
            <Box display="flex">
                <Avatar src={avatarSrc} name={name} round />
                <Spacer direction="horizontal" space={12} />
                <Box display="flex" style={{ flexDirection: "column", flexGap: 4 }}>
                    <Text bold block>
                        {name}
                    </Text>
                    <Text block color={theme.PrimaryGrey}>
                        {subtext}
                    </Text>
                </Box>
            </Box>
            {onDelete && (
                <Button plain onClick={() => onDelete(id)} color={theme.PrimaryRed}>
                    <Icon icon="minus-circle" color={theme.PrimaryRed} />
                </Button>
            )}
        </Box>
    );
}
