import { useState, useEffect } from "react";
import { useQueryStateSync } from "@/lib/use-query-state-sync";
import { PaginationAdapterReturn, paginationAdapter } from "@/lib/pagination-adapter";
import confirmation from "@/lib/confirmation";
import { ApiError } from "@/types/common";
import {
    RoleParams,
    useGetAllRolesQuery,
    useCreateNewRoleMutation,
    useGetPrivilegesQuery,
    SingleRole,
    useEditRoleMutation,
    useDeactivateRoleMutation,
    useGetRoleQuery,
} from "./roles-api";

const DELETE_ROLE_TITLE = "Delete Role";
const DELETE_ROLE_MESSAGE = "Are you sure you want to delete this role";

export const useRoles = () => {
    const [selectedRole, setSelectedRole] = useState<SingleRole | null>(null);
    const [selectedRoleId, setSelectedRoleId] = useState<string>();
    const queryStateSync = useQueryStateSync<RoleParams, PaginationAdapterReturn>(
        { limit: 10 },
        paginationAdapter
    );
    const { queryState, setQueryField, page, setPage, setLimit } = queryStateSync;
    const { data: rolesData, ...getRolesMeta } = useGetAllRolesQuery(queryState);
    const { data: roleData, ...getRoleMeta } = useGetRoleQuery(selectedRoleId);
    const { data: privileges } = useGetPrivilegesQuery("");

    const [createRole, createRoleMeta] = useCreateNewRoleMutation();
    const [editRole, editRoleMeta] = useEditRoleMutation();
    const [deleteRoleReq, deleteRoleMeta] = useDeactivateRoleMutation();
    const searchRole = (value) => setQueryField("search-word", value);

    const deleteRole = confirmation(
        (id: string) => {
            deleteRoleReq(id);
        },
        { title: DELETE_ROLE_TITLE, message: DELETE_ROLE_MESSAGE, type: "danger" }
    );

    useEffect(() => {
        if (getRoleMeta.isSuccess) {
            setSelectedRole(roleData);
        }
    }, [selectedRoleId, roleData, getRoleMeta]);

    const clearSelectedRole = () => setSelectedRole(null);

    const privilegesOptions = privileges?.map((p) => ({
        label: p.description,
        value: p.name,
    }));

    const loading = {
        isLoadingRoles: getRolesMeta.isLoading,
        isUpdatingRoles: getRolesMeta.isFetching,
        isCreatingRoles: createRoleMeta.isLoading,
        isEditingRoles: editRoleMeta.isLoading,
        isDeletingRoles: deleteRoleMeta.isLoading,
    };

    const errors = {
        rolesError: <ApiError>getRolesMeta.error,
        createRoleError: <ApiError>createRoleMeta.error,
        editRoleError: <ApiError>editRoleMeta.error,
        deleteRoleError: <ApiError>deleteRoleMeta.error,
    };

    const actions = {
        searchRole,
        setPage,
        setLimit,
        clearSelectedRole,
        setSelectedRole,
        setSelectedRoleId,
        createRole,
        editRole,
        deleteRole,
    };
    return {
        rolesData,
        page,
        loading,
        errors,
        actions,
        queryState,
        selectedRole,
        privilegesOptions,
        isRoleCreated: createRoleMeta.isSuccess,
        isRoleUpdated: editRoleMeta.isSuccess,
    };
};
