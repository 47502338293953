import { useCallback, useMemo, useState, useRef } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { Box, Loader, Spacer } from "@flexisaf/flexibull2";

import theme from "@/style/theme";
import { usePinchZoom } from "@/lib/use-pinch-zoom";

import { Pagination, Zoomer } from "../components";
import { Alert } from "@/features/ui";
import { RendererWrapper } from "../styles";
import { RendererBaseProps } from "../types";
import { ZOOM_MIN, ZOOM_MAX, ZOOM_STEP } from "../util";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
const DOC_HEIGHT = 600;
const DOC_ERROR_MESSAGE = "An error occured while loading the document";

export function PdfRenderer(props: RendererBaseProps) {
    const { url, onLoadError, rendererHeight = "80vh" } = props;
    const [doc, setDoc] = useState<any>();
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [zoomLevel, setZoomLevel] = useState(ZOOM_MIN);
    const [pages, setPages] = useState<number[]>([]);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const pageHeight = useMemo(() => DOC_HEIGHT * zoomLevel, [zoomLevel]);
    const { onZoomIn, onZoomOut } = usePinchZoom(containerRef);

    onZoomIn(() => zoomLevel < ZOOM_MAX && setZoomLevel(zoomLevel + ZOOM_STEP));
    onZoomOut(() => zoomLevel > ZOOM_MIN && setZoomLevel(zoomLevel - ZOOM_STEP));

    const onDocumentLoadSuccess = (pdfDoc) => {
        setDoc(pdfDoc);
        setCurrentPage(1);
        const { numPages } = pdfDoc;
        setPages(Array.from({ length: numPages }, (_, i) => ++i));
    };

    const onPreviousPage = useCallback(() => {
        if (currentPage > 1) {
            const newPage = currentPage - 1;
            if (containerRef.current) {
                const newScrollTop = pageHeight * (newPage - 1);
                containerRef.current.scrollTo({ top: newScrollTop, behavior: "smooth" });
            }
        }
    }, [pageHeight, currentPage]);

    const onNextPage = useCallback(() => {
        if (currentPage < doc?.numPages) {
            if (containerRef.current) {
                const newScrollTop = pageHeight * currentPage;
                containerRef.current.scrollTo({
                    top: newScrollTop,
                    behavior: "smooth",
                });
            }
        }
    }, [doc, pageHeight, currentPage]);

    const handleScroll = () => {
        const container = containerRef.current;
        if (container) {
            const newPage = parseInt((container.scrollTop / pageHeight).toString()) + 1;
            if (newPage !== currentPage) setCurrentPage(newPage);
        }
    };

    const showPagination = useMemo(() => Boolean(doc?.numPages > 1), [doc?.numPages]);

    return (
        <RendererWrapper>
            {doc && (
                <Box
                    display="flex"
                    style={{
                        position: "sticky",
                        left: 0,
                        justifyContent: "space-between",
                        bottom: 10,
                        zIndex: theme.Layer.first,
                        background: "white",
                        boxShadow: theme.Elevate.low,
                    }}
                >
                    {showPagination && (
                        <Pagination
                            onPrevious={onPreviousPage}
                            onNext={onNextPage}
                            currentPage={currentPage}
                            totalPages={doc.numPages}
                        />
                    )}
                    <Box style={{ justifySelf: "flex-end" }}>
                        <Zoomer
                            onZoomChange={setZoomLevel}
                            min={ZOOM_MIN}
                            max={ZOOM_MAX}
                            step={ZOOM_STEP}
                        />
                    </Box>
                </Box>
            )}
            <div
                onScroll={handleScroll}
                ref={containerRef}
                style={{
                    width: "100%",
                    position: "relative",
                    overflowY: "scroll",
                    boxShadow: theme.Elevate.fader,
                }}
            >
                <div
                    style={{
                        height: rendererHeight,
                        display: "grid",
                        placeItems: "center",
                    }}
                >
                    <Document
                        file={url}
                        onLoadSuccess={onDocumentLoadSuccess}
                        loading={<Loader />}
                        onLoadError={(err) => {
                            // eslint-disable-next-line no-console
                            console.error(err);
                            onLoadError && onLoadError(DOC_ERROR_MESSAGE);
                        }}
                        error={() => <Alert status="error" message={DOC_ERROR_MESSAGE} />}
                    >
                        {pages.map((pageNumber) => (
                            <>
                                <Page
                                    key={pageNumber}
                                    pageNumber={pageNumber}
                                    height={pageHeight}
                                />
                                <Spacer space={8} />
                            </>
                        ))}
                    </Document>
                </div>
            </div>
        </RendererWrapper>
    );
}
