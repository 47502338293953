import { format } from "date-fns";

interface DateTimeObject {
    date: string | undefined;
    time: string | undefined;
}

export function formatDate(dateString: string): string {
    const formattedDate = format(new Date(dateString), "yyyy/MM/dd hh:mm a");
    return formattedDate;
}

export function parseDateSafely(rawDate: string | number) {
    if (!rawDate) return;

    try {
        return new Date(rawDate);
    } catch (err) {}
}
export function extractDateTime(dateTimeString: string): DateTimeObject {
    const dateTime: Date = new Date(dateTimeString);
    if (isNaN(dateTime.getTime())) {
        return { date: undefined, time: undefined };
    }
    const date: string = dateTime.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
    });
    const time: string = dateTime.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
    });

    return { date, time };
}

export const formatTime = (milliseconds: any) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${hours}hr:${minutes}min:${seconds}s`;
};
