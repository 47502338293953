import { isRejectedWithValue, MiddlewareAPI, Middleware } from "@reduxjs/toolkit";

const errorMiddleware: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
        const { error } = action;
        // eslint-disable-next-line no-console
        console.error(error);
    }
    return next(action);
};

export default errorMiddleware;
