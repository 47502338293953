import { useState } from "react";
import { useQueryStateSync } from "@/lib/use-query-state-sync";
import { PaginationAdapterReturn, paginationAdapter } from "@/lib/pagination-adapter";
import { ApiError } from "@/types/common";

import {
    Staff,
    StaffParams,
    useActivateStaffMutation,
    useDeactivateStaffMutation,
    useGetStaffQuery,
} from "../users-api";
import confirmation from "@/lib/confirmation";

const DEACTIVATE_STAFF_TITLE = "Deactivate User";
const DEACTIVATE_STAFF_MESSAGE = "Are you sure you want to deactivate this user account";

export function useUsers() {
    const queryStateSync = useQueryStateSync<StaffParams, PaginationAdapterReturn>(
        { limit: 10 },
        paginationAdapter
    );
    const { queryState, setQueryField, page, setPage, setLimit } = queryStateSync;
    const { data: usersData, ...getUsersMeta } = useGetStaffQuery(queryState);
    const [deactivateStaffReq, deactivateStaffMeta] = useDeactivateStaffMutation();
    const [activateStaff, activateStaffMeta] = useActivateStaffMutation();
    const [selectedUser, setUser] = useState<Staff | null>(null);

    const searchUser = (value) => setQueryField("search-word", value);
    const deactivateStaff = confirmation(
        (id: string) => {
            deactivateStaffReq(id);
        },
        { title: DEACTIVATE_STAFF_TITLE, message: DEACTIVATE_STAFF_MESSAGE, type: "danger" }
    );
    const clearSelectedUser = () => setUser(null);

    const loading = {
        isLoadingusers: getUsersMeta.isLoading,
        isUpdatingUsers: getUsersMeta.isFetching,
        isDeactivatingStaff: deactivateStaffMeta.isLoading,
        isActivatingStaff: activateStaffMeta.isLoading,
    };

    const errors = {
        usersError: <ApiError>getUsersMeta.error,
        activateStaffError: <ApiError>activateStaffMeta.error,
        deactivateStaffError: <ApiError>deactivateStaffMeta.error,
    };

    const actions = {
        setUser,
        clearSelectedUser,
        searchUser,
        deactivateStaff,
        activateStaff,
        setPage,
        setLimit,
    };

    const isStatsEnabled = false;

    return {
        isStatsEnabled,
        page,
        queryState,
        usersData,
        selectedUser,
        loading,
        errors,
        actions,
    };
}
