import { theme } from "@/style";
import { Button, Text } from "@flexisaf/flexibull2";
import { Icon } from "../../ui/base/icon";

type ViewDocumentProps = {
    onClick: () => void;
};

export const ViewDocument = ({ onClick }: ViewDocumentProps) => {
    return (
        <Button
            plain
            onClick={onClick}
            color={theme.PrimaryColor}
            iconLeft={<Icon icon="document-download" color={theme.PrimaryColor} />}
        >
            <Text style={{ fontSize: theme.FontSizes.sm }}>View Attachment</Text>
        </Button>
    );
};
