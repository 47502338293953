import { useState, useEffect } from "react";
import { useToggle } from "react-use";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Box, Spacer } from "@flexisaf/flexibull2/build/layout";
import { Text } from "@flexisaf/flexibull2/build/typo";
import { Button } from "@flexisaf/flexibull2/build/button";

import { theme } from "@/style";
import { HookForm as Form } from "@/features/ui";
import { DropzoneContainer } from "@/features/ui/menu";

import { ResultsModal, SubProgramResults } from "../results/results.component";

import { prayerCategoryOptions, prayerReasonUiOptions } from "../prayers-ui-options";
import { usePrayerController } from "./use-prayer";
import { PrayerCategories, PrayerStatuses, Unit } from "@/utils/enums";
import { hasItems } from "@/utils/object-utils";
import { useResponsiveValue } from "@/lib/use-responsive-value";
import { DocumentUploader, useUploadDocument } from "@/features/documents";

type SrmsResult = {
    subprogram: string;
    level: string;
    semester: string;
    session: string;
    url: string;
};

type PrayerFormProps = {
    submit: any;
    isSubmitting: boolean;
    meetingId?: number;
    isEdit?: boolean;
    data?: any;
};

export const PrayerForm = (props: PrayerFormProps) => {
    const { submit, isSubmitting, data, isEdit, meetingId } = props;
    const { upload, isUploading } = useUploadDocument();

    const [isOpen, setIsOpen] = useToggle(false);

    const [srmsResults, setSrmsResults] = useState<SrmsResult[]>([]);
    const [localResults, setLocalResults] = useState<File[]>([]);

    const { userDetails } = usePrayerController();

    const validation = yup.object().shape({
        title: yup.string().required("Prayer Title is required"),
        description: yup.string().required("Description is required"),
        category: yup.string().required("Prayer Category is required"),
    });

    const form = useForm({
        resolver: yupResolver(validation),
    });

    const category = form.watch("category");

    useEffect(() => {
        if (isEdit) {
            form.reset({
                ...data,
            });
            setSrmsResults([...srmsResults, ...data?.results]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEdit]);

    const onSubmit = async (values) => {
        const { otherAttachments, ...restForm } = values;
        if (otherAttachments) {
            const otherAttachmentsUrl = await upload({
                file: otherAttachments.file,
                fileName: otherAttachments.name,
            });
            restForm.otherAttachments = otherAttachmentsUrl;
        } else if (data) {
            restForm.otherAttachments = data?.otherAttachments;
        }

        const request = { ...restForm, category, meetingId };
        if (category === PrayerCategories.Results) {
            if (srmsResults && srmsResults.length > 0) {
                request.results = srmsResults;
            }

            if (localResults && localResults.length > 0) {
                const localResultsUrls = await uploadFilesAndGetUrls(localResults);
                request.localResults = localResultsUrls;
            }
        }
        if (userDetails.unit === Unit.Department) {
            request.departmentId = userDetails.department?.id;
            request.facultyId = userDetails.faculty?.id;
            request.status = PrayerStatuses.Initiated;
        }
        if (userDetails.unit === Unit.Faculty) {
            request.status = PrayerStatuses.Raised;
            request.facultyId = userDetails.faculty?.id;
        }
        if (userDetails.unit === Unit.Secretariat) {
            request.status = PrayerStatuses.Recommended;
        }

        submit(request);
    };

    function handleOtherAttachement(files: File[]) {
        form.setValue("otherAttachments", { name: files[0].name, file: files[0] });
    }

    async function uploadFilesAndGetUrls(files: File[]): Promise<string[]> {
        const fileUrlPromises = files.map(async (file) => {
            const fileUrl = await upload({
                file,
                fileName: file.name,
            });
            return fileUrl;
        });

        const fileUrls = await Promise.all(fileUrlPromises);
        return fileUrls;
    }

    const buttonWrapperJustify = useResponsiveValue({
        sm: "space-between",
        md: "flex-end",
    }) as string;

    return (
        <>
            <Box>
                <Spacer space={40} />
                <Form form={form}>
                    <Form.Input
                        name="title"
                        label="Prayer Title"
                        placeholder="Title"
                        style={{
                            width: "100%",
                        }}
                        defaultValue={data?.title}
                    />
                    <Spacer space={39} />
                    <Box>
                        <Text size="12px" bold>
                            Description
                        </Text>
                        <Form.ControlledEditor
                            name="description"
                            control={form.control}
                            defaultValue={data?.description}
                        />
                    </Box>

                    <Spacer space={20} />
                    <Form.Select
                        options={prayerCategoryOptions}
                        placeholder="Select Prayer Category"
                        name="category"
                        label="Prayer Category*"
                        spaceTop
                        style={{ width: "100%" }}
                        defaultValue={data?.category}
                    />
                    <Spacer space={20} />
                    {category === PrayerCategories.Results && (
                        <>
                            <Text bold>Result</Text>
                            <Box
                                style={{
                                    display: "flex",
                                    flex: "1",
                                }}
                            >
                                <DropzoneContainer>
                                    <div onClick={() => setIsOpen(true)}>
                                        <Text color={theme.PrimaryBlue}>Attach File</Text>
                                    </div>
                                </DropzoneContainer>
                            </Box>

                            {hasItems(srmsResults) && srmsResults.length > 0 && (
                                <>
                                    <Text bold>SRMS Results</Text>
                                    {srmsResults.map((result) => (
                                        <>
                                            <Spacer space={10} />
                                            <SubProgramResults
                                                level={result.level}
                                                subprogram={result.subprogram}
                                                semester={result.semester}
                                                session={result.session}
                                            />
                                        </>
                                    ))}
                                </>
                            )}

                            {hasItems(localResults) && localResults.length > 0 && (
                                <>
                                    <Spacer space="10" />
                                    <Text bold>Local Results</Text>
                                    {localResults.map((result) => (
                                        <>
                                            <Spacer space={10} />
                                            <Text>{result.name}</Text>
                                        </>
                                    ))}
                                </>
                            )}

                            <Spacer space={30} />
                        </>
                    )}

                    <Form.Select
                        options={prayerReasonUiOptions}
                        placeholder="Select Prayer Reason"
                        name="reason"
                        label="Prayer Reason"
                        spaceTop
                        style={{ width: "100%" }}
                        defaultValue={data?.reason}
                    />

                    <Spacer space={20} />

                    <Text bold>Other Attachments</Text>
                    <Box
                        style={{
                            display: "flex",
                            flex: "1",
                        }}
                    >
                        <DocumentUploader
                            onChange={handleOtherAttachement}
                            fileUrls={[data?.otherAttachments]}
                        >
                            <div>
                                <Text color={theme.PrimaryBlue}>Attach File</Text>
                            </div>
                        </DocumentUploader>
                    </Box>
                    <Box display="flex" style={{ justifyContent: buttonWrapperJustify }}>
                        <Button
                            onClick={form.handleSubmit(onSubmit)}
                            width={theme.ButtonDesktopWidth}
                            progress={isSubmitting || isUploading}
                        >
                            {isEdit ? "Update Prayer" : "Initiate Prayer"}
                        </Button>
                    </Box>
                </Form>
            </Box>
            <ResultsModal
                title="Results Location"
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                srmsResults={srmsResults}
                setSrmsResults={setSrmsResults}
                setLocalResults={setLocalResults}
            />
        </>
    );
};
