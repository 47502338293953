import React from "react";

import { StyledActionItem } from "./table.styles";

export type TableAction = {
    label: React.ReactElement;
    icon: React.ReactElement;
    title?: string;
    onClick: Function;
    disabled: boolean;
};

export function ActionItem(props: TableAction) {
    const { icon, title, onClick, disabled } = props;

    const handleClick = () => {
        if (disabled) return;
        onClick();
    };

    return (
        <StyledActionItem
            title={title}
            aria-role="button"
            onClick={handleClick}
            disabled={disabled}
        >
            <span className="icon">{icon}</span>
        </StyledActionItem>
    );
}
