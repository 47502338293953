import { useController } from "react-hook-form";
import { Datepicker as FlexiDate } from "@flexisaf/flexibull2";
import isValid from "date-fns/isValid";

import { BaseFormElementProps } from "./types";

type InputProps = BaseFormElementProps & {
    placeholder?: string;
    [restProp: string]: unknown;
};

export function Datepicker(props: InputProps) {
    const {
        name,
        label,
        placeholder,
        type = "date",
        defaultValue = "",
        rules = {},
        ext,
        ...restProps
    } = props;
    const { field, fieldState } = useController({ name, defaultValue, rules });

    const parsedDate = new Date(field.value);

    const value = isValid(parsedDate) ? parsedDate : "";

    return (
        <FlexiDate
            name={name}
            label={label}
            type={type}
            placeholder={placeholder}
            onChange={field.onChange}
            value={value}
            error={fieldState.error?.message}
            {...restProps}
        />
    );
}
