import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { AnyAction, combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, PERSIST } from "redux-persist";
import { useDispatch, TypedUseSelectorHook, useSelector } from "react-redux";

import { baseApi } from "./base-api";
import { authSlice } from "@/features/auth";
import { institutionApi } from "@/features/institution";
import { globalSlice } from "@/app/global-slice";
import { Environments } from "@/utils/enums";
import { APP_PREFIX, CLEAR } from "@/utils/constants";

import errorMiddleware from "./error-middleware";

const persistConfiguration = {
    key: APP_PREFIX,
    storage,
    version: 1,
    whitelist: [globalSlice.name, authSlice.name, institutionApi.reducerPath],
};

const ignoredActions = [PERSIST];

const combinedReducer = combineReducers({
    [baseApi.reducerPath]: baseApi.reducer,
    [institutionApi.reducerPath]: institutionApi.reducer,
    [authSlice.name]: authSlice.reducer,
    [globalSlice.name]: globalSlice.reducer,
});

export const rootReducer = (state: RootState, action: AnyAction) => {
    if (action.type === CLEAR) {
        storage.removeItem(`persist:${APP_PREFIX}`);
        state = {};
    }
    return combinedReducer(state, action);
};

const persistedReducer = persistReducer(persistConfiguration, rootReducer);

export const setupStore = (preloadedState, reducer) => {
    return configureStore({
        reducer,
        preloadedState,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: { ignoredActions },
            }).concat(baseApi.middleware, institutionApi.middleware, errorMiddleware),
        devTools: process.env.NODE_ENV !== Environments.Production,
    });
};

export const store = setupStore({}, persistedReducer);

setupListeners(store.dispatch);

type AppDispatch = typeof store.dispatch;
type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useTypedUseSelector: TypedUseSelectorHook<RootState> = useSelector;
