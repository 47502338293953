import { useState } from "react";
import { DocumentPreviewer } from "@/features/documents";
import { Button, Text } from "@flexisaf/flexibull2";
import { Icon, Modal } from "@/features/ui";
import { FileTypes } from "@/utils/enums";
import theme from "@/style/theme";

type DocumentViewerButtonProps = {
    documentUrl: string;
};

const DocumentViewerButton: React.FC<DocumentViewerButtonProps> = ({
    documentUrl,
}: DocumentViewerButtonProps) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentDocumentUrl, setCurrentDocumentUrl] = useState<string>("");
    const openDocumentViewer = (url: string) => {
        setCurrentDocumentUrl(documentUrl);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setCurrentDocumentUrl("");
        setIsModalOpen(false);
    };

    return (
        <>
            <Button
                plain
                onClick={() => openDocumentViewer(documentUrl)}
                color={theme.PrimaryColor}
                iconLeft={<Icon icon="document-download" color={theme.PrimaryColor} />}
            >
                <Text style={{ fontSize: theme.FontSizes.sm }}>View Attachment</Text>
            </Button>
            <Modal
                isOpen={isModalOpen}
                onClose={closeModal}
                style={{ width: "max-content" }}
                bodyStyle={{ padding: "0px" }}
            >
                <DocumentPreviewer url={currentDocumentUrl} ext={FileTypes.Pdf} />
            </Modal>
        </>
    );
};

export default DocumentViewerButton;
