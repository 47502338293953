import { StyledSpacer, SpaceBox, EmptySpace } from "@/features/ui/meeting.style";
import { Box, Spacer } from "@flexisaf/flexibull2";
import { Title } from "../ui/menu";
import { Text } from "@flexisaf/flexibull2/build/typo";
import { Button } from "@flexisaf/flexibull2/build/button";
import { Document } from "iconsax-react";
import { BiCloudUpload } from "react-icons/bi";
import { BsFillPlusSquareFill } from "react-icons/bs";
import { useGetMeetingMinuteQuery, useUploadMinuteMutation } from "./meeting.api";
import { useParams } from "react-router-dom";
import { useAuth } from "../auth";
import theme from "@/style/theme";
import { DocumentUploader, useUploadDocument } from "../documents";
import { memo, useEffect } from "react";

interface Props {
    minuteFile: any;
    setMinuteFile: React.Dispatch<React.SetStateAction<any>>;
    setIsPreviewerOpen: React.Dispatch<React.SetStateAction<boolean>>;
    handleTabClick: (arg0: number) => void;
}

export const MeetingMinute = memo(
    ({ minuteFile, setMinuteFile, setIsPreviewerOpen, handleTabClick }: Props) => {
        const id = useParams().id as string;
        const { data: minuteData } = useGetMeetingMinuteQuery(id);
        const { user } = useAuth();
        const { upload, isUploading } = useUploadDocument();
        const [uploadMinuteMutation, { isLoading, isSuccess }] = useUploadMinuteMutation();

        const handleMinuteUpload = async () => {
            if (minuteFile && id) {
                const minuteUrl = await upload({
                    file: minuteFile,
                    fileName: minuteFile?.name,
                });
                uploadMinuteMutation({
                    meetingId: id,
                    organization: user.organisation,
                    attachmentUrl: minuteUrl,
                });
            }
        };

        useEffect(() => {
            if (isSuccess) {
                handleTabClick(0);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [isSuccess]);

        return (
            <>
                <Spacer space={30} />
                <Title>Meeting Minutes</Title>
                <StyledSpacer space={30} />
                <SpaceBox />
                {!minuteData?.attachmentUrl && (
                    <>
                        <Box
                            style={{
                                display: "flex",
                                flex: "1",
                            }}
                        >
                            <DocumentUploader onChange={(file) => setMinuteFile(file[0])}>
                                <Box pad="0 0 16px 0">
                                    <BiCloudUpload size={30} />
                                </Box>
                                <Box>
                                    <Text>
                                        Browse and chose the files you want to upload from your
                                        computer
                                    </Text>
                                </Box>
                                <Spacer space={20} />
                                <Box>
                                    <BsFillPlusSquareFill color="#4F5D75" size={30} />
                                </Box>
                            </DocumentUploader>
                        </Box>
                        <Spacer space="89px" />
                        <Button
                            style={{
                                width: "100%",
                            }}
                            onClick={handleMinuteUpload}
                            progress={isUploading || isLoading}
                        >
                            Save Minutes
                        </Button>
                    </>
                )}

                <Spacer space="34px" />
                {minuteData?.attachmentUrl ? (
                    <Box onClick={() => setIsPreviewerOpen(true)}>
                        <Text color={theme.PrimaryBlue}>Minute of the meeting</Text>
                    </Box>
                ) : (
                    <EmptySpace>
                        <Document size="32" color="#5b6576" />
                        <Text
                            style={{
                                color: "#4F5D75",
                            }}
                        >
                            You don’t have any previous entry
                        </Text>
                    </EmptySpace>
                )}
            </>
        );
    }
);
