import * as yup from "yup";
import { useForgotPasswordMutation } from "@/features/auth/auth.api";

const validation = yup.object({
    username: yup.string().email().label("Email").required(),
});

export function useForgotPassword() {
    const [
        requestResetLink,
        { isLoading: isRequestingLink, error: requestLinkError, isSuccess: isRequestLinkSent },
    ] = useForgotPasswordMutation();

    return {
        requestResetLink,
        validation,
        isRequestingLink,
        isRequestLinkSent,
        requestLinkError,
    };
}
