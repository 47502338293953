import { lighten } from "polished";
import theme from "./theme";

export const statusColors = {
    active: { color: theme.PrimaryGreen },
    inactive: { color: theme.PrimaryRed },
    canceled: { color: theme.PrimaryRed },
    retired: { color: theme.PrimaryRed },
    wip: { color: lighten(0.2, theme.PrimaryGrey) },
    future: { color: lighten(0.2, theme.PrimaryBlue) },
    expired: { color: lighten(0.2, theme.PrimaryGrey) },
    onboarding: { color: theme.PrimaryBlue, pulse: true },
    warning: { color: theme.PrimaryOrange },
    success: { color: theme.PrimaryGreen },
    issue: { color: theme.PrimaryRed },
    error: { color: theme.PrimaryRed },
    inprogress: { color: theme.PrimaryBlue, pulse: true },
};
