import { Mutex } from "async-mutex";
import axios, { AxiosError } from "axios";
import { BaseQueryApi, BaseQueryFn, retry } from "@reduxjs/toolkit/dist/query";

import { appPaths } from "@/app/app-paths";
import { setAccessToken } from "@/features/auth/auth.slice";
import {
    CLEAR,
    CONNECTION_ERR_CODE,
    CONNECTION_ERR_MESSAGE,
    ORG_ID_HEADER_FIELD,
} from "@/utils/constants";
import { ApiError, RequestConfig } from "@/types/common";
import { HttpStatusCode } from "@/utils/enums";

type BaseQueryOptions = {
    baseUrl: string;
};

const MAX_REFRESH_RETRIES = 10;
let refreshCount = 0;
const mutex = new Mutex();

export function createStaggeredBaseQuery(baseUrl: string, maxRetries: number = 5) {
    return retry(createBaseQuery({ baseUrl }), { maxRetries });
}

export function createBaseQuery(
    opts: BaseQueryOptions
): BaseQueryFn<RequestConfig, unknown, ApiError> {
    const axiosInstance = axios.create({ baseURL: opts.baseUrl });

    return async function baseQuery(requestConfig: RequestConfig, api) {
        attachHeader(requestConfig, api);

        try {
            await mutex.waitForUnlock();
            const resp = await axiosInstance(requestConfig);
            return { data: resp.data };
        } catch (error) {
            const err = error as AxiosError;
            const auth = (<any>api.getState()).auth;

            if (err.response?.status === HttpStatusCode.Unauthorized && auth.access_token) {
                const tokenRefreshUrl = `${opts.baseUrl}/authentication/refresh-token/${auth.refreshToken}`;

                if (!mutex.isLocked()) {
                    refreshCount++;
                    await tokenRefresh(tokenRefreshUrl, api);
                }

                await mutex.waitForUnlock();
                const resp = await baseQuery(requestConfig, api);
                return resp;
            }

            return formatError(error);
        }
    };
}

async function tokenRefresh(url: string, api) {
    const unlock = await mutex.acquire();
    try {
        if (checkMaxRefreshCount()) {
            const { data } = await axios.get(url);
            api.dispatch(setAccessToken(data.token));
        }
    } catch (err) {
        api.dispatch({ type: CLEAR });
        localStorage.clear();
        window.location.replace(appPaths.login);
    } finally {
        unlock();
    }
}

function checkMaxRefreshCount() {
    if (refreshCount >= MAX_REFRESH_RETRIES) {
        return false;
    }
    return true;
}

function attachHeader(requestConfig: RequestConfig, api: BaseQueryApi) {
    if (!requestConfig.headers) {
        requestConfig.headers = {};
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { access_token, user } = (<any>api.getState()).auth;
    if (user && !requestConfig.headers[ORG_ID_HEADER_FIELD]) {
        requestConfig.headers[ORG_ID_HEADER_FIELD] = user.organisation;
    }
    if (access_token) {
        requestConfig.headers.Authorization = `Bearer ${access_token}`;
    }
}

function formatError(error) {
    if (error.response) {
        const { status, data } = error.response;
        const message = data?.details || data?.error || error.message;
        return { error: { status, message } };
    }
    return { error: { status: CONNECTION_ERR_CODE, message: CONNECTION_ERR_MESSAGE } };
}
