import { useSearchParam } from "react-use";
import * as yup from "yup";
import { usePasswordResetMutation } from "../auth.api";
import { ApiError } from "@/types/common";

const validation = yup.object({
    password: yup.string().label("Password").required(),
    confirmPassword: yup
        .string()
        .label("Password")
        .required("You haven't confirmed the password")
        .oneOf([yup.ref("password"), null], "Passwords don't match")
        .min(8),
});

export interface PasswordFormBody {
    password: string;
    confirmPassword: string;
}

export function usePasswordReset() {
    const [resetReq, resetReqMeta] = usePasswordResetMutation();

    const token = useSearchParam("token") as string;
    const user = useSearchParam("user") as string;

    const isInvalidPasswordResetLink = !token || !user;

    const resetPassword = (data: PasswordFormBody) => {
        const { password } = data;
        resetReq({ token, user_id: user, password });
    };

    const loading = {
        isResettingPassword: resetReqMeta.isLoading,
    };

    const errors = {
        passwordResetError: resetReqMeta.error as ApiError,
    };

    const success = {
        isPasswordResetSuccess: resetReqMeta.isSuccess,
    };

    const actions = {
        resetPassword,
    };

    return {
        token,
        isInvalidPasswordResetLink,
        validation,
        actions,
        loading,
        errors,
        success,
    };
}
