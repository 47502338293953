import { useMatches } from "react-router";
import { Box } from "@flexisaf/flexibull2";
import { Crumb } from "./crumb";

type MatchWithHandle<K extends string, T> = { handle: { [Key in K]: T } } & { [key: string]: any };
type CrumbFunction = (match) => { to: string; title: string };

export function Breadcrumbs() {
    const matches = useMatches();

    const crumbs = matches
        .filter((match) => match.handle)
        .map((match) => (match as MatchWithHandle<"crumb", CrumbFunction>).handle.crumb(match));

    return (
        <Box display="flex" style={{ alignItems: "center" }}>
            {crumbs.map((crumb, index) => {
                const isLastCrumb = index === crumbs.length - 1;
                if (isLastCrumb) return <span>{crumb.title}</span>;

                return (
                    <span key={index}>
                        <Crumb to={crumb.to} title={crumb.title} /> /
                    </span>
                );
            })}
        </Box>
    );
}
