import { ReactNode, useCallback } from "react";
import { Option } from "@/types/common";

import { StyledSelect } from "./select.styles";

type Value = number | string | Record<any, any>;
type SelectProps = {
    name?: string;
    label?: ReactNode;
    options: any[];
    pale?: boolean;
    rounded?: boolean;
    error?: string;
    color?: string;
    value: Value;
    isMulti?: boolean;
    valueAndLabel?: boolean;
    onChange: Function;
    [key: string]: any;
    placeholder?: string;
};

const getSelectValue = (options, value: Value) => {
    if (Array.isArray(value)) {
        return value.map((currentValue) => getSelectValue(options, currentValue));
    }

    if (typeof value === "number" && !value) return "";

    if (typeof value === "object") {
        return value;
    }

    if (typeof value === "string") {
        return options.find((opt) => opt.value === value);
    }
};

export function Select(props: SelectProps) {
    const { name, value, options, valueAndLabel, isMulti, onChange, placeholder, ...restProps } =
        props;

    const handleChange = useCallback(
        (selected: Option | Option[]) => {
            if (!selected) return null;
            if (valueAndLabel) return onChange(selected);
            if (isMulti) {
                const selectedValues = selected.map(({ value }) => value);
                return onChange(selectedValues);
            }
            if (!Array.isArray(selected)) onChange(selected.value);
        },
        [isMulti, valueAndLabel, onChange]
    );

    return (
        <StyledSelect
            name={name}
            placeholder={placeholder}
            value={getSelectValue(options, value)}
            onChange={handleChange}
            options={options}
            {...restProps}
        />
    );
}
