import { icons, IconsaxIcon } from "@/assets/iconsax";
import styled from "styled-components";

type IconProps = {
    icon: IconsaxIcon;
    size?: string | number;
    color?: string;
};
const IconWrapper = styled.div<{ color: string }>`
    padding: 0px;
    display: inline-flex;
    height: min-content;
    svg {
        stroke: ${(p) => p.color};
        path {
            fill: ${(p) => p.color};
        }
    }
`;

export const Icon = (props: IconProps) => {
    const { icon, size = 16, color = "auto" } = props;
    const IconComp = icons[icon];

    return (
        <IconWrapper color={color}>
            <IconComp width={size} height={size} />
        </IconWrapper>
    );
};
