import { Notify } from "@flexisaf/flexibull2";

import { baseApi } from "@/store/base-api";
import { Methods } from "@/utils/enums";
import { QueryFulfilledError } from "@/types/common";

export const commentsApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getComments: builder.query<any, any>({
            query: (prayerId) => ({
                url: `/comments/${prayerId}/comments`,
            }),
            providesTags: ["COMMENTS"],
            transformResponse: transformComments,
        }),

        getCommentById: builder.query<unknown, unknown>({
            query: (id) => ({
                url: `/comments/${id}`,
            }),
        }),
        createComment: builder.mutation<any, void>({
            query: (data) => ({
                url: "/comments",
                method: Methods.Post,
                data,
            }),
            invalidatesTags: ["COMMENTS"],
            async onQueryStarted(_, { queryFulfilled: qf }) {
                qf.then(() => Notify("Comment Created Successfully")).catch(
                    (err: QueryFulfilledError) => Notify(err.error?.message, { status: "error" })
                );
            },
        }),
        updateComment: builder.mutation<unknown, unknown>({
            query: ({ id, ...data }) => ({
                url: `/comments/${id}`,
                method: Methods.Put,
                data,
            }),
            async onQueryStarted({ queryFulfilled: qf }) {
                qf.then(() => Notify("Comment Updated Successfully")).catch(
                    (err: QueryFulfilledError) => Notify(err.error?.message, { status: "error" })
                );
            },
        }),
        deteleComment: builder.mutation<unknown, unknown>({
            query: (id) => ({
                url: `/comments/${id}`,
                method: Methods.Delete,
            }),
            async onQueryStarted(req, { queryFulfilled: qf }) {
                qf.then(() => Notify("Comment Deleted Successfully")).catch(
                    (err: QueryFulfilledError) => Notify(err.error?.message, { status: "error" })
                );
            },
        }),
    }),
});

export interface PrayerParams {
    category?: string;
    departmentId?: string;
    facultyId?: string;
    meetingId?: string;
    type?: string;
    limit?: number;
    offset?: number;
    status?: string;
}

function transformComments(info) {
    return info.entities.map((entity) => {
        return {
            content: entity.content,
            id: entity.id,
            createdAt: entity.createdAt,
            staff: entity.createdBy,
        };
    });
}

export const {
    useGetCommentsQuery,
    useGetCommentByIdQuery,
    useCreateCommentMutation,
    useUpdateCommentMutation,
} = commentsApi;
