import { useDispatch } from "react-redux";
import { createContext, useMemo, useCallback, useContext, ReactElement, useEffect } from "react";

import { appPaths } from "@/app/app-paths";
import { clearStore } from "@/store/store-utils";
import { Error, Mutation } from "@/types/common";

import { AuthUser, useLoginMutation } from "./auth.api";
import { setAuth, useAuthSlice } from "./auth.slice";

interface AuthContext {
    user: AuthUser;
    login: Mutation;
    logout: () => void;
    isAuthenticated: boolean;
    isLoggingIn: boolean;
    isLoginSuccess: boolean;
    loginError: Error;
}

const authContext = createContext<AuthContext | {}>({});

export function AuthProvider(props: { children: ReactElement }) {
    const { user } = useAuthSlice();
    const isAuthenticated = useMemo(() => user, [user]);
    const dispatch = useDispatch();

    const [
        login,
        {
            isLoading: isLoggingIn,
            error: loginError,
            isSuccess: isLoginSuccess,
            data: loginResponse,
        },
    ] = useLoginMutation();

    useEffect(() => {
        if (loginResponse) {
            dispatch(setAuth(loginResponse));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loginResponse]);

    const logout = useCallback(() => {
        clearStore();
        window.location.replace(appPaths.login);
    }, []);

    return (
        <authContext.Provider
            value={{
                user,
                login,
                logout,
                isAuthenticated,
                isLoggingIn,
                isLoginSuccess,
                loginError,
            }}
        >
            {props.children}
        </authContext.Provider>
    );
}

export const useAuth = () => useContext(authContext) as AuthContext;
