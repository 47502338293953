import { Outlet, Link } from "react-router-dom";
import {
    Datepicker,
    Text,
    Box,
    Button,
    Spacer,
    FlexiPagination,
    EmptyState,
} from "@flexisaf/flexibull2";

import { useAuthUserNavigate } from "@/features/auth";
import { DataItem, LabelWithIcon, PageLayout, SearchBar, Section, WithLoader } from "@/features/ui";
import { theme } from "@/style";
import { Icon } from "@/features/ui/base/icon";
import { appPaths } from "@/app";
import { IconsaxIcon } from "@/assets/iconsax";
import { Privileges, usePrivileges } from "@/features/authorization";
import { formatDate, parseDateSafely } from "@/utils/date-utils";
import { hasItems, pageOptions } from "@/utils/object-utils";
import { addNumberSuffix } from "@/utils/number-utils";
import { Duration } from "@/utils/enums";
import { useResponsiveValue } from "@/lib/use-responsive-value";

import { useAgendas } from "./use-agendas";

export default function Agendas() {
    const {
        queryState,
        page,
        upcomingMeeting,
        upcomingMeetingAgenda,
        agendasData,
        actions,
        loading,
        errors,
    } = useAgendas();
    const { buildPath, navigateTo } = useAuthUserNavigate();
    const { hasPrivilege } = usePrivileges();
    const parsedDate = queryState.meetingDate ? parseDateSafely(+queryState.meetingDate) : "";

    const pageLoading = loading.isLoadingUpcomingMeeting || loading.isLoadingAgendas;

    const datePickerWidth = useResponsiveValue({ sm: "70%", md: "auto" });
    const buttonHeight = useResponsiveValue({ sm: undefined, md: "51" }) as string;

    const showCreateAgendaButton =
        hasPrivilege(Privileges.CreateAgenda) &&
        Boolean(
            upcomingMeeting && !loading.isLoadingUpcomingMeetingAgenda && !upcomingMeetingAgenda
        );
    return (
        <>
            <Outlet />
            <PageLayout pageLabel="Agenda" minHeight="95vh">
                <>
                    <Section
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                        }}
                    >
                        <Box>
                            <Text bold block size="1.5em" style={{ color: theme.PrimaryGreyDark }}>
                                Agenda
                            </Text>

                            <Spacer space={8} />
                            <Text> Search, share and track Agenda</Text>
                        </Box>
                        {showCreateAgendaButton && (
                            <Box>
                                <Link to={buildPath(appPaths.createAgenda) as string}>
                                    <Button
                                        onClick={() => navigateTo(appPaths.createPrayer)}
                                        width={160}
                                        height={buttonHeight}
                                    >
                                        New Agenda
                                    </Button>
                                </Link>
                            </Box>
                        )}
                    </Section>
                    <Spacer space={16} />
                    <Section>
                        <Box>
                            <Spacer space={32} />
                            <Box display="flex" style={{ justifyContent: "space-between", gap: 8 }}>
                                <Datepicker
                                    value={parsedDate}
                                    label={<LabelWithIcon icon="note-text" text="Date" />}
                                    onChange={actions.filterByDate}
                                    style={{ width: datePickerWidth }}
                                />
                                <SearchBar
                                    debounceInterval={Duration.Mid}
                                    value={queryState.meetingTitle ?? ""}
                                    onChange={(q) => actions.filterByMeeting(q)}
                                    label={<LabelWithIcon icon="judge" text="Meeting Title" />}
                                />
                            </Box>
                        </Box>
                        <Spacer space={24} />
                        <WithLoader isLoading={pageLoading} error={errors.usersError}>
                            <>
                                {hasItems(agendasData?.entities) ? (
                                    agendasData?.entities.map((entity) => (
                                        <DataItem
                                            key={entity.id}
                                            style={{
                                                padding: "1em 1em 1em 0",
                                                borderBottom: theme.Border.light,
                                            }}
                                            onClick={() =>
                                                navigateTo(
                                                    appPaths.getAgenda(entity.id?.toString())
                                                )
                                            }
                                            subtitle={entity.title}
                                            caption={`Meeting date: ${formatDate(
                                                entity.meeting.datetime
                                            )}`}
                                            rightContent={
                                                <Text bold>
                                                    {" "}
                                                    {`${addNumberSuffix(entity.meeting.number)}`}
                                                </Text>
                                            }
                                        />
                                    ))
                                ) : (
                                    <EmptyState
                                        style={{ width: "100%" }}
                                        title="Nothing here yet!"
                                        info="Sorry, no agenda was found"
                                    />
                                )}

                                <Spacer space={16} />
                                <FlexiPagination
                                    pageCounts={pageOptions}
                                    total={20}
                                    itemsDisplayed
                                    pageSize={queryState.limit}
                                    changePageSize={({ value }) => actions.setLimit(value)}
                                    onChange={(val: number) => actions.setPage(val)}
                                    current={page}
                                    style={{ maxWidth: "100%" }}
                                />
                            </>
                        </WithLoader>
                    </Section>
                </>
            </PageLayout>
        </>
    );
}

type UserActionProps = {
    icon: IconsaxIcon;
    title: string;
    callback: () => void;
};

export function UserAction(props: UserActionProps) {
    return (
        <Box
            display="flex"
            style={{
                borderBottom: "1px solid lightgray",
                alignItems: "center",
                padding: "1em",
                gap: 16,
                cursor: "pointer",
            }}
            onClick={props.callback}
        >
            <Icon icon={props.icon} />
            <span>{props.title}</span>
        </Box>
    );
}
