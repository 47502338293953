import styled from "styled-components";

export const TabContainer = styled.div`
    display: flex;
    display: flex;
    /* align-items: start;
  justify-content: start; */
    background: #ebf5ff;
    /* padding: 5px; */
    /* border-radius: 8px; */
    width: 347px;
`;

export const TabButton = styled.div<{ active: boolean }>`
    width: auto;
    /* height: 25.34px; */
    border-right: ${(props) => (props.active ? "none" : "1px solid #bfc0c0")};
    /* border-bottom-right-radius: ${(props) => (props.active ? "0" : "8px")}; */

    background: ${(props) => (props.active ? "#1890FF" : "transparent")};
    color: ${(props) => (props.active ? "#fff" : "#4F5D75")};
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;

    padding: 16px 16px;

    /* Flexbox */
    display: flex;
    justify-content: flex-start;

    &:last-child {
        flex-grow: 1;
        border-right: none;
        border-bottom-right-radius: 0;
    }
`;

export const TabPanel = styled.div<{ active: boolean }>`
    display: ${(props) => (props.active ? "block" : "none")};
    /* border: 1px solid rgba(6, 92, 90, 0.5);
  border-top: none; */
    /* padding: 12px 16px; */
`;
