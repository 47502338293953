import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { APP_PREFIX } from "@/utils/constants";
import storage from "redux-persist/lib/storage";
import { useSelector } from "react-redux";

import { UserRole } from "@/features/authorization";
import { LoginResponse } from "./auth.api";

export type AuthState = LoginResponse;

const initialState = <AuthState>{};

const setAuthHandler = (state, { payload: auth }) => {
    state = auth;
    return state;
};

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setAuth: setAuthHandler,
        clearAuth() {
            storage.removeItem(APP_PREFIX);
            return initialState;
        },
        setAccessToken(state, { payload: token }: PayloadAction<string>) {
            state.access_token = token;
        },
        switchRole(state, { payload: targetRole }: PayloadAction<UserRole>) {
            if (state.user) {
                state.user.currentRole = targetRole;
            }
        },
    },
});

export const { setAuth, setAccessToken, clearAuth, switchRole } = authSlice.actions;
export const useAuthSlice = () => useSelector((state: { auth: AuthState }) => state.auth);
