import { OptionsObject } from "@/types/common";
import { Environments } from "./enums";

export const genderMap = {
    m: "Male",
    f: "Female",
};

export const valuesFromOptionsObject = <T extends OptionsObject, U extends Object>(
    optionsObject: T
) => {
    return Object.entries(optionsObject).reduce<U>((acc, [key, currOption]) => {
        acc[key] = currOption.value;
        return acc;
        // eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter, @typescript-eslint/consistent-type-assertions
    }, {} as U);
};

export const getGenderString = (genderChar: string) => genderMap[genderChar.toLowerCase()] || "N/A";

export const hashArray = <T = any>(arr: T[], { key }: { key: string }) => {
    return !arr?.length
        ? {}
        : arr.reduce<{ [S: string]: T }>((targetObj, item) => {
              targetObj[item[key]] = item;
              return targetObj;
          }, {});
};

export const hasItems = (collection: any, min = 1) =>
    Array.isArray(collection) && collection.length >= min;

const extractAcademicStartYear = (academicSessionString = "") =>
    +academicSessionString.split("/")[0];

export const sortSessionOptions = (sessionsArr: string[]) => {
    const _sessions = sessionsArr;
    _sessions.sort((a, b) => {
        const startYearA = extractAcademicStartYear(a);
        const startYearB = extractAcademicStartYear(b);
        return startYearB - startYearA;
    });

    return _sessions;
};

export const isEnv = (env: Environments) => process.env.NODE_ENV === env;

export const pageOptions = [
    {
        label: "10",
        value: "10",
    },
    {
        label: "20",
        value: "20",
    },
    {
        label: "30",
        value: "30",
    },
];
