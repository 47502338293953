import { ReactComponent as MessageQuestion } from "./message-question.svg";
import { ReactComponent as BookSaved } from "./book-saved.svg";
import { ReactComponent as ClipboardText } from "./clipboard-text.svg";
import { ReactComponent as MonitorRecorder } from "./monitor-recorder.svg";
import { ReactComponent as Note2 } from "./note-2.svg";
import { ReactComponent as Notification } from "./notification.svg";
import { ReactComponent as Setting2 } from "./setting-2.svg";
import { ReactComponent as People } from "./people.svg";
import { ReactComponent as ArrowLeft } from "./arrow-left.svg";
import { ReactComponent as ArrowUp2 } from "./arrow-up-2.svg";
import { ReactComponent as ArrowRight } from "./arrow-right.svg";
import { ReactComponent as ArrowRight1 } from "./arrow-right-1.svg";
import { ReactComponent as ArrowRightPlain } from "./arrow-right-3.svg";
import { ReactComponent as ArrowLeftPlain } from "./arrow-left-2.svg";
import { ReactComponent as ArrowDown1 } from "./arrow-down-1.svg";
import { ReactComponent as CloseCircle } from "./close-circle.svg";
import { ReactComponent as DirectDown } from "./direct-down.svg";
import { ReactComponent as Minus } from "./minus.svg";
import { ReactComponent as MinusCircle } from "./minus-cirlce.svg";
import { ReactComponent as MinusSquare } from "./minus-square.svg";
import { ReactComponent as Add } from "./add.svg";
import { ReactComponent as TickCircle } from "./tick-circle.svg";
import { ReactComponent as Warning2 } from "./warning-2.svg";
import { ReactComponent as DocumentDownload } from "./document-download.svg";
import { ReactComponent as Judge } from "./judge.svg";
import { ReactComponent as FilterEdit } from "./filter-edit.svg";
import { ReactComponent as Layer } from "./layer.svg";
import { ReactComponent as SearchNormalOne } from "./search-normal-1.svg";
import { ReactComponent as UserEdit } from "./user-edit.svg";
import { ReactComponent as More } from "./more.svg";
import { ReactComponent as UserRemove } from "./user-remove.svg";
import { ReactComponent as TOggleOffCircle } from "./toggle-off-circle.svg";
import { HambergerMenu, Calendar } from "iconsax-react";
import { ReactComponent as Clipboard } from "./clipboard.svg";
import { ReactComponent as MenuLines } from "./menu-1.svg";
import { ReactComponent as EditLine } from "./edit-2.svg";
import { ReactComponent as NoteText } from "./note-text.svg";
import { ReactComponent as DocumentWithTextFolded } from "./document-text-1.svg";
import { ReactComponent as User } from "./user.svg";
import { ReactComponent as Speedometer } from "./speedometer.svg";
import { ReactComponent as Eye } from "./eye.svg";
import { ReactComponent as Logout } from "./logout.svg";
import { ReactComponent as Trash } from "./trash.svg";
export const icons = {
    "message-question": MessageQuestion,
    "book-saved": BookSaved,
    date: Calendar,
    "clipboard-text": ClipboardText,
    "document-download": DocumentDownload,
    "close-circle": CloseCircle,
    "monitor-recorder": MonitorRecorder,
    "note-2": Note2,
    "setting-2": Setting2,
    "arrow-up-2": ArrowUp2,
    "arrow-left": ArrowLeft,
    "arrow-right": ArrowRight,
    "arrow-down-1": ArrowDown1,
    "arrow-right-1": ArrowRight1,
    "direct-down": DirectDown,
    "minus-circle": MinusCircle,
    "minus-square": MinusSquare,
    "tick-circle": TickCircle,
    "warning-2": Warning2,
    "filter-edit": FilterEdit,
    "user-edit": UserEdit,
    "user-remove": UserRemove,
    "search-normal-1": SearchNormalOne,
    "menu-lines": MenuLines,
    "toggle-off-circle": TOggleOffCircle,
    "note-text": NoteText,
    "edit-line": EditLine,
    "arrow-right-plain": ArrowRightPlain,
    "arrow-left-plain": ArrowLeftPlain,
    "document-with-text-folded": DocumentWithTextFolded,
    judge: Judge,
    layer: Layer,
    hamburger: HambergerMenu,
    logout: Logout,
    add: Add,
    more: More,
    clipboard: Clipboard,
    minus: Minus,
    user: User,
    notification: Notification,
    people: People,
    speedometer: Speedometer,
    eye: Eye,
    trash: Trash,
};

export type IconsaxIcon = keyof typeof icons;
