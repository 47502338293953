import { omitBy } from "lodash";
import { useState, useEffect, useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

type QueryStateSyncAdapter<F, A> = (
    queryState: F,
    setQueryField: (name: keyof F, value: any) => void,
    updateQueryState: (queryState: F) => void
) => A;

export function useQueryStateSync<F extends Object, A = Object>(
    initialQueryState: F,
    adapter?: QueryStateSyncAdapter<Partial<F>, A>
) {
    const location = useLocation();
    const navigate = useNavigate();
    const [queryState, setQueryStateObj] = useState<Partial<F>>(initialQueryState);

    // Updates the queryState with the initial values in location.search
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const newQueryState = {} as F;
        for (const [key, value] of params.entries()) {
            newQueryState[key] = value;
        }
        setQueryStateObj((prevFilters) =>
            omitNullOrEmptyString({ ...prevFilters, ...newQueryState })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Updates location.search whenever the `queryState` is updates
    useEffect(() => {
        const params = new URLSearchParams();
        for (const [key, value] of Object.entries(queryState)) {
            params.set(key, value);
        }
        const newSearch = params.toString();
        navigate({ search: newSearch });
    }, [queryState, navigate]);

    const setQueryField = useCallback((name: keyof F, value: string | number) => {
        setQueryStateObj((prevFilters) =>
            omitNullOrEmptyString({
                ...prevFilters,
                [name]: value,
            })
        );
    }, []);

    const updateQueryState = useCallback((newQueryState: Partial<F>) => {
        setQueryStateObj(omitNullOrEmptyString(newQueryState));
    }, []);

    const resetQueryState = () => {
        setQueryStateObj(omitNullOrEmptyString(initialQueryState));
    };

    // Adds extra methods and fields from the supplied adapter
    const extraMethods: A = useMemo(() => {
        if (!adapter) return {} as A;
        return adapter(queryState, setQueryField, updateQueryState);
    }, [adapter, queryState, setQueryField, updateQueryState]);

    return {
        queryState,
        setQueryField,
        updateQueryState,
        resetQueryState,
        ...extraMethods,
    };
}

// utils
function omitNullOrEmptyString(val) {
    const newVal = omitBy(val, (val) => val === "" || val === null || val === undefined);
    return newVal;
}
