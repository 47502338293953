import { baseApi } from "@/store/base-api";
import { Methods } from "@/utils/enums";
import { List } from "@/types/common";

export const authorizationApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getRoles: builder.query<List<Role>, any>({
            query: (params) => ({
                url: "/roles",
                params,
            }),
        }),
        getRole: builder.query<void, string>({
            query: (id) => ({
                url: `/roles/${id}`,
            }),
        }),
        createRole: builder.mutation<Role, RoleRequest>({
            query: (data) => ({
                url: "/roles",
                method: Methods.Post,
                data,
            }),
        }),
        updateRole: builder.mutation<Role, UpdateRoleRequest>({
            query: ({ id, ...data }) => ({
                url: `role/${id}`,
                method: Methods.Put,
                data,
            }),
        }),
        deleteRole: builder.mutation<unknown, string>({
            query: (token) => ({
                url: "students/email-verification-update",
                params: { token },
                method: Methods.Put,
            }),
        }),
    }),
});

export interface RoleRequest {
    name: string;
    privileges: string[];
    description: string;
}

export interface UserRole {
    admin_level: string;
    role: Role;
    unit: string;
}

export interface UpdateRoleRequest extends Partial<RoleRequest> {
    id: string;
}
interface Privilege {
    id: string;
    name: string;
}

export interface Role {
    date_created?: string;
    date_updated?: string;
    description: string;
    id: string;
    name: string;
    privileges: Privilege[];
    user_count: number;
}

export const { useGetRolesQuery, useGetRoleQuery } = authorizationApi;
