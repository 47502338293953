import { darken } from "polished";
import { useKey, useToggle } from "react-use";
import { render, unmountComponentAtNode } from "react-dom";
import { useCallback } from "react";
import styled from "styled-components";

import { Box, Spacer } from "@flexisaf/flexibull2/build/layout";
import { Text } from "@flexisaf/flexibull2/build/typo";

import { theme } from "@/style";

type ConfirmationType = "warning" | "danger" | "confirm";

const colorTypeMap: Record<ConfirmationType, string> = {
    warning: theme.PrimaryOrange,
    danger: theme.PrimaryRed,
    confirm: theme.PrimaryColor,
};

const getColorFromTypeMap = (type) => colorTypeMap[type] || theme.PrimaryColor;

const ConfirmationOverlay = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    z-index: 90000;
    min-height: 100vh;
    display: grid;
    place-items: center;
    background-color: rgba(217, 217, 217, 0.7);
`;

const ConfirmationWrapper = styled.div<{ type: ConfirmationType }>`
    color: ${theme.PrimaryGreyDark};
    background: white;
    border-radius: 0.5em;
    padding: 2em 3em;

    & button {
        // Values based on flexibull's button component
        height: 36px;
        padding: 0 15px;
        border-radius: 5px;

        background: transparent;
        border: none;
        cursor: pointer;

        &:hover {
            background-color: ${darken(0.1, "white")};
        }
    }

    & button.is-cancel {
        border: 1px solid black;
    }
    & button.is-action {
        color: white;
        background-color: ${(p) => getColorFromTypeMap(p.type)};

        &:hover {
            background-color: ${(p) => darken(0.1, getColorFromTypeMap(p.type))};
        }
    }
`;

const containerId = "confirmation-container";
const defaultConfig = {};
(() => {
    if (!document.getElementById(containerId)) {
        const container = document.createElement("div");
        container.setAttribute("id", containerId);
        document.body.append(container);
    }
})();

type ActionButton = { style?: React.CSSProperties; text: string };

type ConfirmationConfig = {
    title?: string | number | React.ReactNode;
    message?: string | number | React.ReactNode;
    onClose?: () => void;
    render?: (handleAction: () => void, handleUnmount: () => void) => React.ReactNode;
    type?: ConfirmationType;
    okText?: string;
    cancelText?: string;
    okButton?: ActionButton;
    cancelButton?: ActionButton;
    loadingText?: string;
};

type ConfirmationProps = {
    cb: () => void;
} & ConfirmationConfig;

function Confirmation(props: ConfirmationProps) {
    const {
        cb,
        title,
        message,
        type = "confirm",
        okButton,
        cancelButton,
        okText = "Confirm",
        cancelText = "Cancel",
        render,
        onClose,
        loadingText = "Loading...",
    } = props;

    useKey("Escape", unmountConfirmation);
    const [isLoading, toggleIsLoading] = useToggle(false);
    const handleUnmount = useCallback(() => {
        onClose && onClose();
        unmountConfirmation();
    }, [onClose]);

    const handleAction = useCallback(async () => {
        if (cb) {
            try {
                toggleIsLoading(true);
                await cb();
                unmountConfirmation();
            } catch (err) {
                // eslint-disable-next-line no-console
                console.error(err);
            } finally {
                toggleIsLoading(false);
            }
        }
    }, [cb, toggleIsLoading]);

    return (
        <ConfirmationOverlay>
            {render ? (
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                render(handleAction, handleUnmount)
            ) : (
                <ConfirmationWrapper type={type}>
                    <Box>
                        <Box>
                            <Text block bold size="1.5em" style={{ textAlign: "center" }}>
                                {title}
                            </Text>
                            <Spacer space="20" />
                            <Text block style={{ textAlign: "center" }}>
                                {message}
                            </Text>
                        </Box>
                        <Spacer space="20" />
                        <Box display="flex" pad="20" style={{ justifyContent: "center" }}>
                            <button
                                className="is-cancel"
                                onClick={handleUnmount}
                                style={{ marginRight: "1em", ...(cancelButton?.style ?? {}) }}
                            >
                                {cancelButton?.text ?? cancelText}
                            </button>
                            <button
                                className="is-action"
                                style={okButton?.style ?? {}}
                                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                                onClick={handleAction}
                            >
                                {isLoading ? loadingText : okButton?.text ?? okText}
                            </button>
                        </Box>
                    </Box>
                </ConfirmationWrapper>
            )}
        </ConfirmationOverlay>
    );
}

export function unmountConfirmation() {
    const container = document.getElementById(containerId);
    if (container) {
        unmountComponentAtNode(container);
    } else {
        // eslint-disable-next-line no-console
        console.error(`Unable to unmount component with container id: ${containerId}`);
    }
}

function confirmation(cbArg: (data: any) => void, config: ConfirmationConfig = {}) {
    return (data) => {
        const cb = () => cbArg(data);
        const componentProps = { ...defaultConfig, ...config, cb };
        const container = document.getElementById(containerId);
        if (container) {
            render(<Confirmation {...{ ...componentProps }} />, container);
        }
    };
}

export default confirmation;
