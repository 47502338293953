import startCase from "lodash/startCase";
import toLower from "lodash/toLower";
import { INSTITUTION_KEY } from "./constants";

export const isValidUrl = (url: string) => {
    if (!url) return false;
    const parsedUrl = new URL(url);
    return /https?:/.test(parsedUrl.protocol);
};

export const titleCase = (str = "") => startCase(toLower(str));

export const snakeToTitleCase = (text: string) =>
    !text
        ? ""
        : text
              .toLocaleLowerCase()
              .replace(/(^\w)/, (char) => char.toUpperCase())
              .replace(/_+(\w)/g, (char) => char.toUpperCase())
              .replace(/_+/g, " ");

export const formatSession = (semester: string) => {
    const [firstPart, secondPart] = semester.split("/");
    if (secondPart.length !== 4) return semester;
    const [, , ...lastDigits] = secondPart;
    return `${firstPart}/${lastDigits.join("")}`;
};

export const replaceNewLineWithBr = (content: string) => content.replace(/\n/g, "<br />");

export const encodeInterpolatedUrl = (
    basePath: TemplateStringsArray,
    ...interpolated: string[]
) => {
    const encondedInterpolated = interpolated.map((i) => encodeURIComponent(i));
    return basePath.map((path, index) => `${path}${encondedInterpolated[index] ?? ""}`).join("");
};

export const pluralize = (size: number, singularForm: string, pluralForm: string) =>
    size > 1 ? pluralForm : singularForm;

export const buildInstitutionPath = (path: string, institutionId: string) =>
    path.replace(`:${INSTITUTION_KEY}`, institutionId);

export const isSearchParamsEqual = (
    searchParams1: URLSearchParams,
    searchParam2: URLSearchParams
) => {
    for (const [key, value] of searchParams1.entries()) {
        if (searchParam2.get(key) !== value) {
            return false;
        }
    }

    return true;
};

export function getFileDetailsFromUrl(url: string): { name: string; ext: string } {
    const delimiterIndex = url?.lastIndexOf(":");
    const name = url?.substring(delimiterIndex + 1);

    const regex = /\.[^.]+$/;
    const match = url?.match(regex);
    const ext = match ? match[0].substring(1) : "";

    return { name, ext };
}

export const formatResultUrl = (resultUrl: string) =>
    resultUrl ? resultUrl.replace("s3.amazonaws.com", "s3.us-west-2.amazonaws.com") : "";
