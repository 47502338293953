import { Box, Text, Spacer } from "@flexisaf/flexibull2";
import { PageLayout, Icon, Section, BackButton, WithLoader } from "@/features/ui";
import { theme } from "@/style";
import { useState } from "react";
import DeleteNotificationModal from "./delete-notification-modal";

export default function Notification() {
    const sampleData = [
        { id: 1, title: "Senate Meeting", sub: "2/27/2023 - 2:00PM  |  Regular", done: true },
        { id: 2, title: "Emergency Meeting", sub: "1/18/2023 - 2:00PM | Emergency", done: false },
        {
            id: 3,
            title: "Prayer recommended",
            sub: "1/18/2023 - 2:00PM  |  Emergency",
            done: true,
        },
    ];
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <PageLayout pageLabel="Notification" header={"Notificationn"} minHeight={"95vh"}>
            <>
                <WithLoader
                    isLoading={false}
                    height="100vh"
                    // error={errors.getAgendaError}
                >
                    <Section>
                        <BackButton />
                        {sampleData.map((item) => {
                            return (
                                <Box
                                    key={item.id}
                                    display="flex"
                                    style={{
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        width: "100%",
                                        padding: "8px",
                                        background: item.done && " #f5f5f5",
                                        borderBottom: " 1px solid #E6EAEE",
                                    }}
                                >
                                    <Box>
                                        <Text
                                            bold
                                            block
                                            size="1.5em"
                                            style={{ color: theme.PrimaryGreyDark }}
                                        >
                                            {item.title}
                                        </Text>
                                        <Text>{item.sub}</Text>
                                    </Box>
                                    <Box
                                        style={{ cursor: "pointer" }}
                                        onClick={() => setIsModalOpen(true)}
                                    >
                                        <Icon icon="trash" color={theme.PrimaryDark} />
                                    </Box>
                                </Box>
                            );
                        })}
                        <Spacer space={16} />
                        <Box
                            pad="10px"
                            align="right"
                            style={{ cursor: "pointer" }}
                            onClick={() => setIsModalOpen(true)}
                        >
                            <Text size={theme.PrimaryFontSize}>Clear all notification</Text>
                        </Box>
                    </Section>
                </WithLoader>
                <DeleteNotificationModal
                    setIsModalOpen={setIsModalOpen}
                    isModalOpen={isModalOpen}
                />
            </>
        </PageLayout>
    );
}
