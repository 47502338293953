import { RoleSwitchIndicator } from "@/features/authorization";
import useScrollRestoration from "@/utils/hooks/use-scroll-restoration";
import { Box } from "@flexisaf/flexibull2";

type BaseLayoutProps = {
    children: React.ReactNode;
};

export default function BaseLayout(props: BaseLayoutProps) {
    useScrollRestoration();
    return (
        <Box>
            <RoleSwitchIndicator />
            {props.children}
        </Box>
    );
}
