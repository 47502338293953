import styled, { css } from "styled-components";
import { ModalBody as FlexiModalBody } from "@flexisaf/flexibull2";
import { theme } from "@/style";

export type ModalBodyProps = {
    position?: "center" | "right" | "bottom";
};
export const ModalBody = styled(FlexiModalBody)<ModalBodyProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    ${(p) => {
        if (p.position === "right") {
            return css`
                right: 0;
            `;
        }
        if (p.position === "bottom") {
            return css`
                position: absolute;
                bottom: 0;
                width: 100%;
            `;
        }
    }}
`;

export const ModalBar = styled.div`
    width: 40px;
    height: 5px;
    background-color: ${theme.PrimaryGrey};
`;

export const ModalOverlay = styled.div<{ isOpen: boolean }>`
    position: fixed;
    z-index: 1000;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.6);
    display: ${(p) => (p.isOpen ? "flex" : "none")};
`;
