import { useState } from "react";
import styled from "styled-components";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { Controller } from "react-hook-form";
import { Spacer } from "@flexisaf/flexibull2/build/layout";
import { Text } from "@flexisaf/flexibull2/build/typo";
import { theme } from "@/style";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const StyledEditor = styled.div`
    .wrapper-class {
        padding: 1rem;
        border: 1px solid ${theme.PrimaryBorderColor};
        border-radius: ${theme.PrimaryRadius};
    }
    .editor-class {
        background-color: white;
        padding: 1rem;
    }
    .toolbar-class {
    }
`;

type RichEditorProps = {
    onChange: Function;
    defaultValue: string;
};

type ControlledEditorProps = {
    name: string;
    control: any;
    defaultValue?: string;
};

const RichEditor = (props: RichEditorProps) => {
    const contentBlock = htmlToDraft(props.defaultValue);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const initialEditorState = EditorState.createWithContent(contentState);

    const [editorState, setEditorState] = useState(initialEditorState);

    const handleChange = (editorState) => {
        setEditorState(editorState);
        return props.onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    };

    return (
        <StyledEditor>
            <Editor
                editorState={editorState}
                onEditorStateChange={handleChange}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
            />
        </StyledEditor>
    );
};

export const ControlledEditor = (props: ControlledEditorProps) => {
    const { name, control, defaultValue = "" } = props;
    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState }) => {
                return (
                    <>
                        <RichEditor {...field} defaultValue={defaultValue} />
                        {fieldState.error && (
                            <>
                                <Spacer space="12" />
                                <Text size="1em" color={theme.PrimaryRed}>
                                    {fieldState.error?.message}
                                </Text>
                            </>
                        )}
                    </>
                );
            }}
        />
    );
};
