import styled from "styled-components";
import { Spacer } from "@flexisaf/flexibull2/build/layout";
import { Box, Text, ModalBody } from "@flexisaf/flexibull2";
import { Link } from "react-router-dom";
import { theme } from "@/style";
import { ViewportWidth } from "@/utils/enums";

export const StyledBox = styled(Box)`
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 10px;
`;

export const TextCover = styled.div`
    display: flex;
    width: 350px;
    padding: 10px 8px;
    align-items: flex-start;
    background: #f6f6f6;
    justify-content: space-between;
`;

export const StyledSpacer = styled(Spacer)`
    height: 10px;
`;

export const SpaceBox = styled.div`
    height: 10px;
    border-bottom: 1px solid #d9d9d9;
`;

export const EmptySpace = styled.div`
    display: flex;
    width: 100%;
    padding: 12px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    background: #f5f5f5;
`;

export const TitleContainer = styled.div`
    padding: 20px 10px 10px 10px;
    border-top: 1px solid #d9d9d9;

    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    p {
        font-size: 11px;
        font-family: Manrope;
        color: #4f5d75;
    }
`;

export const Title = styled.p`
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 5px;
    color: #191d23;
    @media screen and (min-width: 768px) {
        font-size: 25px;
        margin: 10px 0;
        font-weight: 600;
    }
`;

export const QuickLinks = styled(Link)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: ${theme.PrimaryGreyDark};
    align-items: center;
    padding: 5px 5px;
    margin: 0 5px;
    width: 112px;
    height: auto;
    background: #ebf5ff;
    border-radius: 10px;
    .icon {
        width: 45px;
        height: 45px;

        background: #dfdfdf;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    p {
        font-size: 12px;
        margin-top: 3px;
        font-weight: bold;
        color: ${theme.PrimaryGreyDark};
    }
    &:hover {
        background-color: ${theme.SecondaryGrey};
        border-radius: 5px;
        cursor: pointer;
    }
`;

export const QuickLinksContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: start;
    margin-bottom: 2em;
    background: #fff;
    border-radius: 4px;

    @media (max-width: ${ViewportWidth.md}) {
        display: flex;
        flex: 1;
        justify-content: center;
        margin-bottom: 2em;
        padding: 20px;
    }
`;

export const ModText = styled.span`
    color: #2d3142;
    font-size: 16px;
    font-family: Manrope;
    font-weight: 600;
`;
export const ModSubText = styled.span`
    color: #626262;
    font-size: 14px;
    font-family: Manrope;
    font-weight: 600;
`;

export const PageBanner = styled(Box)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    background: #f0f0f0;
    padding: 16px 10px;
`;

export const ModTitle = styled(Text)`
    font-style: normal;
    font-size: 12px;
    font-weight: 500;
    line-height: 140%;
    font-family: Manrope;
`;

export const SubTitle = styled(Text)`
    font-style: normal;
    font-size: 15px;
    font-weight: 300;
    line-height: 140%;
    font-family: Manrope;
    color: #2d3142;
`;

export const FilterLabelWrapper = styled.div`
    display: flex;
    align-items: center;
`;
export const IconWrapper = styled.div`
    margin-right: 5px;
`;

export const DropdownContainer = styled.div`
    position: relative;
    display: inline-block;
    width: 100%;
`;

export const DropdownList = styled.div`
    width: 100%;
    max-height: 400px;
    overflow-y: auto;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 4px 4px;

    background-color: #fff;
    margin-bottom: 10px;
`;

export const UserName = styled.span`
    font-size: 0.8rem;
    margin-left: 5px;
`;

export const SelectedContainer = styled.div`
    display: block;
    margin-top: 8px;
`;

export const SelectedItem = styled.div`
    display: flex;
    background-color: #f5f5f5;
    border-radius: 4px;
    padding: 4px 8px;
    margin: 4px;
    flex: 1;
    justify-content: space-between;
    height: 50px;
`;

export const FlexContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #fff;
    border-radius: 4px;
    padding: 20px;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;
export const MeetingWrapper = styled.div`
    background: #fff;
    border-radius: 4px;
    padding: 20px;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const StyledModalBody = styled(ModalBody)`
    @media screen and (max-width: 767px) {
        width: 85vw;
    }

    @media screen and (min-width: 768px) {
        width: ${({ width }) => width || "auto"};
    }
`;
