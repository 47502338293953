import styled from "styled-components";
import { DEFAULT_404_PAGE_MESSAGE } from "@/utils/constants";
import { theme } from "@/style";

type Props = {
    message?: string;
};

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
`;

const Content = styled.div`
    text-align: center;
`;

const Title = styled.h1`
    font-size: 3rem;
    margin-bottom: 1rem;
`;

const Message = styled.p`
    font-size: 1.5rem;
    color: ${theme.PrimaryRed};
`;

export const NotFound = ({ message = DEFAULT_404_PAGE_MESSAGE }: Props) => {
    return (
        <Container>
            <Content>
                <Title>404 Not Found</Title>
                <Message>{message}</Message>
            </Content>
        </Container>
    );
};
