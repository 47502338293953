import { Box, Spacer } from "@flexisaf/flexibull2";
import { theme, lightenCol } from "@/style";
import { Icon } from "../icon";

type AlertProps = {
    status: "error" | "success" | "warning";
    message: string;
    title?: string;
    padding?: number | string;
};

const iconMap = {
    error: <Icon icon="warning-2" color={theme.PrimaryRed} />,
    warning: <Icon icon="warning-2" color={theme.PrimaryOrange} />,
    success: <Icon icon="tick-circle" color={theme.PrimaryGreen} />,
};

const colors = {
    error: { fontColor: theme.PrimaryRed, backgroundColor: lightenCol(theme.PrimaryRed) },
    warning: { fontColor: theme.PrimaryOrange, backgroundColor: lightenCol(theme.PrimaryOrange) },
    success: {
        fontColor: theme.PrimaryGreen,
        backgroundColor: lightenCol(theme.PrimaryGreen, 0.5),
    },
    default: { fontColor: theme.PrimaryGrey, background: theme.PrimaryLight },
};

export function Alert({ status, message, padding = "1em" }: AlertProps) {
    const icon = iconMap[status];
    const { backgroundColor, fontColor: color } = colors[status] || colors.default;

    return (
        <Box
            style={{
                backgroundColor,
                padding,
                color,
                display: "flex",
                alignItems: "flex-start",
                width: "240px",
                borderRadius: "5px",
            }}
        >
            <Box>{icon}</Box>
            <Spacer direction="horizontal" space={8} />
            <Box>{message}</Box>
        </Box>
    );
}
