import { createContext, useContext } from "react";

type PreviewerContextType = {
    hasError: boolean;
    setHasError: (val: boolean) => void;
};

export const PreviewerContext = createContext<PreviewerContextType | undefined>(undefined);

export const usePreviewerContext = () => {
    const context = useContext(PreviewerContext);
    if (context === undefined) {
        throw new Error("usePreviewerContext was not used within PreviewerContextProvider");
    }
    return context;
};
